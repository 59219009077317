import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FlyerService } from 'src/app/services/flyer.service';


@Component({
  selector: 'app-attribute-edit-popup',
  templateUrl: './attribute-edit-popup.component.html',
  styleUrls: ['./attribute-edit-popup.component.css']
})


export class AttributeEditPopupComponent implements OnInit {

  /* New edited attribute values */
  newAttributeName = new FormControl();
  newDefaultValue = new FormControl();
  newMinValue = new FormControl();
  newMaxValue = new FormControl();
  newIntervalValue = new FormControl();
  newChoiceValue = new FormControl();
  newColorValue =  new FormControl();
  newDescriptionValue =  new FormControl();

  /* New edited attribute array */
  editedAttribute: any = [];

  /* To show correct fields for attributes */
  showMaxValue: boolean = false;
  showMinValue: boolean = false;
  showIntervalValue: boolean = false;
  showDefaultValue: boolean = false;
  showChoicesValue: boolean = false;
  showColorValue: boolean = false;

  /* Attribute info which is being edited */
  attrinfo: any;

  constructor(
    public flyerService: FlyerService,
  ) { }

  ngOnInit(): void {
    this.attrinfo = this.flyerService.attributeInfo;
    // console.log("attr info",this.attrinfo);

    if (this.flyerService.attributeInfo.type === 'SLIDER'){
      this.showMinValue = true;
      this.showMaxValue = true;
      this.showIntervalValue = true;
      this.showDefaultValue = true;
    } else if (this.flyerService.attributeInfo.type === 'CHOICES'){
      this.showDefaultValue = true;
      this.showChoicesValue = true; 
    } else if (this.flyerService.attributeInfo.type === 'TEXT' || this.flyerService.attributeInfo.type === 'SECTION_HEADING'){
      this.showDefaultValue = true;
    } else if (this.flyerService.attributeInfo.type === 'NUMBER'){
      this.showMinValue = true;
      this.showMaxValue = true;
      this.showDefaultValue = true;
    } else if (this.flyerService.attributeInfo.type === 'COLOR'){
      this.showColorValue = true;
    }

  }

  editAttribute(){
    if (this.flyerService.attributeInfo.type === 'SLIDER'){
      this.flyerService.attributeInfo.name = this.newAttributeName.value;
      this.flyerService.attributeInfo.defaultValue = this.newDefaultValue.value;
      this.flyerService.attributeInfo.minimumValue = this.newMinValue.value;
      this.flyerService.attributeInfo.maximumValue = this.newMaxValue.value;
      this.flyerService.attributeInfo.interval = this.newIntervalValue.value;
      this.flyerService.attributeInfo.description = this.newDescriptionValue.value;
    } else if (this.flyerService.attributeInfo.type === 'CHOICES'){
      this.flyerService.attributeInfo.name = this.newAttributeName.value;
      this.flyerService.attributeInfo.defaultValue = this.newDefaultValue.value;
      this.flyerService.attributeInfo.choices = this.newChoiceValue.value;
      this.flyerService.attributeInfo.description = this.newDescriptionValue.value;
    } else if (this.flyerService.attributeInfo.type === 'TEXT' || this.flyerService.attributeInfo.type === 'SECTION_HEADING'){
      this.flyerService.attributeInfo.name = this.newAttributeName.value;
      this.flyerService.attributeInfo.defaultValue = this.newDefaultValue.value;
      this.flyerService.attributeInfo.description = this.newDescriptionValue.value;
    } else if (this.flyerService.attributeInfo.type === 'NUMBER'){
      this.flyerService.attributeInfo.name = this.newAttributeName.value;
      this.flyerService.attributeInfo.defaultValue = this.newDefaultValue.value;
      this.flyerService.attributeInfo.minimumValue = this.newMinValue.value;
      this.flyerService.attributeInfo.maximumValue = this.newMaxValue.value;
      this.flyerService.attributeInfo.description = this.newDescriptionValue.value;
    } else if (this.flyerService.attributeInfo.type === 'COLOR'){
      this.flyerService.attributeInfo.name = this.newAttributeName.value;
      this.flyerService.attributeInfo.defaultValue = this.newColorValue.value;
      this.flyerService.attributeInfo.description = this.newDescriptionValue.value;
    } else {
      this.flyerService.attributeInfo.name = this.newAttributeName.value;
      this.flyerService.attributeInfo.description = this.newDescriptionValue.value;
    }

    // console.log("edited attr info", this.flyerService.attributeInfo);
    this.editedAttribute.push(this.flyerService.attributeInfo);
    this.flyerService.updateAttributes(this.flyerService.flyerId, this.editedAttribute).subscribe((data)=> {
      this.raiseSuccessSnackbar();
    });
  }

  raiseSuccessSnackbar(){
    this.flyerService.showToastMessage("Attribute Updated Successfully!", "check_circle", 'success');
  }


}
