import { Component, Inject, OnInit } from '@angular/core';
// Service imports
import { MicrositesService } from 'src/app/services/microsites.service';

// Material imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-microsite-page-publish-popup',
  templateUrl: './microsite-page-publish-popup.component.html',
  styleUrls: ['./microsite-page-publish-popup.component.css'],
})
export class MicrositePagePublishPopupComponent implements OnInit {
  constructor(
    public micrositesService: MicrositesService,
    public dialogRef: MatDialogRef<MicrositePagePublishPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  publishMicrositePage() {
    this.micrositesService
      .publishMicrositePage(
        this.data.micrositeId,
        this.data.microstePageId,
        this.micrositesService.currentMicrositeData
      )
      .subscribe((response) => {
        this.micrositesService.showToastMessage(
          'Microsite Page Published Successfully!',
          'check_circle',
          'success'
        );
        this.micrositesService.assignMicrositePageData(response);
      });
  }
}
