import { Injectable, ApplicationRef, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import * as Cookie from 'es-cookie';
import { map } from 'rxjs/operators';
import { WebpagesService } from './webpages.service';
import { AppConfigService } from './app-config.service';

@Injectable()
export class UserService {
  loggedIn: boolean = false;
  loggedInUser: any;
  loginStatusChanged: any = new Subject<any>();

  constructor(
    private env: AppConfigService,
    public httpClient: HttpClient,
    public router: Router,
    public appRef: ApplicationRef,
    public injector: Injector,
    public webPageService: WebpagesService
  ) {}

  onLogin(userData?) {
    this.loggedIn = true;
    this.loggedInUser = userData;
    if (!Cookie.get('propertycapsule-' + this.env.config.name + '.loggedIn'))
      this.setLoggedInCookie();
    // if (!this.router.url.includes('webpages')) this.router.navigate(['webpages']);
    this.loginStatusChanged.next(this.loggedIn);
  }

  logOut() {
    return this.httpClient
      .get(this.env.config.APP.API_URL + '/logout', { withCredentials: true })
      .pipe(map((res) => this.onLogout()));
  }

  onLogout() {
    this.loggedIn = false;
    this.loggedInUser = '';
    Cookie.remove('propertycapsule-' + this.env.config.name + '.loggedIn', {
      path: '/',
      domain: this.env.config.name,
    });
    this.router.navigate(['signin']);
    this.loginStatusChanged.next(this.loggedIn);
  }

  setLoggedInCookie(rememberMe?: boolean) {
    Cookie.set('propertycapsule-' + this.env.config.name + '.loggedIn', 'true', {
      path: '/',
      expires: 0.167,
      domain: this.env.config.name,
    }); // 4 hours
  }

  getLoggedInUser() {
    return this.httpClient.get(this.env.config.APP.USER_TOKEN_URL, { withCredentials: true }).pipe(
      map((res: any) => {
        this.env.appLoaded = true;
        if (!res.emailAddress) {
          this.onLogout();
          return false;
        } else if (res.features && res.features.includes('SYSADMIN_CAPSULE_EDIT')) {
          this.onLogin(res);
          return res;
        } else {
          this.webPageService.showToastMessage('Access Denied', 'close', 'danger');
          this.onLogout();
          return false;
        }
      })
    );
  }
}
