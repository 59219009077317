<div class="row custom-preview-row">
  <div class="col-2 custom-margin">
    <button mat-icon-button (click)="preview()">
      <mat-icon>computer</mat-icon>
      PREVIEW
    </button>
  </div>

  <div class="col-5 custom-margin" *ngIf="router.url.includes('/webpages/edit')">
    <ng-select
      *ngIf="isPDP && templateSystem != 'VTS'"
      [items]="propertyList"
      bindLabel="name"
      bindValue="globalId"
      placeholder="Select a Property"
      [clearable]="false"
      (change)="setPropertyGlobalId($event)"
    >
    </ng-select>
    <ng-select
      *ngIf="isPDP && templateSystem == 'VTS'"
      [items]="propertyList"
      bindLabel="slug"
      bindValue="slug"
      placeholder="Select a Property"
      [clearable]="false"
      (change)="setPropertyGlobalId($event)"
    >
    </ng-select>
  </div>

  <div class="col-5 custom-margin" *ngIf="router.url.includes('/flyers/edit')">
    <!-- <ng-select  [items]="propertyList" bindLabel="propertyName" bindValue="propertyGlobalId"
            placeholder="Select a Property" [clearable]=false (change)="setPropertyGlobalId($event)">
        </ng-select> -->
    <app-properties-dropdown
      [inputvar]="flyerService.statusEnabledProperties"
      [inputSub]="flyerService.statusEnabledLinkedProperties"
      (propertySelected)="setPropertyGlobalId($event)"
    >
    </app-properties-dropdown>
  </div>

  <div class="col-5 custom-margin" *ngIf="router.url.includes('/microsites/edit')"></div>

  <div class="col-5 custom-margin">
    <button mat-button class="custom-new-tab" (click)="openPreviewDialog()">
      <span class="material-icons custom-span">open_in_new</span>
      <span *ngIf="router.url.includes('/webpages/edit')">Open in New Tab </span>
      <span *ngIf="router.url.includes('/microsites/edit')">Open Preview in New Tab</span>
      <span *ngIf="router.url.includes('/flyers/edit')">Generate PDF Preview </span>
    </button>
  </div>
</div>

<div id="htmldata" class="htmldata">
  <iframe id="xFrame" class="custom-iframe"></iframe>
</div>

<!-- <ngx-extended-pdf-viewer *ngIf="router.url.includes('/flyers/edit')"
    [src]="'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf'"
    useBrowserLocale="true"
    height="80vh" [zoom]="100" [textLayer]="true">
</ngx-extended-pdf-viewer> -->
