<div class="microsite-pages-add-popup">
  <div class="row custom-close-row">
    <button class="custom-close-btn" mat-icon-button [mat-dialog-close]="true">
      <span class="material-icons">close</span>
    </button>
  </div>

  <div class="container-fluid">
    <div mat-dialog-title class="popup-heading">Add Page</div>

    <div mat-dialog-content>
      <form novalidate #addMicrositePageForm="ngForm" (ngSubmit)="onSubmit(addMicrositePageForm)">
        <!-- Microsite page name -->
        <div class="main-row row">
          <mat-form-field
            class="full-width custom-margin"
            appearance="outline"
            hintLabel="Max 60 characters"
            [floatLabel]="'always'"
          >
            <mat-label class="custom-label custom-color">Name</mat-label>
            <input
              matInput
              #micrositePageNameInput
              placeholder="Page name"
              name="pageName"
              type="text"
              [maxLength]="60"
              ngModel
              #pageName="ngModel"
              required
            />
            <mat-error *ngIf="!pageName?.valid && (pageName?.dirty || pageName?.touched)">
              <strong *ngIf="pageName.errors.required">You must enter a name!</strong>
            </mat-error>
            <mat-hint align="end">{{ micrositePageNameInput.value?.length || 0 }}/60</mat-hint>
          </mat-form-field>
        </div>

        <!-- Microsite page slug  -->
        <div class="main-row row">
          <mat-form-field
            class="full-width custom-margin"
            appearance="outline"
            [floatLabel]="'always'"
          >
            <mat-label class="custom-label custom-color">Slug</mat-label>
            <span class="building-hostname">{{ this.buildingHostname }}</span>
            <input
              matInput
              #micrositePageSlugInput
              class="page-slug-input"
              placeholder="pageslug"
              type="text"
              name="pageSlug"
              [maxLength]="60"
              ngModel
              #pageSlug="ngModel"
              pattern="^[A-Za-z\-]+$"
              (ngModelChange)="onPageSlugValueChange($event)"
              required
            />
            <mat-hint align="start" *ngIf="!showDuplicateSlugErrorMessage">
              Max 60 characters
            </mat-hint>
            <mat-error
              *ngIf="!pageSlug?.valid && (pageSlug?.dirty || pageSlug?.touched) && pageSlug.errors"
            >
              <strong *ngIf="pageSlug.errors.required">You must enter a name!</strong>
              <strong *ngIf="pageSlug.errors.pattern">Only `-` and alphabet are allowed !</strong>
            </mat-error>
            <mat-hint align="end" *ngIf="!showDuplicateSlugErrorMessage"
              >{{ micrositePageSlugInput.value?.length || 0 }}/60</mat-hint
            >
          </mat-form-field>
          <div class="custom-error-message" *ngIf="showDuplicateSlugErrorMessage">
            <strong>{{ duplicateSlugErrorMessage }}</strong>
          </div>
        </div>

        <!-- Microsite page description  -->
        <div class="main-row row">
          <mat-form-field
            class="full-width custom-description custom-margin"
            appearance="outline"
            hintLabel="Max 120 characters"
            [floatLabel]="'always'"
          >
            <mat-label class="custom-label custom-color">Description</mat-label>
            <textarea
              matInput
              placeholder="Add page description"
              type="textarea"
              name="pageDescription"
              #pageDescription="ngModel"
              ngModel
              [maxLength]="120"
              required
            ></textarea>
            <mat-error
              *ngIf="
                !pageDescription?.valid && (pageDescription?.dirty || pageDescription?.touched)
              "
            >
              <strong *ngIf="pageDescription.errors.required">You must enter a Description!</strong>
            </mat-error>
            <mat-hint align="end">{{ pageDescription.value?.length || 0 }}/120</mat-hint>
          </mat-form-field>
        </div>

        <!-- Save button -->
        <div class="main-row row custom-row">
          <div mat-dialog-actions class="custom-actions">
            <button
              type="submit"
              mat-raised-button
              class="custom-save custom-label"
              [disabled]="addMicrositePageForm.invalid"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
