<div class="microsite-pages-add-popup">
  <div class="row custom-close-row">
    <button class="custom-close-btn" mat-icon-button [mat-dialog-close]="true">
      <span class="material-icons">close</span>
    </button>
  </div>

  <div class="container-fluid">
    <div mat-dialog-title class="popup-heading">Create Microsite</div>

    <div mat-dialog-content>
      <form novalidate #addTemplateForm="ngForm" (ngSubmit)="onSubmit(addTemplateForm)">
        <!-- Microsite Customer name choice -->
        <div class="main-row row">
          <mat-form-field
            class="full-width custom-margin"
            appearance="outline"
            [floatLabel]="'always'"
          >
            <mat-label class="custom-label custom-color">Customer</mat-label>
            <mat-select
              placeholder="Select a customer*"
              (selectionChange)="onCustomerChange($event)"
              [(value)]="selectedCustomer"
              [formControl]="selectCustomer"
            >
              <mat-option *ngFor="let customer of customers" [value]="customer">{{
                customer.name
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectCustomer.hasError('required')">
              <strong>Please select a Customer !</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="custom-error-message" *ngIf="showNoBuildingExistMessage">
          <strong>Selected customer already has microsite for all their buildings !</strong>
        </div>

        <!-- Microsite Building name choice -->
        <div class="main-row row">
          <mat-form-field
            class="full-width custom-margin"
            appearance="outline"
            [floatLabel]="'always'"
          >
            <mat-label class="custom-label custom-color">Building</mat-label>
            <mat-select
              placeholder="Select a building*"
              [(value)]="selectedBuilding"
              [formControl]="selectBuilding"
              [disabled]="!selectedCustomer || showNoBuildingExistMessage"
            >
              <mat-option
                class="multiline-mat-option"
                *ngFor="let building of buildings"
                [value]="building"
              >
                <div class="building-name">
                  {{ building.name || building.displayAddress || null }}
                </div>
                <div class="building-slug">{{ building.vtsBuildingSlug }}</div>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="selectBuilding.hasError('required')">
              <strong>Please select a Building !</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Microsite host name -->
        <div class="main-row row">
          <mat-form-field
            class="full-width custom-margin"
            appearance="outline"
            [floatLabel]="'always'"
          >
            <mat-label class="custom-label custom-color">Host name</mat-label>
            <span class="protocol">https://</span>
            <input
              matInput
              #micrositeUrlInput
              class="hostname-input"
              placeholder="Microsite host name"
              type="text"
              name="micrositeUrl"
              [(ngModel)]="formData.hostname"
              #micrositeUrl="ngModel"
              [disabled]="!selectedCustomer || showNoBuildingExistMessage"
              pattern="^([a-z0-9]+(-[a-z0-9]+)*\.){1,}([a-z]{2,3})$"
              (ngModelChange)="onHostnameValueChange($event)"
            />
            <mat-error
              *ngIf="
                !micrositeUrl?.valid &&
                (micrositeUrl?.dirty || micrositeUrl?.touched) &&
                micrositeUrl.errors
              "
            >
              <strong *ngIf="micrositeUrl.errors.pattern">Invalid Hostname !</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="custom-error-message" *ngIf="showDuplicateHostnameErrorMessage">
          <strong>{{ duplicateHostnameErrorMessage }}</strong>
        </div>

        <!-- Microsite name -->
        <div class="main-row row">
          <mat-form-field
            class="full-width custom-margin"
            appearance="outline"
            hintLabel="Max 60 characters"
            [floatLabel]="'always'"
          >
            <mat-label class="custom-label custom-color">Name</mat-label>
            <input
              matInput
              #micrositeTitleInput
              placeholder="Enter microsite name"
              name="micrositeName"
              type="text"
              [maxLength]="60"
              [(ngModel)]="formData.name"
              [disabled]="!selectedCustomer || showNoBuildingExistMessage"
              required
            />
            <mat-error *ngIf="micrositeName.invalid">
              <strong>You must enter a Name !</strong>
            </mat-error>
            <mat-hint align="end">{{ micrositeTitleInput.value?.length || 0 }}/60</mat-hint>
          </mat-form-field>
        </div>

        <!-- Microsite Description -->
        <div class="main-row row">
          <mat-form-field
            class="full-width custom-description custom-margin"
            appearance="outline"
            hintLabel="Max 120 characters"
            [floatLabel]="'always'"
          >
            <mat-label class="custom-label custom-color">Description</mat-label>
            <textarea
              matInput
              placeholder="Add microsite description"
              type="textarea"
              [(ngModel)]="formData.description"
              name="micrositeDescription"
              #micrositeDescription="ngModel"
              [maxLength]="120"
              [disabled]="!selectedCustomer || showNoBuildingExistMessage"
              required
            ></textarea>
            <mat-error *ngIf="micrositeDescription.invalid">
              <strong>You must enter a Description !</strong>
            </mat-error>
            <mat-hint align="end">{{ micrositeDescription.value?.length || 0 }}/120</mat-hint>
          </mat-form-field>
        </div>

        <!-- Save button -->
        <div class="main-row row custom-row">
          <div mat-dialog-actions class="custom-actions">
            <button
              type="submit"
              mat-raised-button
              class="custom-save custom-label"
              [disabled]="
                addTemplateForm.form.invalid ||
                selectBuilding.hasError('required') ||
                selectCustomer.hasError('required')
              "
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
