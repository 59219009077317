import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';

import { FlyerEditComponent } from './components/flyer-edit/flyer-edit.component';
import { ListComponent } from './components/list/list.component';
import { SigninPopupComponent } from './components/popups/signin-popup/signin-popup.component';
import { MicrositeEditComponent } from './components/list/microsite-edit/microsite-edit.component';

import { ExitGuardGuard } from './guards/exit-guard.guard';
import { AuthGuardGuard } from './guard/auth-guard.guard';


const routes: Routes = [
    { path: "", redirectTo: 'webpages', pathMatch: 'full' },
    { path: 'signin', component: SigninPopupComponent },
    { path: 'flyers', component: ListComponent },
    { path: 'flyers/edit', component: FlyerEditComponent },
    { path: 'flyers/edit/:id', component: FlyerEditComponent },
    { path: 'webpages', component: ListComponent, canActivate: [AuthGuardGuard] },
    { path: 'webpages/edit/:id/version/:version', component: FlyerEditComponent, canDeactivate: [ExitGuardGuard], canActivate: [AuthGuardGuard] },
    { path: 'flyers/edit/:id/version/:version', component: FlyerEditComponent, canDeactivate: [ExitGuardGuard], canActivate: [AuthGuardGuard] },
    { path: 'microsites', component: ListComponent, canActivate: [AuthGuardGuard] },
    { path: 'microsites/edit/:id', component: MicrositeEditComponent },
    { path: 'microsites/edit/:id/pages/:pageId', component: FlyerEditComponent, canDeactivate: [ExitGuardGuard], canActivate: [AuthGuardGuard] },
];


@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})


export class AppRoutingModule { }
