import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfigService {
  private appConfig;
  public appLoaded = false;

  constructor(private injector: Injector) {}

  private randomString(): String {
    return Math.random().toString(32).substring(2, 12);
  }

  private includeEmbedAppScript(): any {
    return new Promise((resolve, reject) => {
      let embedAppScript = document.createElement('script');
      embedAppScript.type = 'text/javascript';
      embedAppScript.async = false;
      embedAppScript.src = this.appConfig.APP.EMBED_APP_URL + this.randomString();
      document.getElementsByTagName('head')[0].appendChild(embedAppScript);
      embedAppScript.addEventListener('load', () => {
        resolve(embedAppScript);
      });
    });
  }

  async loadAppConfig() {
    let http = this.injector.get(HttpClient);
    let hashForFingerprinting = this.randomString()
    await http
      .get('/config/app-config.json?hash=' + hashForFingerprinting)
      .toPromise()
      .then((data) => {
        this.appConfig = data;
      });
    await this.includeEmbedAppScript();
    return;
  }

  get config() {
    return this.appConfig;
  }
}
