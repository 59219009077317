<mat-tab-group mat-align-tabs="start" *ngIf="router.url.includes('/flyers/edit') && dataLoaded | async" #tabs
  [selectedIndex]="selectedTab" (selectedTabChange)="selectTab($event)">
    
  <mat-tab label="BASIC">
    <!-- start of basic tab -->
    <div class="container-fluid">
      <!-- ID -->
      <div class="row">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Flyer ID</mat-label>
          <input matInput [(ngModel)]="this.flyerDetail.flyerId" placeholder="Flyer ID" [disabled]="true">
        </mat-form-field>
      </div>
      
      <!-- name -->
      <div class="row">
        <mat-form-field class="full-width" hintLabel="Max 60 characters" appearance="outline">
          <mat-label>Flyer Name</mat-label>
          <input matInput #input [maxLength]="60" [(ngModel)]="this.flyerDetail.name"
            placeholder="Flyer"  required
            (ngModelChange)="setIsValid()">
          <mat-error *ngIf="this.flyerDetail.name.length == 0">
            <strong>You must enter a name!</strong>
          </mat-error>
          <mat-hint align="end">{{input.value?.length || 0}}/60</mat-hint>
        </mat-form-field>
      </div>

      <!-- description  -->
      <div class="row">
        <mat-form-field class="full-width custom-desc" hintLabel="Max 120 characters" appearance="outline">
          <mat-label>Description</mat-label>
          <textarea matInput #description [maxLength]="120" placeholder="Sample description..."
            [(ngModel)]="this.flyerDetail.description"
             required
            (ngModelChange)="setIsValid()"></textarea>
          <mat-error *ngIf="this.flyerDetail.description.length == 0">
            <strong>You must enter a description!</strong>
          </mat-error>
          <mat-hint align="end">{{description.value?.length || 0}}/120</mat-hint>
        </mat-form-field>
      </div>

      <!-- slug -->
      <div class="row">
        <mat-form-field class="full-width" hintLabel="Max 100 characters" appearance="outline">
        <mat-label>Slug</mat-label>
        <input matInput #slug [maxLength]="100" [(ngModel)]="this.flyerDetail.slug"
          placeholder="Slug"  required
          (ngModelChange)="setIsValid()">
        <mat-error *ngIf="this.flyerDetail.slug.length == 0">
          <strong>You must enter a slug!</strong>
        </mat-error>
        <mat-hint align="end">{{slug.value?.length || 0}}/100</mat-hint>
        </mat-form-field>
      </div>

      <!-- customer -->
      <div class="row">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label class="custom-label">Customer</mat-label>
          <input matInput #input [maxLength]="60" readonly [(ngModel)]="this.flyerDetail.customerName" disabled>
        </mat-form-field>
      </div>

      <!-- customer -->
      <div class="row" *ngIf="this.flyerDetail.system == 'VTS'">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label class="custom-label">Api Src Url</mat-label>
          <input matInput #input [maxLength]="100" [(ngModel)]="this.flyerDetail.apiSrcUrl">
        </mat-form-field>
      </div>

      <!-- properties -->
      <div class="row">
     <!--   <mat-form-field class="full-width custom-margin" appearance = "outline">
          <mat-label class="custom-label">Property</mat-label>
          <mat-select disableRipple #select multiple required [formControl] = "selectProperty" [(ngModel)]="checkedProperties"
            (selectionChange)="selectedProperties(select, $event)"
            >
            <div class="select-all">
              <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                (ngModelChange)="toggleAllSelection()" 
                >
                Select All
              </mat-checkbox>
            </div>
            <mat-option (click)="optionClick()" *ngFor="let property of properties" [value]="property">{{property.propertyName}}</mat-option>
          </mat-select>
          <mat-error *ngIf="selectProperty.hasError('required')">
            <strong>Please select the Property!</strong>
          </mat-error>
          <mat-hint>Choose the Property.</mat-hint>
        </mat-form-field> -->
        <app-properties-dropdown class="full-width" [multiple]="true" [inputvar]="FlyerService.linkedpropertiesList" [inputSub]="FlyerService.linkedProperties" [selectAll]="allSelected" (propertySelected)="selectedProperties($event)" (checkAllAction)="toggleAllSelection($event)"></app-properties-dropdown>
        <div *ngIf="postFlyerSaveProcessing">Please wait.. Properties refreshing</div>
      </div>

    </div>
    <!-- end of basic tab -->
  </mat-tab>

  <mat-tab label="DETAILS">
    <!-- start of Details tab --> 

    <div style="text-align: center;">
      <button mat-flat-button (click)="onReplaceURLs()" class="replaceURLs" 
      >
        <mat-icon>find_replace</mat-icon>
        Replace Asset Links
      </button>
    </div>
              
    <button mat-icon-button (click)="onUpload()" class="upload-button"  matTooltip="Upload an asset file."
      aria-label="Upload an asset file"
      >
      <span class="material-icons">cloud_upload</span>
      Upload Files
    </button>

    <button mat-icon-button (click)="openAddFilePopup()" class="upload-button" matTooltip="Upload a Flyer file."
      aria-label="Upload a template file"
      >
      <span class="material-icons">note_add</span>
      Create File
    </button>
    <app-download-assets [source]="flyerDetail"></app-download-assets>

    <mat-expansion-panel [expanded]="true" #expansionPanelTemplate>
      <mat-expansion-panel-header class="panel-header" (click)="expandPanel(expansionPanelTemplate, $event)">
        <mat-panel-title>Template Files</mat-panel-title>
      </mat-expansion-panel-header>

      <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" 
        class="custom-file-upload" />

      <mat-list>
        <mat-list-item *ngFor="let file of files">
          <span id="file-label"></span>
          <h4 class="asset-file" matLine>{{file.data.name}}</h4>
          <mat-progress-bar [value]="file.progress"></mat-progress-bar>
        </mat-list-item>
      </mat-list>

      <mat-list>
        <mat-list-item *ngFor="let file of flyerFiles" (click)="onEdit(file)"
          [ngClass]="{black: isActive(file.name)}">
            
          <span id="file-label"></span>
            
          <h4 matLine>
            <span class="file-logo" [ngSwitch]="file.mediaType">
              <img *ngSwitchCase="'JS'" src="assets/icons/icons8-javascript-25.png" />
              <img *ngSwitchCase="'HTML'" src="assets/icons/icons8-html-5-25.png" />
              <img *ngSwitchCase="'CSS'" src="assets/icons/icons8-css3-25.png" />
              <img *ngSwitchDefault src="assets/icons/icons8-file-20.png" />
            </span>
            {{file.name}}
          </h4>
            
          <span class="deleted" *ngIf="file.deleted === true">Deleted</span>
            
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
            (click)=" $event.stopPropagation()" >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openRenamePopup(file.name)" *ngIf="file.name !== 'flyer.ftlh'">
              <mat-icon>autorenew</mat-icon>
              <span>Rename</span>
            </button>
            <button mat-menu-item (click)="copyS3URL(file.downloadUrl)" *ngIf="file.s3Url">
              <mat-icon>file_copy</mat-icon>
              <span>Copy URL</span>
            </button>
            <button mat-menu-item (click)="openDeletePopup(file)" *ngIf="file.name !== 'flyer.ftlh'">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item *ngIf="file.deleted === true" (click)="restoreFile(file.name)">
              <mat-icon>undo</mat-icon>
              <span>Restore</span>
            </button>
          </mat-menu>

        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true" #expansionPanelAssets>
      <mat-expansion-panel-header class="panel-header" (click)="expandPanel(expansionPanelAssets, $event)">
        <mat-panel-title>
          <span>Asset Files</span>
        </mat-panel-title>
      </mat-expansion-panel-header>


      <mat-list>
        <mat-list-item *ngFor="let file of assetFiles" (click)="onEdit(file)"
          [ngClass]="{black: isActive(file.name)}">

          <h4 matLine>
            <span class="file-logo" [ngSwitch]="file.mediaType">
              <img *ngSwitchCase="'JS'" src="assets/icons/icons8-javascript-25.png" />
              <img *ngSwitchCase="'HTML'" src="assets/icons/icons8-html-5-25.png" />
              <img *ngSwitchCase="'CSS'" src="assets/icons/icons8-css3-25.png" />
              <img *ngSwitchDefault src="assets/icons/icons8-file-20.png" />
            </span>
            {{file.name}}
          </h4>
            
          <span class="deleted" *ngIf="file.deleted === true">Deleted</span>
            
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
            (click)=" $event.stopPropagation()" >
            <mat-icon>more_vert</mat-icon>
          </button>
            
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openRenamePopup(file.name)">
              <mat-icon>autorenew</mat-icon>
              <span>Rename</span>
            </button>
            <button mat-menu-item (click)="copyS3URL(file.downloadUrl)" *ngIf="file.s3Url">
              <mat-icon>file_copy</mat-icon>
              <span>Copy URL</span>
            </button>
            <button mat-menu-item (click)="openDeletePopup(file)" *ngIf="file.s3Url">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item *ngIf="file.deleted === true" (click)="restoreFile(file.name)">
              <mat-icon>undo</mat-icon>
              <span>Restore</span>
            </button>
          </mat-menu>

        </mat-list-item>
      </mat-list>

    </mat-expansion-panel>
    <!-- end of details tab -->
  </mat-tab>

  <mat-tab label="ATTRIBUTES">
    <!-- start of Attributes tab -->

    <div class="container-fluid">
      <div class="row" style="margin-bottom: 8px; text-align: center;">
        <div class="col-12">
          <button mat-raised-button (click)="openAttributePopUp()" class="customFlyerAttributes" 
              >
              Add Flyer Attributes
          </button>
          &nbsp;
          <button mat-flat-button (click)="canReorder = !canReorder"  [hidden]="flyerAttributes.length == 0">
              Re-order
          </button>
        </div>
      </div>

      <div class="row" *ngIf="showAttribute">
        <div class="col-12" style="padding: 0;">
      
          <div class="attribute-box">
            <b class="mod-item">Name</b>
            <b class="mod-item">Value</b>
            <b class="mod-item">Action</b>
          </div>
          <div cdkDropList class="attribute-list" [ngClass]="{isReordering: canReorder}" (cdkDropListDropped)="drop($event)">
            <div class="attribute-box" *ngFor="let flyerAttribute of flyerAttributes" cdkDrag>
              <div class="mod-item">
                {{flyerAttribute.name}} 
                <br>
                  <span class="custom-span" 
                    *ngIf="(flyerAttribute.type === 'SLIDER' || flyerAttribute.type === 'CHOICES' || flyerAttribute.type === 'TEXT' || 
                      flyerAttribute.type === 'NUMBER' || flyerAttribute.type === 'COLOR' || flyerAttribute.type === 'FILE')">
                    {{flyerAttribute.type}}
                  </span>
                  <span class="custom-span" *ngIf="(flyerAttribute.type === 'SECTION_HEADING')">SECTION HEADING</span>
                  <span class="custom-span" *ngIf="(flyerAttribute.type === 'IMAGE_FILE')">IMAGE FILE</span>
                  <span class="custom-span" *ngIf="(flyerAttribute.type === 'PHOTO_PICKER_WITH_UPLOAD')">PHOTO PICKER</span>
                  <span class="custom-span" *ngIf="(flyerAttribute.type === 'PDF_APPEND')">PDF APPEND</span>
                  <span class="custom-span" *ngIf="(flyerAttribute.type === 'PDF_PREPEND')">PDF PREPEND</span>
              </div>
              <div class="mod-item">
                {{ flyerAttribute.defaultValue }}
              </div>
              
              <div class="attribute-options mod-item">
                <button mat-icon-button class="attribute-handle" cdkDragHandle [hidden]="!canReorder">
                  <mat-icon>reorder</mat-icon>
                </button>
                <div *ngIf="canReorder == false">
                  <button mat-icon-button [matMenuTriggerFor]="menu"  [hidden]="canReorder">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <!-- Edit -->
                    <button mat-menu-item class="custom-menu-item" (click)="openDialog('edit', flyerAttribute.flyerId, flyerAttribute, '')" >
                      Edit
                      </button>
                    <!-- Delete -->
                    <button mat-menu-item class="custom-menu-item" (click)="openDialog('delete', flyerAttribute.flyerId, '', flyerAttribute.id)">
                      Delete
                    </button>
                  </mat-menu>
                </div>
                
              </div>
              
            </div>
          </div>

        </div>
      </div>
        
    </div>
    <!-- end of attributes tab -->
  </mat-tab>

</mat-tab-group>