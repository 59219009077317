<div class="preview-dialog" *ngIf = "router.url.includes('/webpages/edit')">

    <div class="row custom-close-row">
        <button class="custom-close-btn" mat-icon-button [mat-dialog-close]="true">
            <span class="material-icons">close</span>
        </button>
    </div>
    
    
    <div class="container-fluid">
        
        <h1 mat-dialog-title><b>Preview Website</b></h1>
    
        <div class="center">
            <!-- ****************** customer name choice ****************** -->
            
            <ng-select appearance="outline" 
                [items]="customers" bindLabel="name" placeholder="Customer" [(ngModel)]="this.customer" disabled>
            </ng-select>
    
            <!-- ****************** PDP choice ****************** -->

            <ng-select appearance="outline"
                [items]="propertyDetailPages" bindLabel="name" placeholder="Select Property Detail Page" *ngIf="!isPDP" 
                [formControl]="selectPDP" [(ngModel)]="selectedPDP" [clearable]=true>
            </ng-select>

            <ng-select appearance="outline" 
                [items]="propertyDetailPages" bindLabel="name" placeholder="Property Detail Page" *ngIf="isPDP"
                [(ngModel)]="this.pdpName" disabled>
            </ng-select>

            <!-- ****************** Search page choice ****************** -->     

            <ng-select appearance="outline"
                [items]="searchPages" bindLabel="name" placeholder="Select Search Page" *ngIf="isPDP" 
                [formControl]="selectSearchPage" [(ngModel)]="selectedSearchPage" [clearable]=true>
            </ng-select>

            <ng-select appearance="outline" 
                [items]="searchPages" bindLabel="name" placeholder="Search Page" *ngIf="!isPDP" 
                [(ngModel)]="this.searchPageName" disabled>
            </ng-select>

        </div>
    </div>

    <!-- Generate preview buttons -->
    <div class="row justify-content-center">
        <div mat-dialog-actions class="custom-dialog-actions">
            <button mat-toggle-button class="custom-preview-btn" (click)="generateLink()" *ngIf = "isPDP"
                [disabled]="!selectedSearchPage" mat-button>
                <!-- mat-button> -->
                Generate Link
            </button>
            <button mat-toggle-button class="custom-preview-btn" (click)="generateLink()" *ngIf = "!isPDP"
                [disabled]="!selectedPDP" mat-button>
                Generate Link
            </button>
        </div>
    </div>

    <!-- To show link -->
    <div class="row custom-row" *ngIf="showLink">
        <div class="col-1 custom-icon">
            <span class="material-icons">insert_link</span>    
        </div>
        <div class="col-9 custom-link">
            <input matInput disabled [(ngModel)]="link">
        </div>
        <div class="col-2">
            <button class="copy-btn" mat-button [cdkCopyToClipboard]="link">Copy</button>
        </div>
    </div>
    
</div>

<div class="preview-dialog" *ngIf = "router.url.includes('/flyers/edit')">

    <div class="row custom-close-row">
        <button class="custom-close-btn" mat-icon-button [mat-dialog-close]="true">
            <span class="material-icons">close</span>
        </button>
    </div>
        
    <div class="container-fluid">
        
        <h1 mat-dialog-title><b>Preview Flyer</b></h1>
    
        <div class="center">
            <!-- ****************** Flyer Name ****************** -->

            <div class="row">
                <mat-form-field class="full-width custom-margin" hintLabel="Max 60 characters" appearance="outline">
                    <mat-label class="custom-label custom-color">Flyer Name</mat-label>
                    <input matInput placeholder="Flyer Name" readonly type="text" [(ngModel)]="flyerDetail.name" required>
                </mat-form-field>
            </div>
           
            <!-- ****************** Property choice ****************** -->

            <div class="row">
                <br />
                <!-- <mat-form-field class="full-width custom-margin" appearance="outline">
                  <mat-label class="custom-label">Property</mat-label>
                  <mat-select required disableRipple [formControl] = "selectProperty" (selectionChange)="selectedProperties($event)">
                    <mat-option *ngFor="let property of properties" [value]="property.propertyGlobalId">{{property.propertyName}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="selectProperty.hasError('required')">
                    <strong>Please select the Property!</strong>
                  </mat-error>
                  <mat-hint>Choose the Property.</mat-hint>
                </mat-form-field>   -->
                <input type="hidden" name="property" [formControl] = "selectProperty">
                <app-properties-dropdown [inputvar]="flyerService.statusEnabledProperties" [inputSub]="flyerService.statusEnabledLinkedProperties" (propertySelected)="selectedProperties($event)"></app-properties-dropdown>
            </div>
        </div>
    </div>

    <!-- Generate preview buttons -->
    <div class="row justify-content-center">
        <div mat-dialog-actions class="custom-dialog-actions">
            <button mat-toggle-button class="custom-preview-btn" (click)="generatePDFLink()" [disabled]="selectProperty.hasError('required')" mat-button>
                Generate link
            </button>
        </div>
    </div>

    <div class="row row justify-content-center">
        <mat-spinner *ngIf="showSpinner"></mat-spinner>
      </div>

    <!-- To show link -->
    <div class="row custom-row" *ngIf="showLink">
        <div class="col-1 custom-icon">
            <span class="material-icons">insert_link</span>    
        </div>
        <div class="col-9 custom-link">
            <input matInput disabled [(ngModel)]="link">
        </div>
        <div class="col-2">
            <button class="copy-btn" mat-button [cdkCopyToClipboard]="link">Copy</button>
        </div>
    </div>
    
</div>