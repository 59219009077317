<div class="container-fluid">

    <div class="icon-box">
        <i class="material-icons custom-icon">priority_high</i>
    </div>
    
    <h2 mat-title><strong>Are you sure?</strong></h2>

    <div mat-dialog-content>
        <div class="row justify-content-center" >
        <p>Do you really want to delete? This cannot be undone.</p>
        </div>
    </div>

    <div class="row justify-content-center">
        <div mat-dialog-actions>
            <button mat-raised-button class= "custom-btn" [mat-dialog-close]="true">Cancel</button>
            <button mat-raised-button class="btn btn-danger custom-danger-btn" [mat-dialog-close]="true" (click)="deleteTemplate()">Yes, Delete</button>
        </div>
    </div>
    
</div>