import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';

import {WebpagesService} from '../../../services/webpages.service';
import { FlyerService } from 'src/app/services/flyer.service';
import { interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-open-in-new-tab-popup',
  templateUrl: './open-in-new-tab-popup.component.html',
  styleUrls: ['./open-in-new-tab-popup.component.css']
})


export class OpenInNewTabPopupComponent implements OnInit, OnDestroy {

  /* To show preview link */
  showLink: boolean = false;
  link:string;

  /* To select customers, search and property detail pages for dynamic preview URL */
  public customers: any;
  public searchPages: any;
  public propertyDetailPages: any;

  /* Selected customer, search and property detail page */
  selectedCustomer:any;
  selectedSearchPage:any;
  selectedPDP:any;
  selectCustomer = new FormControl('', [Validators.required]);
  selectPDP = new FormControl('', [Validators.required]);
  selectSearchPage = new FormControl('', [Validators.required]);

  /* Query params for preview link generation */
  customerGlobalId:any;
  pdpTemplateId:any;
  pdpTemplateVersionId:any;
  searchTemplateId:any;
  searchTemplateVersionId:any;

  /* To get the total number of templates - required for sending as pagesize as the default number of templates returned is 30 */
  pgTotalElements: Subscription;

  /* To retreive the details of the current template */
  customerName: Subscription;
  pdpName: Subscription;
  searchPageName: Subscription;
  webpageType: any;
  templateDetail: any;
  customer: any;
  templateName: any;
  isPDP: boolean;

  /* Flyer details */ 
  flyerDetail: any;

  /* Linked properties to the flyer */ 
  linkedProperties: Subscription;

  /* Assign properties for property selection drop down */ 
  properties: any = [];

  /* Selected Property value */
  selectProperty = new FormControl('', [Validators.required]);

  /* Request Id for flyer PDF generation polling */
  requestId: any;

  /* Display spinner until PDF is generated from BE */
  showSpinner: boolean = false;

  /* property Global Id of the selected property */
  propertyGlobalId: any;

  pollingData: any;


  constructor(
    public WebpagesService: WebpagesService, public flyerService: FlyerService,
    public router: Router
  ) {
    this.linkedProperties = this.flyerService.statusEnabledLinkedProperties.subscribe((data) => {
      this.assignProperties(data);
    })
  }
  
  ngOnInit(): void {

    if (this.router.url.includes("webpages/edit")){
      this.templateDetail = this.WebpagesService.templateDetail;

      /* For customer field */
      this.customer = this.templateDetail.customerName;

      /* For PDP and Search field */
      this.templateName = this.templateDetail.name;
      this.webpageType = this.templateDetail.type;
      if (this.webpageType === 'PDP') {
        this.pdpName = this.templateDetail.name
        this.isPDP = true;
      } else if (this.webpageType === 'SEARCH') {
        this.searchPageName = this.templateDetail.name
        this.isPDP = false;
      }

      /* Property Detail pages dropdown */
      this.WebpagesService.getTemplatesByType('PDP',this.customer, '100000000').subscribe(data => { this.propertyDetailPages = data['contents'] });
      
      /* Search pages dropdown */
      this.WebpagesService.getTemplatesByType('SEARCH', this.customer, '100000000').subscribe(res => { this.searchPages = res['contents'] });
    }
    else if (this.router.url.includes("flyers/edit")) {
      this.flyerDetail = this.flyerService.flyerDetail;
      this.properties = this.flyerService.statusEnabledProperties;
    }

  }

  assignProperties(properties){
    this.properties = properties;
  }

  selectedProperties(event){
    if (event.propertyGlobalId) {
      this.selectProperty.setValue(event.propertyGlobalId);
      this.flyerService.propertyGlobalId = event.propertyGlobalId;
      this.propertyGlobalId = event.propertyGlobalId;
    }
    
  }

  /* Set webpage type for selecting the correct endpoint and to show/hide the property dropdown */
  setWebpageType(type){
    this.webpageType = type
    if (this.webpageType === 'PDP'){
      this.isPDP = true;
    } else {
      this.isPDP = false;
    }
  }

  /* Select respective templates for the selected customer */
  selectPages(){
    let customer = this.selectedCustomer.name;
    this.getPropertyDetailPages(customer);
    this.getSearchPages(customer)
  }

  /* Select PDP templates for the selected customer */
  getPropertyDetailPages(customer){
    this.WebpagesService.getTemplatesByType('PDP', customer , '100000000').subscribe(data => { this.propertyDetailPages = data['contents'] });
  }

  /* Select Search templates for the selected customer */
  getSearchPages(customer){
    this.WebpagesService.getTemplatesByType('SEARCH', customer, '100000000').subscribe(res => { this.searchPages = res['contents'] });
  }

  /* Generate preview link for selected templates of the customer */
  generateLink(){
    this.customerGlobalId = this.WebpagesService.templateDetail.customerGlobalId;

    if (this.webpageType === 'SEARCH') {
      this.searchTemplateId = this.WebpagesService.templateDetail.id
      this.searchTemplateVersionId = this.WebpagesService.templateDetail.version
      this.pdpTemplateId = this.selectedPDP.id
      this.pdpTemplateVersionId = this.selectedPDP.version
    } else if (this.webpageType === 'PDP') {
      this.searchTemplateId = this.selectedSearchPage.id
      this.searchTemplateVersionId = this.selectedSearchPage.version
      this.pdpTemplateId = this.WebpagesService.templateDetail.id
      this.pdpTemplateVersionId = this.WebpagesService.templateDetail.version
    }

    this.createDynamicLink(this.customerGlobalId, this.searchTemplateId, this.searchTemplateVersionId, this.pdpTemplateId, this.pdpTemplateVersionId);
  }

  generatePDFLink(){
    this.flyerService.renderFlyerPDF(this.propertyGlobalId, this.flyerService.flyerDetail.flyerId).subscribe((data) => {
      if(data){
        if (data['status'] == 'PENDING' || data['status'] == 'PROCESSING'){
          this.requestId = data['id'];
          this.showSpinner = true;
          this.pollingData = interval(5000).pipe(startWith(0), switchMap(() => this.flyerService.flyerGenerationStatus(this.requestId)))
            .subscribe(data => {
              if (data['status'] == 'PENDING' || data['status'] == 'PROCESSING'){
                // just wait
              } else if (data['status'] == 'SUCCESSFUL'){
                this.pollingData.unsubscribe();
                this.onSucessfulLinkGeneration();
              } else {
                this.pollingData.unsubscribe();
                this.showSpinner = false;
                this.flyerService.showToastMessage("Oops, something went wrong! Please try again later.", "", 'danger');
              }
            }, err => {
              this.pollingData.unsubscribe();
              this.showSpinner = false;
              this.flyerService.showToastMessage("PDF queue full! Try again later.", "", 'danger');
            });
        } 
        else if (data['status'] == 'SUCCESSFUL'){
          this.onSucessfulLinkGeneration();
        }
      }
    })
  }

  onSucessfulLinkGeneration() {
    this.flyerService.flyerPDFLink(this.flyerService.propertyGlobalId, this.flyerService.flyerDetail.flyerId).subscribe((link) => {
      if (link['previewUrl']) {
        this.showSpinner = false;
        this.showLink = true;
        this.assignLink(link['previewUrl']);
      } else {
        this.showSpinner = false;
        this.flyerService.showToastMessage('Oops! Flyer link was not generated properly', "", 'danger');
      }
    }, err => {
      this.showSpinner = false;
      this.flyerService.showToastMessage('Something went wrong! Please try again later.', "", 'danger');
    })
  }

  /* Call dynamic link endpoint */
  createDynamicLink( customerGlobalId, searchTemplateId, searchTemplateVersionId, pdpTemplateId, pdpTemplateVersionId ){
    this.WebpagesService.createDynamicPreviewLink(customerGlobalId, searchTemplateId, searchTemplateVersionId, pdpTemplateId, pdpTemplateVersionId).subscribe((data: any) => {
      if(data && data.previewUrl) {
        this.assignLink(data.previewUrl);
        this.showLink = true;
      }
    })
  }

  /* Assign link */
  assignLink = link => this.link = link;

  ngOnDestroy() {
    this.pollingData && this.pollingData.unsubscribe();
  }
}
