import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MicrositesService } from '../../../services/microsites.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DeletePopupComponent } from './../../popups/delete-popup/delete-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-microsites-data-table',
  templateUrl: './microsites-data-table.component.html',
  styleUrls: ['./microsites-data-table.component.css'],
})
export class MicrositesDataTableComponent implements OnInit, OnDestroy {
  chosenCustomer: string = '';
  chosenStatus: string = '';
  chosenBuilding: any;
  chosenCustomerSlug: string = '';
  chosenBuildingId: string = '';
  statusOptions: Array<{ name: string; id: number }> = [
    { name: 'Onboarding', id: 1 },
    { name: 'Ready For Approval', id: 2 },
    { name: 'Live', id: 3 },
    { name: 'Inactive', id: 4 },
    { name: 'Retired', id: 5 },
  ];
  areFilterEmpty: boolean = true;
  showMicrositeList: boolean = true;
  dataSource: any = new MatTableDataSource();
  columnsToDisplay: string[] = [
    'buildingName',
    'micrositeName',
    'micrositeDescription',
    'customer',
    'status',
    'lastPublished',
    'actions',
  ];
  micrositesListDataSource: Subscription;
  pgNo: Subscription;
  pgSize: Subscription;
  pgTotalElements: Subscription;

  /* Pagination */
  pageNo: number; //The current page index
  pageSize: number; //Items per page
  length: number; //To get total number of elements in the data table

  /* Routing */
  route: string;
  path: string;

  customers: any;
  buildings: any;
  // ALL option in the filter
  allAOptionArray = [
    {
      customerGlobalId: 0,
      id: 0,
      name: ' ALL', //The space is introduced to show this option as first
    },
  ];

  // To reset the form fields of the filters
  filterCustomer = new FormControl();
  filterStatus = new FormControl();
  filterBuilding = new FormControl();
  private dialogRef;

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    public micrositesService: MicrositesService,
    public dialog: MatDialog
  ) {
    this.router = router;

    this._route.queryParams.subscribe((params) => {
      if (params['customer']) this.chosenCustomerSlug = params['customer'];
      if (params['buildingId']) this.chosenBuildingId = params['buildingId'];
      if (params['status']) this.chosenStatus = params['status'];
    });
  }

  ngOnInit(): void {
    this.loadMicrositeList();
    this.fetchCustomersAndBuildingsDropdownList();
  }

  fetchBuildingsDropdownList(customerSlug: String, createdMicrosites: Boolean) {
    this.micrositesService.getBuildings(customerSlug, createdMicrosites).subscribe((buildings) => {
      this.buildings = buildings;
      if (this.buildings && this.buildings.length) {
        this.buildings = this.buildings.sort((a, b) => a.name.localeCompare(b.name));
        if (this.chosenBuildingId != '') {
          this.chosenBuilding = this.buildings.filter(function (building) {
            return building.id == this.chosenBuildingId;
          }, this)[0];
        }
        this.toogleResetFilter();
      }
    });
  }

  fetchCustomersAndBuildingsDropdownList() {
    this.micrositesService.getCustomers().subscribe((customers: Array<any>) => {
      this.customers = customers;
      if (this.customers) {
        Array.prototype.push.apply(this.customers, this.allAOptionArray);
        this.customers = this.customers.sort((a, b) => a.name.localeCompare(b.name));
      }
      if (this.chosenCustomerSlug == ' ALL') {
        this.chosenCustomer = ' ALL';
      }
      if (this.chosenCustomerSlug != '' && this.chosenCustomerSlug != ' ALL') {
        this.chosenCustomer = this.customers
          .filter(function (customer) {
            return customer.truvaLandlordSlug == this.chosenCustomerSlug;
          }, this)
          .map(function (customer) {
            return customer.name;
          })[0];
        this.fetchBuildingsDropdownList(
          this.findCurrentSelectedCustomerByName(this.chosenCustomer).truvaLandlordSlug,
          true
        );
      } else {
        this.fetchBuildingsDropdownList('', true);
      }
      this.toogleResetFilter();
    });
  }

  loadMicrositeList() {
    /* Get the hardcoded values of page number and page size from webservice */
    this.pageNo = this.micrositesService.pageIndex - 1;
    this.pageSize = this.micrositesService.pageSize;

    /* Retrieve the microsites list */
    this.micrositesListDataSource =
      this.micrositesService.micrositesListDataSourceChanged.subscribe((data) => {
        if (data) {
          this.assignData(data);
        }
      });

    /* Get the total elements */
    this.pgTotalElements = this.micrositesService.sharedTotalElements$.subscribe(
      (pageTotalements) => {
        this.setLength(pageTotalements);
      }
    );

    /* Get the page No. */
    this.pgNo = this.micrositesService.sharedPageNo$.subscribe((pageNo) => {
      this.pageNo = pageNo - 1;
    });

    /* Get the page size */
    this.pgSize = this.micrositesService.sharedPageSize$.subscribe((pageSize) => {
      this.setPageSize(pageSize);
    });

    this.micrositesService.loadMicrositesOnPagination(
      ++this.pageNo,
      this.pageSize,
      this.chosenCustomerSlug,
      this.chosenBuildingId,
      this.chosenStatus
    );
  }

  toogleResetFilter() {
    this.areFilterEmpty =
      this.chosenCustomer || this.chosenStatus || this.chosenBuilding ? false : true;
  }

  findCurrentSelectedCustomerByName(chosenCustomer: String) {
    if (chosenCustomer == ' ALL' || chosenCustomer == '') {
      return '';
    }
    return this.customers.find((customer) => customer.name == chosenCustomer);
  }

  editMicrosite(id: string) {
    this.router.navigateByUrl('microsites/edit/' + id);
  }

  getBuildingAddress(data: any) {
    let buildingAddress = data.address ? data.address + ',' : '';
    let buildingCity = data.city ? data.city + ',' : '';
    let buildingState = data.state ? data.state : '';
    return buildingAddress + buildingCity + buildingState;
  }

  assignData(microsites: any) {
    this.dataSource.data = microsites;
    this.micrositesService.sharedDataSource.data = this.dataSource.data;
    if (this.dataSource.data && this.dataSource.data.length) {
      this.micrositesService.showCreateMicrositesButton = true;
      this.showMicrositeList = true;
    } else {
      this.showMicrositeList = false;
    }
  }

  setLength(length) {
    this.length = length;
    this.micrositesService.totalElements = this.length;
  }

  setPageNo(pageNumber) {
    this.pageNo = pageNumber;
    this.micrositesService.pageIndex = this.pageNo;
  }

  setPageSize(pageSize) {
    this.pageSize = pageSize;
    this.micrositesService.pageSize = this.pageSize;
  }

  onPagination = ($event) =>
    this.micrositesService.loadMicrositesOnPagination(
      ++$event.pageIndex,
      $event.pageSize,
      this.chosenCustomerSlug,
      this.chosenBuildingId,
      this.chosenStatus
    );

  copyMicrosite(microsite) {
    this.micrositesService.copyMicrosite(microsite.id, microsite).subscribe((response) => {
      if (response && this.router.url.includes('/microsites')) {
        this.router.navigateByUrl('microsites/edit/' + response['id']);
        this.micrositesService.showToastMessage(
          'Microsite Copied Successfully!',
          'check_circle',
          'success'
        );
      }
    });
  }

  onFilter(event: any, filterType: String) {
    if (filterType === 'status') {
      this.micrositesService.loadMicrositesOnPagination(
        ++this.pageNo,
        this.pageSize,
        this.chosenCustomerSlug,
        this.chosenBuildingId,
        this.chosenStatus
      );
    } else {
      if (filterType === 'customer') this.resetBuildingsFilter();
      if (this.chosenCustomer === ' ALL') {
        this.chosenCustomerSlug = ' ALL';
        this.micrositesService.loadMicrositesOnPagination(++this.pageNo, this.pageSize, '', '', '');
        this.fetchBuildingsDropdownList('', true);
      } else {
        this.chosenCustomerSlug =
          this.chosenCustomer != ''
            ? this.findCurrentSelectedCustomerByName(this.chosenCustomer).truvaLandlordSlug
            : '';
        if (filterType === 'building') {
          this.chosenBuilding = event;
        }
        this.chosenBuildingId = this.chosenBuilding ? this.chosenBuilding.id : '';
        this.micrositesService.loadMicrositesOnPagination(
          ++this.pageNo,
          this.pageSize,
          this.chosenCustomerSlug,
          this.chosenBuildingId,
          this.chosenStatus
        );
        this.fetchBuildingsDropdownList(
          this.findCurrentSelectedCustomerByName(this.chosenCustomer).truvaLandlordSlug,
          true
        );
      }
    }

    this.router.navigate([], {
      queryParams: {
        customer: this.chosenCustomerSlug,
        buildingId: this.chosenBuildingId,
        status: this.chosenStatus,
      },
      queryParamsHandling: 'merge',
    });

    this.toogleResetFilter();
  }

  /* To reset the filters */
  resetFilters() {
    this.resetBuildingsFilter();
    this.resetCustomerFilter();
    this.resetStatusFilter();
    this.path = this._route.snapshot.url[0].path;
    this.router.navigate(['/' + this.path]);
    this.toogleResetFilter();
    this.micrositesService.loadMicrositesOnPagination(++this.pageNo, this.pageSize, '', '', '');
    this.fetchBuildingsDropdownList('', true);
  }

  resetBuildingsFilter() {
    this.filterBuilding.setValue('');
    this.chosenBuilding = this.chosenBuildingId = '';
  }

  resetCustomerFilter() {
    this.filterCustomer.setValue('');
    this.chosenCustomer = this.chosenCustomerSlug = '';
  }

  resetStatusFilter() {
    this.filterStatus.setValue('');
    this.chosenStatus = '';
  }

  doItemsHaveSameIds(item, selected) {
    return item.id === selected.id;
  }

  confirmDelete(micrositeId: string) {
    this.dialog.open(DeletePopupComponent, { data: { micrositeId } });
  }

  isMicrositeRetired(status: string) {
    return status.toLowerCase() === 'retired';
  }

  ngOnDestroy() {
    this.micrositesListDataSource && this.micrositesListDataSource.unsubscribe();
  }
}
