<mat-toolbar *ngIf="router.url.includes('/webpages/edit')">
  <div class="name-section" *ngIf="dataLoaded | async">
    <button mat-icon-button class="back" (click)="onBack()">
      <mat-icon>arrow_back_ios</mat-icon>
      BACK
    </button>
    <h1 class="name">
      {{ webpageName }}
    </h1>
    <span class="status" [ngStyle]="{ color: webpageStatus === 'DRAFT' ? '#EFB815' : '#5AB524' }">{{
      webpageStatus
    }}</span>
  </div>
  <span class="example-spacer"></span>
  <button
    mat-flat-button
    class="btn view-btn"
    (click)="onPublish()"
    [disabled]="webpageStatus == 'PUBLISHED'"
  >
    Publish
  </button>
  <button
    mat-flat-button
    class="btn save-btn"
    (click)="onSaveTemplate()"
    [disabled]="
      webpageStatus == 'PUBLISHED' ||
      !(webPageService.isModified() && this.webPageService.basicFormValid)
    "
  >
    Save
  </button>
</mat-toolbar>

<mat-toolbar *ngIf="router.url.includes('/flyers/edit')">
  <div class="name-section" *ngIf="dataLoaded | async">
    <button mat-icon-button class="back" (click)="onBack()">
      <mat-icon>arrow_back_ios</mat-icon>
      BACK
    </button>
    <h1 class="name">
      {{ flyerName }}
    </h1>
    <span class="status" [ngStyle]="{ color: flyerStatus === 'DRAFT' ? '#EFB815' : '#5AB524' }">{{
      flyerStatus
    }}</span>
  </div>
  <span class="example-spacer"></span>
  <button
    mat-flat-button
    class="btn save-btn"
    [hidden]="flyerStatus != 'PUBLISHED'"
    (click)="showFlyerPropertyStatus()"
  >
    Status
  </button>
  <button
    mat-flat-button
    class="btn view-btn"
    (click)="onPublish()"
    [disabled]="this.flyerService.notSave"
  >
    {{ flyerStatus == "PUBLISHED" ? "Republish" : "Publish" }}
  </button>
  <button
    mat-flat-button
    class="btn save-btn"
    (click)="onSaveTemplate()"
    [disabled]="
      this.flyerService.notSave || !(flyerService.isModified() && this.flyerService.basicFormValid)
    "
  >
    Save
  </button>
</mat-toolbar>

<div class="microsite-edit-header" *ngIf="router.url.includes('/pages')">
  <app-microsite-edit-header></app-microsite-edit-header>
</div>
