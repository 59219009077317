<div class="row custom-close-row">
  <button class="custom-close-btn" mat-icon-button [mat-dialog-close]="true" (click)="onCloseDialog()">
    <span class="material-icons">close</span>
  </button>
</div>

<div class="container-fluid center">

  <div mat-dialog-title>Set as homepage</div>

  <div mat-dialog-content class="center">
    Are you sure you want to make this page the front page of your site? It will be the first thing visitors of your
    site will see.
  </div>

  <!-- Save button -->
  <div class="row custom-row">
    <div mat-dialog-actions class="custom-actions">
      <button type="submit" mat-raised-button class="custom-save custom-label" (click)="onConfirmDialog()"
        [mat-dialog-close]="true">Confirm</button>
    </div>
  </div>

</div>
