import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Service imports
import { MicrositesService } from 'src/app/services/microsites.service';

// Material imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-microsite-page-unpublish-popup-component',
  templateUrl: './microsite-page-unpublish-popup-component.component.html',
  styleUrls: ['./microsite-page-unpublish-popup-component.component.css'],
})
export class MicrositePageUnpublishPopupComponentComponent implements OnInit {
  constructor(
    private router: Router,
    public micrositesService: MicrositesService,
    public dialogRef: MatDialogRef<MicrositePageUnpublishPopupComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  unPublishMicrositePage() {
    this.micrositesService
      .unPublishMicrositePage(
        this.data.micrositeId.toString(),
        this.data.micrositePageId.toString(),
        this.micrositesService.currentMicrositeData
      )
      .subscribe((data: any) => {
        this.micrositesService.showToastMessage(
          'Microsite Page Unpublished Successfully!',
          'check_circle',
          'success'
        );
        this.micrositesService.assignCurrentMicrositeData(data);
        if (this.data.reload) {
          this.reloadCurrentPage();
        }
      });
  }

  reloadCurrentPage() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
