import { Component, OnInit, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-show-image-file-popup',
  templateUrl: './show-image-file-popup.component.html',
  styleUrls: ['./show-image-file-popup.component.css']
})


export class ShowImageFilePopupComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any ) { }

  ngOnInit(): void { }


}
