<div class="dialog">

    <h1 mat-dialog-title>
        <strong>Rename </strong>
        <span class="custom-font">{{data.fileName}}</span>
    </h1>

    <div mat-dialog-content>

        <mat-form-field appearance="outline">
            <mat-label>Rename file</mat-label>
            <input matInput [formControl]="newFileName">
        </mat-form-field>
        
        <span class="error" *ngIf="duplicateFileName == true">
            <strong>Filename already exists!</strong>
        </span>
        
        <span class="error" *ngIf="duplicateFileName == null">
            <strong>Filename cannot be empty.</strong>
        </span>
        
        <span class="error" *ngIf="duplicateFileName == 'incorrectExtension'">
            <strong>Extension should be same.</strong>
        </span>

    </div>

    <div mat-dialog-actions class="custom-actions">
        <button mat-raised-button mat-dialog-close>Close</button>
        <button mat-raised-button class="custom-save" (click)="renameFile(data.fileName)">Save</button>
    </div>

</div>