import { Component, OnInit, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { DeletePopupComponent } from '../../popups/delete-popup/delete-popup.component';
import { FlyerPublishPopupComponent } from '../../popups/flyer-publish-popup/flyer-publish-popup.component';
import { FlyerUnpublishPopupComponent } from '../../popups/flyer-unpublish-popup/flyer-unpublish-popup.component';

import { FlyerService } from '../../../services/flyer.service';


@Component({
  selector: 'app-flyer-data-table',
  templateUrl: './flyer-data-table.component.html',
  styleUrls: ['./flyer-data-table.component.css']
})


export class FlyerDataTableComponent implements OnInit {

  /* Filters */
  // Value of the customer and/or status from the filter field
  choosenCustomer: any = ""
  choosenStatus: string = "";
  
  // To reset the form fields of the filters
  filterCustomer = new FormControl();
  filterStatus = new FormControl();
  
  // The options for Status field
  statusOptions = [
    { name: "DRAFT", id: "1" },
    { name: "PUBLISHED", id: "2" }
  ]
  
  // To select customers while filtering data
  public customers: any;
  
  // ALL option in the filter
  allAOptionArray = [{
    customerGlobalId: 0,
    id:0,
    name: ' ALL' //The space is introduced to show this option as first - hack :)
  }];

  allCustomers:any;

  /* Routing */
  router: Router;
  route: string;
  path: string;

  /* DATA TABLE */
  dataSource = new MatTableDataSource();
  columnsToDisplay: string[];

  data: Subscription;
  PaginatedData: Subscription;
  pgNo: Subscription;
  pgSize: Subscription;
  pgTotalElements: Subscription;

  /* Pagination */
  pageNo: number; //The current page index
  pageSize: number; //Items per page
  length: number; //To get total number of elements in the data table

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageEvent: PageEvent;
  previousPageIndex: any;

  constructor(
    public dialog: MatDialog, 
    private _router: Router, private _route: ActivatedRoute, 
    public FlyerService: FlyerService
  ) {
    this.router = _router;
    this._route.queryParams.subscribe(params => {
      if (params['customer']) this.choosenCustomer = params['customer'];
      if (params['status']) this.choosenStatus = params['status'];
    });
  }

  ngOnInit(): void {

    /* Customers filter dropdown */
    this.FlyerService.getCustomers().subscribe(res => { 
      this.customers = res 
      Array.prototype.push.apply(this.customers,this.allAOptionArray); 
      this.customers = this.customers.sort((a, b) => a.name.localeCompare(b.name))
    });

    /* select the correct columns*/
    if (this._router.url.includes('/flyers')) {
      this.columnsToDisplay = ['name', 'description', 'customer', 'lastModified', 'actions']
    }

    /* Get the hardcoded values of page number and page size from webservice */
    this.pageNo = this.FlyerService.pageIndex - 1;
    this.pageSize = this.FlyerService.pageSize;

    /* RETRIEVE THE TEMPLATE DATA LIST */
    this.data = this.FlyerService.sharedDataSourceChanged$.subscribe( (data) => { this.assignData(data) })
    
    /* Get the total elements */
    this.pgTotalElements = this.FlyerService.sharedTotalElements$.subscribe( (pageTotalements) => { this.setLength(pageTotalements); })

    /* Get the page No. */
    this.pgNo = this.FlyerService.sharedPageNo$.subscribe( (pageNo) => { this.pageNo = pageNo - 1 })

    /* Get the page size */
    this.pgSize = this.FlyerService.sharedPageSize$.subscribe( (pageSize) => { this.setPageSize(pageSize) })

    /* Loading flyer data */
    this.FlyerService.loadFlyersOnPagination(++this.pageNo, this.pageSize, this.choosenStatus, this.choosenCustomer);
  }

  /* Assign data to the datasource */
  assignData(data) {
    this.dataSource.data = data
    this.FlyerService.sharedDataSource.data = this.dataSource.data
  }

  /* To filter templates by Customers and status*/
  onFilter() {
    let customer = this.choosenCustomer ? this.choosenCustomer : "", status = this.choosenStatus ? this.choosenStatus : ""

    if (customer === ' ALL') {
      this.FlyerService.filterFlyers("", status);
    }
    else { 
      this.FlyerService.filterFlyers(customer, status);
    }
    
    this._router.navigate([], {
      queryParams: { customer: customer, status: status },
      queryParamsHandling: 'merge'
    });
  }

  /* To reset the filters */
  resetFilters() {
    this.filterCustomer.setValue('')
    this.filterStatus.setValue('')
    this.path = this._route.snapshot.url[0].path
    this._router.navigate(['/' + this.path])
    this.FlyerService.loadFlyers()
  }

  /* To open Delete, Publish and unpublish popup */
  openDialog(action, id, flyerId, version, status) {
    if (action == 'delete') {
      this.FlyerService.templateID = id;
      this.FlyerService.flyerVersion = version;
      const dialogRef = this.dialog.open(DeletePopupComponent);
    } 
    else if (action == 'publish' || 'unpublish') {
      this.FlyerService.flyerId = flyerId;
      this.FlyerService.flyerStatus = status;
      if (action == 'publish'){
        const dialogRef = this.dialog.open(FlyerPublishPopupComponent, {
          data: { flyerId: flyerId }
        }); 
      } else if (action == 'unpublish') {
        const dialogRef = this.dialog.open(FlyerUnpublishPopupComponent);
      }
    }
  }

  /* To navigate to the edit section of the flyer - Making the data table row clickable */
  editTemplate(id, version) {
    if (this._router.url.includes('/flyers')) {
      this.router.navigateByUrl('flyers/edit/' + id + '/version/' + version);
    }
  }

  /* To create a copy of a particular flyer */
  copy(id, version) {
    this.FlyerService.copyFlyer(id, version).subscribe(response => {
      if (response && this._router.url.includes('/flyers')){
        this.router.navigateByUrl('flyers/edit/' + response['id'] + '/version/' + response['version']);
        this.FlyerService.showToastMessage("Flyer Copied Successfully!", "check_circle", 'success');
      }
    })
  }

  /* Pagination */
  onPagination = $event => this.FlyerService.loadFlyersOnPagination(++$event.pageIndex, $event.pageSize, this.choosenCustomer, this.choosenStatus);

  setLength(length) {
    this.length = length
    this.FlyerService.totalElements = this.length;
  }

  setPageNo(pageNumber) {
    this.pageNo = pageNumber
    this.FlyerService.pageIndex = this.pageNo;
  }

  setPageSize(pageSize) {
    this.pageSize = pageSize
    this.FlyerService.pageSize = this.pageSize;
  }

  
}