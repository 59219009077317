<div class="container-fluid">

  <div class="row custom-close-row">
    <button class="custom-close-btn" mat-icon-button [mat-dialog-close]="true">
      <span class="material-icons custom-close-span">close</span>
    </button>
  </div>

  <div class="row custom-row">
    <h1><strong>Select Attributes</strong></h1>
    <br>
  </div>

  <form [formGroup]="attributeForm">

    <div>
      <mat-form-field appearance="outline" class="custom-row">
        <mat-label>Attributes</mat-label>
        <mat-select disableRipple #attributeSelect [formControl]="selectedAttribute" (selectionChange)="selectAttribute($event)" 
          [disabled]="disableDropdown">
          <mat-option *ngFor="let attribute of attributeList" [value]="attribute.type">
            {{attribute.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field> 
    </div>

    <table class="table table-bordered" formArrayName="attributes">
      <tr *ngFor="let quantity of quantities().controls; let i=index" [formGroupName]="i"
        class="custom-row">

        <td>
          <div class="row">
            <div [ngClass]="{'col-3': number === true,
                    'col-4': slider === true || choices === true, 
                    'col-6': color === true || text === true || sectionHeading === true || image === true || 
                            photoPicker === true || file === true || pdfAppend === true || pdfPrepend === true
                  }"
              >
              <mat-label>Name</mat-label>
              <input matinput type="text" #name class="form-control" [formControl]="attrName" required>
              <mat-error *ngIf="name.invalid">
                <strong>You must enter a Name.</strong>
              </mat-error>
            </div>

            <div *ngIf = "showDefaultValue" 
              [ngClass]="{'col-2': slider === true,
                'col-3': number === true,
                'col-4': choices === true,
                'col-6': text === true || sectionHeading === true || color == true
              }"
              >
              <mat-label>Default 
                <span *ngIf = "!showChoicesValue">value</span>
                <span *ngIf = "showChoicesValue">choice</span>
              </mat-label>
              <input *ngIf="!showColor" matinput type="text" class="form-control" [formControl]="attrDefault" [maxLength]="2048" required>
              <input *ngIf="showColor" matinput type="text" class="form-control" placeholder="Enter 6 digit Hexcode preceded by #" [formControl]="attrDefault" [maxLength]="7" required>
            </div>
            
            <div *ngIf = "showMinValue" 
              [ngClass]="{'col-2': slider === true,
                'col-3': number === true
              }"
              >
              <mat-label>Minimum value</mat-label>
              <input matinput type="number" class="form-control" [formControl]="attrMin"> 
            </div>
            
            <div *ngIf = "showMaxValue" [ngClass]="{'col-2': slider === true,
                                          'col-3': number === true
                                        }"
              >
              <mat-label>Maximum value</mat-label>
              <input matinput type="number" class="form-control" [formControl]="attrMax">
            </div>
            
            <div class="col-2" *ngIf = "showStepValue">
              <mat-label>Step size</mat-label>
              <input matinput type="number" class="form-control" [formControl]="attrStep">
            </div>
            
            <div class="col-4" *ngIf = "showChoicesValue">
              <mat-label>Enter Choices</mat-label>
              <input matinput placeholder="Enter Comma separated values." type="text" class="form-control" [formControl]="attrChoices">
            </div>
              
          </div>
  
          <div class="row custom-description">
            <mat-label class="custom-label">Description</mat-label>
            <textarea type="textarea" class="form-control custom-textarea" #description [formControl]="attrDescription" 
              [maxLength]="500" required>
            </textarea>
            <mat-hint class="custom-margin">Number of characters used: {{description.value?.length || 0}}/500</mat-hint>
          </div>

        </td>
        
        <td class="custom-td" *ngIf="showDefaultValue">
          <button (click)="addAttribute($event)" class="btn btn-danger custom-add" 
            [disabled]="attrName.hasError('required') || attrDescription.hasError('required') || attrDefault.hasError('required')">
            Add 
          </button>
        </td>
        <td class="custom-td" *ngIf="!showDefaultValue">
          <button (click)="addAttribute($event)" class="btn btn-danger custom-add" 
            [disabled]="attrName.hasError('required') || attrDescription.hasError('required')">
            Add
          </button>
        </td>
  
      </tr>
    </table>
     ‎
    <button mat-dialog-close type="submit" [disabled]="showSubmit" class="btn btn-success custom-submit" 
      (click)="onSubmit()">
      Submit
    </button>

  </form>

  <div class="row custom-row" *ngIf="showList" class="custom-card-div">
    <mat-card class="custom-card custom-row">
      <mat-card-content>
        <ul>
          <li *ngFor="let attr of attrs; let i=index;" class="custom-li">
            {{i + 1}}. {{ attr.name }}
            <br>
          </li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>

</div>