<div class="dialog">
    
    <h1 mat-dialog-title>
        <strong>Edit Attribute</strong>
    </h1>

    <table class="table table-bordered">
        <tr class="custom-row">

          <td>
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input type="text" matInput [(ngModel)]="this.attrinfo.name" required [formControl]="newAttributeName">
            </mat-form-field>    
          </td>

          <td *ngIf = "showDefaultValue">
            <mat-form-field appearance="outline">
                <mat-label>Default
                  <span *ngIf = "!showChoicesValue">value</span>
                  <span *ngIf = "showChoicesValue">choice</span>
                </mat-label>
                <input type="text" matInput [(ngModel)]="this.attrinfo.defaultValue" required [formControl]="newDefaultValue" [maxLength]="2048">
            </mat-form-field>
          </td>
          
          <td *ngIf = "showMinValue">
            <mat-form-field appearance="outline">
              <mat-label>Minimum value</mat-label>
              <input type="number" matInput [(ngModel)]="this.attrinfo.minimumValue" [formControl]="newMinValue">
            </mat-form-field>
          </td>
          
          <td *ngIf = "showMaxValue">
            <mat-form-field appearance="outline">
              <mat-label>Maximum value</mat-label>
              <input type="number" matInput [(ngModel)]="this.attrinfo.maximumValue" [formControl]="newMaxValue">
            </mat-form-field>    
          </td>
          
          <td *ngIf = "showIntervalValue">
            <mat-form-field appearance="outline">
              <mat-label>Step size</mat-label>
              <input type="number" matInput [(ngModel)]="this.attrinfo.interval" [formControl]="newIntervalValue">
            </mat-form-field>
          </td>

          <td *ngIf = "showChoicesValue">
            <mat-form-field appearance="outline">
              <mat-label>Choice</mat-label>
              <input type="text" matInput [(ngModel)]="this.attrinfo.choices" [formControl]="newChoiceValue">
            </mat-form-field>
          </td>

          <td *ngIf = "showColorValue">
            <mat-form-field appearance="outline">
                <mat-label>Color</mat-label>
                <input type="text" matInput #color [(ngModel)]="this.attrinfo.defaultValue" required [formControl]="newColorValue" [maxLength]="7">
                <mat-hint>6 digit Hexcode preceded by #</mat-hint>
                <mat-error *ngIf="color.invalid">
                  <strong>You must enter a valid 6 digit Hexcode</strong>
                </mat-error>
            </mat-form-field>
          </td>
          
        </tr>
    </table>

    <div class="row" style="display: block;">
      <mat-form-field appearance="outline" class="custom-margin">
        <mat-label class="custom-label" >Description</mat-label>
        <textarea type="text" matInput class="custom-row" class="custom-margin" placeholder="Max 500 characters" [maxLength]="500"
          [(ngModel)]="this.attrinfo.description" [formControl]="newDescriptionValue">
        </textarea>  
      </mat-form-field> 
    </div>

    <div mat-dialog-actions class="custom-actions">
        <button mat-raised-button mat-dialog-close>Close</button>
        <button mat-raised-button mat-dialog-close class="custom-save" (click)="editAttribute()" 
          [disabled]="newAttributeName.hasError('required') || newDefaultValue.hasError('required') || newColorValue.hasError('required')">
          Save
        </button>
    </div>
</div>