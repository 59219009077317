import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { FlyerService } from 'src/app/services/flyer.service';
import { WebpagesService } from 'src/app/services/webpages.service';
import { MicrositesService } from 'src/app/services/microsites.service';

@Component({
  selector: 'app-preview-confirmation-popup',
  templateUrl: './preview-confirmation-popup.component.html',
  styleUrls: ['./preview-confirmation-popup.component.css'],
})
export class PreviewConfirmationPopupComponent implements OnInit {
  webpageType: any;

  loadPDPPreview: Subscription;
  loadSearchPreview: Subscription;
  showCheckBox: boolean;
  templateType: any;

  flyerSavedForPreview: any = new Subject<any>();

  editWebpage: boolean = false;
  editFlyer: boolean = false;
  editMicrosite: boolean = false;

  constructor(
    public WebpagesService: WebpagesService,
    public flyerService: FlyerService,
    public micrositeService: MicrositesService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    if (this._router.url.includes('/webpages/edit')) {
      this.editWebpage = true;
    }

    if (this._router.url.includes('/flyers/edit')) {
      this.editFlyer = true;
    }

    if (this._router.url.includes('/microsites/edit')) {
      this.editMicrosite = true;
    }

    if (this.editWebpage) {
      this.templateType = this.WebpagesService.templateDetail.type;
    }
  }

  /* Show preview */
  showPreview() {
    if (this.editWebpage) {
      if (this.templateType === 'PDP') {
        this.WebpagesService.saveWebpage('get-preview-for-pdp-page');
      } else if (this.templateType === 'SEARCH') {
        this.WebpagesService.saveWebpage('get-preview-for-search-page');
      }
    }
    if (this.editFlyer) {
      this.flyerService.saveFlyer('get-preview-for-flyer');
    }

    if (this.editMicrosite) {
      this.micrositeService.saveMicrositePage('get-preview-for-microsite');
    }
  }
}
