import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { FlyerService } from '../../../services/flyer.service';

import { flyerData } from 'src/app/models/flyerData';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';


@Component({
  selector: 'app-flyer-add-popup',
  templateUrl: './flyer-add-popup.component.html',
  styleUrls: ['./flyer-add-popup.component.css']
})


export class FlyerAddPopupComponent implements OnInit {

  /* Form field validations */
  name = new FormControl('', [Validators.required]);
  description = new FormControl('', [Validators.required]);
  selectCustomer = new FormControl('', [Validators.required]);
  selectSystem = new FormControl('PC', [Validators.required]);
  selectProperty = new FormControl('', [Validators.required]);

  /* Add template form */
  @ViewChild('addTemplateForm', { static: false })
  addTemplateForm = NgForm;
  
  /* Routing */
  router: Router;

  /* Datasource for table data */
  dataSource = new MatTableDataSource();
  data: Subscription;

  /* Flyer data sent while sending POST request when a new flyer is created */
  flyerData: flyerData = {
    name: "",
    customerId: 0,
    type: "FLYER",
    description: "",
    attachToNewProperties:false,
    system: "PC",
    slug: ""
  }

  /* Params - filteration  */
  choosenCustomer: any = "";
  choosenStatus: any = "";

  /* To Fetch customers */
  public customers: any;

  /* To Fetch properties of the customers */
  public properties: any;

  /* To check properties selected by customers */
  public selectedPropertiesList = [];

  /* Create Property object array for linking properties to the created flyer */
  propertyObjectArray = [];

  /* To check the length of the selected properties inorder to craete Property object array for linking properties to the created flyer */
  length: number;

  /* To select all properties */
  selectAll: any[];

  /* select all event */
  allSelected: boolean = false;
  
  /* select all properties event boolean */
  allPropertiesEvent: boolean = false;

  /* Select drop down */
  @ViewChild('select') select: MatSelect;


  constructor(
    public dialog: MatDialog,
    private _router: Router, private _route: ActivatedRoute, http: HttpClient,
    public FlyerService:FlyerService
  ) {
    this.router = _router;
  }


  ngOnInit(): void {
  
    /* For fetching customers */
    this.FlyerService.getCustomers().subscribe(res => { 
      this.customers = res;
      this.customers = this.customers.sort((a, b) => a.name.localeCompare(b.name));
    });

    /* Filter considerations */
    this._route.queryParams.subscribe(params => {
      if (params['customer']) this.choosenCustomer = params['customer'];
      if (params['status']) this.choosenStatus = params['status'];
    });

  }

  fetchCustomers(event) {
    this.changeRequiredForVts();
    this.FlyerService.getCustomers(event.value).subscribe(res => { 
      this.customers = res;
      this.customers = this.customers.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  changeRequiredForVts() {
    if (this.selectSystem.value == 'PC') {
      this.selectProperty.setValidators(Validators.required);
      this.selectProperty.updateValueAndValidity();
    } else {
      this.selectProperty.clearValidators();
      this.selectProperty.updateValueAndValidity();
    }
  }

  /* To fetch properties based on selected customer */
  fetchProperties(event){
    let customerGlobalId = event.value.id;
    this.FlyerService.getProperties(customerGlobalId).subscribe((data) => {
      this.properties = data;
    })
  }

  /* On selection of all properties */
  selectedAllProperties(event){
    if (event){
      this.allPropertiesEvent = true;
      this.flyerData.attachToNewProperties = true;
    }
  }

  /* Toggle selection of all properties */
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
   optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  /* List of selected properties */
  selectedProperties(select: MatSelect, event){
    this.selectedPropertiesList = event.value;
    this.length = event.value.length;
    this.allPropertiesEvent == false;
  }

  /* On submit of Add Flyer Pop up form */
  onSubmit() {
    this.flyerData.customerId = this.selectCustomer.value.id;
    this.flyerData.system = this.selectSystem.value;
    this.addFlyer(this.flyerData);
  }

  /* To create a new Flyer */
  addFlyer(flyerData) {
    this.FlyerService.createFlyer(flyerData).subscribe(response => {
      this.FlyerService.templateID = response['id'];
      this.FlyerService.flyerVersion = response['version'];
      this.FlyerService.flyerId = response['flyerId'];
        
      if (this.allPropertiesEvent == false){
        for (var property of this.selectedPropertiesList){
          let propObject =  {
            "propertyGlobalId": property['propertyGlobalId'],
            "status": "ENABLED",
            "flyerId": this.FlyerService.flyerId
          };
          this.propertyObjectArray.push(propObject);
        }
        this.linkProperties(this.FlyerService.flyerId, this.propertyObjectArray);
      }

      if (this.allPropertiesEvent == true){
        this.raiseSnackbar();
      };

    }, error => {
      this.FlyerService.showToastMessage("Non unique Slug!", "error", 'danger');
    })
  }

  /* Link properties to the Flyer */
  linkProperties(flyerId, propertyObject){
    if (!propertyObject || !propertyObject.length) {
      this.raiseSnackbar();
    } else {
      this.FlyerService.linkProperties(flyerId, propertyObject).subscribe((data) => {
        this.raiseSnackbar();
      })
    }
  }

  /* Raise success snack bar after creating flyer */
  raiseSnackbar(){
    if (this._router.url.includes('/flyers')) {
      this.router.navigate(['/flyers/edit/' + this.FlyerService.templateID + '/version/' + this.FlyerService.flyerVersion]);
      this.FlyerService.showToastMessage("Flyer Added Successfully!", "check_circle", 'success');
    }
  }


}