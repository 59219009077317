import { Component, OnInit, ElementRef, PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { WebpagesService } from 'src/app/services/webpages.service';
import { Subscription, Subject } from 'rxjs/Rx';
import { Router, ActivatedRoute } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { PreviewConfirmationPopupComponent } from '../../popups/preview-confirmation-popup/preview-confirmation-popup.component';
import { OpenInNewTabPopupComponent } from '../../popups/open-in-new-tab-popup/open-in-new-tab-popup.component';
import { FlyerService } from 'src/app/services/flyer.service';
import { MicrositesService } from 'src/app/services/microsites.service';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css'],
})
export class PdfViewerComponent implements OnInit {
  router: Router;
  path: string;
  routeQueryParams$: Subscription;

  /* To fetch properties belonging to a particular customer */
  public properties: Subscription;
  propertyList: any;

  /* To fetch details required for PDP static endpoint */
  propertyGlobalId: any;
  templateId: any;
  version: any;

  /* For static preview */
  loadPDPPreview: Subscription;
  loadSearchPreview: Subscription;
  loadMicrositePagePreview: Subscription;

  // Flyer preview
  loadFlyerPreview: Subscription;

  webpageType: any;
  customerGlobalId: any;
  isPDP: boolean;

  /* To assign HTML reponse of preview to iframe */
  htmlData: any = '';
  htmlString: any = '';

  show: boolean = false;
  link: string;

  /* To select customers, search and property detail pages for dynamic preview URL */
  public customers: any;
  public searchPages: any;
  public propertyDetailPages: any;
  selectedCustomer: any;
  selectedSearchPage: any;
  selectedPDP: any;

  pdpTemplateId: any;
  pdpTemplateVersionId: any;
  searchTemplateId: any;
  searchTemplateVersionId: any;

  linkedProperties: Subscription;

  /* To get the total number of templates - required for sending as pagesize as the default number of templates returned is 30 */
  propertiesList: any;
  linkedpropertyList: any;
  linkedPropertyList: any = [];

  templateSystem: string;

  editWebpage: boolean = false;
  editFlyer: boolean = false;
  editMicrosite: boolean = false;

  constructor(
    public http: HttpClient,
    // private sanitizer: DomSanitizer,
    public WebpagesService: WebpagesService,
    public flyerService: FlyerService,
    public micrositeService: MicrositesService,
    public dialog: MatDialog,
    private _router: Router,
    private route: ActivatedRoute
  ) {
    this.router = _router;
    this.routeQueryParams$ = route.queryParams.subscribe((params) => {
      if (params['preview']) {
        this.openDialog();
      }
    });
    this.WebpagesService.getCustomers().subscribe((res) => {
      this.customers = res;
    });
  }

  ngOnInit(): void {
    if (this.router.url.includes('/webpages/edit')) {
      this.editWebpage = true;
    }

    if (this.router.url.includes('/flyers/edit')) {
      this.editFlyer = true;
    }

    if (this.router.url.includes('/microsites/edit')) {
      this.editMicrosite = true;
    }
    // To fetch properties
    this.properties = this.WebpagesService.properties$.subscribe((data) => {
      this.assignProperties(data);
    });

    // check webpage type
    this.webpageType = this.WebpagesService.webpageType$.subscribe((type) => {
      this.setWebpageType(type);
    });
    // check template system
    this.WebpagesService.templateSystem$.subscribe((system) => {
      this.setTemplateSystem(system);
    });

    // set customer global ID
    this.customerGlobalId = this.WebpagesService.customerGlobalId$.subscribe((id) => {
      this.setCustomerGlobalId(id);
    });

    // for PDP preview
    this.loadPDPPreview = this.WebpagesService.webpageSavedForPDPPreview.subscribe((success) => {
      if (success) {
        if (this.webpageType === 'PDP') {
          this.WebpagesService.getStaticPreviewForPDP(
            this.WebpagesService.propertyGlobalID,
            this.WebpagesService.templateId,
            this.WebpagesService.templateVersion,
            this.WebpagesService.templateSystem,
            this.WebpagesService.customerGlobalId
          ).subscribe((res) => {
            // console.log("check PDP call response: ", res);
            this.setPreviewContents(res);
          });
        }
      }
    });

    // for Search preview
    this.loadSearchPreview = this.WebpagesService.webpageSavedForSearchPreview.subscribe(
      (success) => {
        if (success) {
          if (this.webpageType === 'SEARCH') {
            this.WebpagesService.getStaticPreviewForSearch(
              this.WebpagesService.templateId,
              this.WebpagesService.templateVersion,
              this.customerGlobalId
            ).subscribe((res) => {
              // console.log("check search call response: ", res);
              this.setPreviewContents(res);
            });
          }
        }
      }
    );

    // for Microsite Page Preview
    this.loadMicrositePagePreview = this.micrositeService.micrositeSavedForPreview.subscribe(
      (success) => {
        if (success) {
          this.micrositeService.getStaticPreviewForSearch().subscribe((res) => {
            this.setPreviewContents(res);
          });
        }
      }
    );

    // for fetching linked properties
    this.linkedProperties = this.flyerService.statusEnabledLinkedProperties.subscribe((data) => {
      this.assignProperties(data);
    });

    // For Flyer Preview
    this.loadFlyerPreview = this.flyerService.flyerSavedForPreview.subscribe((success) => {
      if (success) {
        this.flyerService.isFlyerBeingPreviewed = true;
        // console.log("check flyerId", this.flyerService.flyerId);
        this.flyerService
          .previewHTML(this.flyerService.propertyGlobalId, this.flyerService.flyerDetail.flyerId)
          .subscribe((res) => {
            this.setPreviewContents(res);
          });
      }
    });
  }

  /* Set the preview contents */
  setPreviewContents(html) {
    // console.log("check html response: ", html);
    let iframeDiv = document.getElementById('htmldata');
    if (iframeDiv) {
      // this.htmlString = html;
      this.htmlData = html;
      //this.sanitizer.bypassSecurityTrustHtml(this.htmlString);
      iframeDiv.style.display = 'block';
      var iframeElement: any = document.getElementById('xFrame');
      iframeElement.src = 'about:blank';
      iframeElement.contentWindow.document.open();
      iframeElement.contentWindow.document.write(html);
      iframeElement.contentWindow.document.close();
    }
  }

  /* Assign properties to the property dropdown */
  assignProperties(data) {
    this.propertyList = data;
  }

  /* Set webpage type for selecting the correct endpoint and to show/hide the property dropdown */
  setWebpageType(type) {
    this.webpageType = type;
    if (this.webpageType === 'PDP') {
      this.isPDP = true;
    } else {
      this.isPDP = false;
    }
  }

  setTemplateSystem(system) {
    this.templateSystem = system;
  }

  /* Set customer Global Id */
  setCustomerGlobalId(id) {
    this.customerGlobalId = id;
    this.WebpagesService.customerGlobalId = id;
  }

  /* Show preview */
  showPreview() {
    if (this.webpageType === 'PDP') {
      if (
        this.WebpagesService.propertyGlobalID &&
        this.WebpagesService.templateId &&
        this.WebpagesService.templateVersion
      ) {
        this.WebpagesService.saveWebpage('get-preview-for-pdp-page');
      }
    } else {
      this.WebpagesService.saveWebpage('get-preview-for-search-page');
    }
  }

  /* Set property Global Id */
  setPropertyGlobalId(event) {
    if (this.editWebpage) {
      let id;
      if (this.templateSystem == 'VTS') {
        id = event.slug;
      } else {
        id = event.propertyGlobalId;
      }
      if (id) {
        this.propertyGlobalId = id;
        this.WebpagesService.propertyGlobalID = id;
      }
    } else {
      this.propertyGlobalId = event.propertyGlobalId;
      this.flyerService.propertyGlobalId = event.propertyGlobalId;
    }
  }

  /* On preview button click */
  preview() {
    if (this.editWebpage) {
      if (this.WebpagesService.isModified()) {
        this.dialog.open(PreviewConfirmationPopupComponent);
      } else {
        if (this.webpageType == 'PDP') {
          this.WebpagesService.webpageSavedForPDPPreview.next(true);
        } else {
          this.WebpagesService.webpageSavedForSearchPreview.next(true);
        }
      }
    }
    if (this.editFlyer) {
      if (this.flyerService.isModified()) {
        this.dialog.open(PreviewConfirmationPopupComponent);
      } else {
        this.flyerService.flyerSavedForPreview.next(true);
      }
    }
    if (this.editMicrosite) {
      if (this.micrositeService.isModified()) {
        this.dialog.open(PreviewConfirmationPopupComponent);
      } else {
        this.micrositeService.micrositeSavedForPreview.next(true);
      }
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(OpenInNewTabPopupComponent, {
      panelClass: 'custom-modalbox',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate([], {
        queryParams: { preview: null },
        queryParamsHandling: 'merge',
      });
    });
  }

  openPreviewDialog() {
    if (
      this.editMicrosite &&
      typeof this.micrositeService.getCurrentMicrositePagePreviewURL() === 'string'
    ) {
      window.open(this.micrositeService.getCurrentMicrositePagePreviewURL(), '_blank');
    } else {
      this.router.navigate([], {
        queryParams: { preview: true },
        queryParamsHandling: 'merge',
      });
    }
  }

  selectPages() {
    let customer = this.selectedCustomer.name;

    // fetch the property detail pages for selected customer
    this.getPropertyDetailPages(customer);

    // fetch the search pages for selected customer
    this.getSearchPages(customer);
  }

  getPropertyDetailPages(customer) {
    this.WebpagesService.getTemplatesByType('PDP', customer, '100000000').subscribe((data) => {
      this.propertyDetailPages = data['contents'];
    });
  }

  getSearchPages(customer) {
    this.WebpagesService.getTemplatesByType('SEARCH', customer, '100000000').subscribe((res) => {
      this.searchPages = res['contents'];
    });
  }

  generateLink() {
    this.show = true;

    this.customerGlobalId = this.selectedCustomer.customerGlobalId;

    this.pdpTemplateId = this.selectedPDP.id;
    this.pdpTemplateVersionId = this.selectedPDP.version;

    this.searchTemplateId = this.selectedSearchPage.id;
    this.searchTemplateVersionId = this.selectedSearchPage.version;

    this.createDyanamicLink(
      this.customerGlobalId,
      this.searchTemplateId,
      this.searchTemplateVersionId,
      this.pdpTemplateId,
      this.pdpTemplateVersionId
    );
  }

  createDyanamicLink(
    customerGlobalId,
    searchTemplateId,
    searchTemplateVersionId,
    pdpTemplateId,
    pdpTemplateVersionId
  ) {
    this.WebpagesService.createDynamicPreviewLink(
      customerGlobalId,
      searchTemplateId,
      searchTemplateVersionId,
      pdpTemplateId,
      pdpTemplateVersionId
    ).subscribe((data: any) => {
      if (data && data.previewUrl) {
        this.assignLink(data.previewUrl);
      }
    });
  }

  /* Assign link */
  assignLink = (link) => (this.link = link);

  ngOnDestroy() {
    this.routeQueryParams$ && this.routeQueryParams$.unsubscribe();
    this.properties && this.properties.unsubscribe();
    this.loadPDPPreview && this.loadPDPPreview.unsubscribe();
    this.loadSearchPreview && this.loadSearchPreview.unsubscribe();
    this.loadMicrositePagePreview && this.loadMicrositePagePreview.unsubscribe();
    this.loadFlyerPreview && this.loadFlyerPreview.unsubscribe();
    this.linkedProperties && this.linkedProperties.unsubscribe();
  }
}
