import { Component, OnInit } from '@angular/core';

import { FlyerService } from 'src/app/services/flyer.service';


@Component({
  selector: 'app-attribute-delete-popup',
  templateUrl: './attribute-delete-popup.component.html',
  styleUrls: ['./attribute-delete-popup.component.css']
})


export class AttributeDeletePopupComponent implements OnInit {

  constructor( public flyerService: FlyerService) { }

  ngOnInit(): void { }

  deleteAttribute(){
    this.flyerService.deleteFlyerAttribute(this.flyerService.flyerId, this.flyerService.attributeId);
  }

  
}
