import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Clipboard } from "@angular/cdk/clipboard";
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';

import { FlyerService } from '../../../services/flyer.service';

import { FileDeletePopupComponent } from '../../popups/file-delete-popup copy/file-delete-popup.component';
import { FileRenamePopupComponent } from '../../popups/file-rename-popup copy/file-rename-popup.component';
import { FileAddPopupComponent } from '../../popups/file-add-popup/file-add-popup.component';
import { ShowImageFilePopupComponent } from '../../popups/show-image-file-popup/show-image-file-popup.component';
import { AddAttributePopupComponent } from '../../popups/add-attribute-popup/add-attribute-popup.component';
import { AttributeEditPopupComponent } from '../../popups/attribute-edit-popup/attribute-edit-popup.component';
import { AttributeDeletePopupComponent } from '../../popups/attribute-delete-popup/attribute-delete-popup.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-flyer-attributes',
  templateUrl: './flyer-attributes.component.html',
  styleUrls: ['./flyer-attributes.component.css']
})


export class FlyerAttributesComponent implements OnInit {

  /* To access the element fileUpload, tabs and select */ 
  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  @ViewChild('tabs') tabGroup: MatTabGroup;
  @ViewChild('select') select: MatSelect;

  /* To store details of flyer */
  flyerDetail: any;
  flyerDetailChanged: Subscription;
  basicDetail: any;
  flyerFiles: any;
  assetFiles: any;

  // To fetch the attributes selected
  attributesAdded:Subscription;
 
  /* Data source to hold Attributes selected and columnsToDisplay for displaying the correct columns from the attribute data received  */
  dataSource = new MatTableDataSource();
  columnsToDisplay: string[];

  /* An array to hold the uploaded files*/
  files = [];

  /* To navigate to the proper section */
  router: Router;

  /* To edit fields in the basic section */
  name = new FormControl('', [Validators.required]);
  selectProperty = new FormControl('', [Validators.required]);
 
  /* To open selected file in editor */
  selectedFile: any;

  /* Read image */
  imageSource;

  /* Data load promise */
  dataLoaded: Promise<boolean>;
  
  /* Selecting the correct tab */
  selectedTab: any

  /* Flyer saved */
  flyerSaved: Subscription;
  
  /* File changed */
  fileChanged: any;

  /* List of properties linked with the Flyer */
  linkedProperties: Subscription;
  statusEnabledProperties: Subscription;
  
  /* To select all properties */
  selectAll: any[];

  /* To check properties selected by customers */
  public selectedPropertiesList:any = [];

  /* To check the length of the selected properties inorder to create Property object array for linking properties to the created flyer */
  length: number;
 
  /* To Fetch properties of the customers */
  public properties: any;

  /* To store flyer attributes */
  flyerAttributes: any;

  /* Toggle for all property selection */
  allSelected: boolean = false;

  /* Event for all properties */
  allPropertiesEvent: boolean = false;

  /* checkedProperties array incase of selective properties linking */
  checkedProperties:any = [];
  oldCheckedProperties:any = []; //to compare with newly selected property/properties

  /* To display attributes section */
  showAttribute: boolean = false;
  oldProperties: any;
  eventchecked: any;

  propertyObjectArray = [];
  result43: any;
  canReorder: boolean = false;

  postFlyerSaveProcessing: boolean = false;

  constructor(
    private _router: Router, private renderer: Renderer2, private _route: ActivatedRoute,
    private clipboard: Clipboard, public dialog: MatDialog, 
    public FlyerService: FlyerService
  ) {
    this.router = _router;
    this._route.queryParams.subscribe(params => {
      if (params['section']) {
        if (params['section'] == 'basic') this.selectedTab = 0
        else if (params['section'] == 'details') this.selectedTab = 1
        else if (params['section'] == 'attributes') this.selectedTab = 2
      }
      else {
        this.selectedTab = 0
      }
    }); 
  }

  ngOnInit(): void {

    /* Flyer details */
    this.flyerDetailChanged = this.FlyerService.flyerDetailChanged.subscribe(
      (flyerDetail) => {
      this.flyerDetail = flyerDetail;
      const { flyerAttributes, files, ...basicDetails } = flyerDetail;
      this.flyerAttributes = flyerAttributes;
      this.flyerFiles = files.filter(file => file.mediaType == "HTML" || file.mediaType == "CSS" || file.mediaType == "JS" || file.mediaType == "TEXT_PLAIN");      
      this.assetFiles = files.filter(file => file.mediaType !== "HTML" && file.mediaType !== "CSS" && file.mediaType !== "JS" && file.mediaType !== "TEXT_PLAIN");
      this.basicDetail = basicDetails;
      this.FlyerService.basicFormValid = true;

      this._router.navigate([], {
        queryParams: { section: 'details' },
      })
      
      const flyerFtlh = files.filter(file => file.name == 'flyer.ftlh');
      if (flyerFtlh.length == 1 && !this.selectedFile) {
        this.selectedFile = flyerFtlh[0].name;
        this.FlyerService.openFileInEditor('flyer.ftlh');
      }
      
      this.dataLoaded = Promise.resolve(true);
    }
  )

    this.flyerSaved = this.FlyerService.flyerSaved.subscribe(isSaved => {
      if (isSaved) this.files = [];
      this.canReorder = false;
      this.postFlyerSaveProcessing = true;
    })

    /* File in editor */
    this.fileChanged = this.FlyerService.flyerFtlhSelected.subscribe((flyerFtlhSelected)=>{
      if(flyerFtlhSelected) this.selectedFile = 'flyer.ftlh';
    })

    /* Columns to display in attribute section */
    this.columnsToDisplay = ['name', 'value', 'actions'];

    /* Data source for attribute section */
    this.attributesAdded = this.FlyerService.flyerAttributesChanged.subscribe((attributes) => {
      if (attributes.length > 0) this.showAttribute = true;
      this.flyerAttributes = attributes;
    });

    /* Checked properties in edit section */
    // this.linkedProperties = this.FlyerService.linkedProperties.subscribe(
      // (linkedProperties) => {
        // this.properties = linkedProperties;
        // console.log("properties", this.properties);
      // }
    // )

    this.statusEnabledProperties = this.FlyerService.statusEnabledLinkedProperties.subscribe(
      (enabledProperties) => {
        // this.checkedProperties = enabledProperties;
        // this.oldCheckedProperties = enabledProperties;

        if (enabledProperties.length == this.FlyerService.linkedpropertiesList.length) {
          this.FlyerService.setnewSelectedPropertiesList(this.FlyerService.linkedpropertiesList.map(item => {
            return {
              'id': item['id'],
              'propertyGlobalId': item['propertyGlobalId'],
              'status': "ENABLED",
              'flyerId': this.flyerDetail.flyerId
            }
          }));
          this.allSelected = true;
        }

        this.postFlyerSaveProcessing = false;
      }
    )

  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.flyerAttributes, event.previousIndex, event.currentIndex);
    
    let order = 1;
    this.flyerAttributes = this.flyerAttributes.map(attribute => {
      attribute.ordering = order;
      order++;
      return attribute;
    });

    this.FlyerService.flyerAttributes = [...this.flyerAttributes];
    this.FlyerService.linkedPropertiesModified = true;
    this.FlyerService.notSave = false;

    console.log(this.FlyerService.flyerAttributes);
  }

  /* To open correct dialog for attribute edit and delete */
  openDialog(action, flyerId, element, id){
    this.FlyerService.flyerId = flyerId;
    this.FlyerService.attributeId = id;
    this.FlyerService.attributeInfo = element;

    if (action == 'edit') {
      const dialogRef = this.dialog.open(AttributeEditPopupComponent);
    } else if (action == 'delete') {
      const dialogRef = this.dialog.open(AttributeDeletePopupComponent);
    }
  }

  /* To copy S3 URL for downloading any file */
  copyS3URL(s3Url) {
    this.clipboard.copy(s3Url);
    this.FlyerService.showToastMessage("URL Copied Successfully!", "check_circle", 'success');
  }

  /* To select the right tab - basic, details or attributes */
  selectTab(tab) {
    if (tab.index == 0) {
      this._router.navigate([], {
        queryParams: { section: 'basic' },
      })
    }
    else if (tab.index == 1) {
      this._router.navigate([], {
        queryParams: { section: 'details' },
      }) 
    } else {
      this._router.navigate([], {
        queryParams: { section: 'attributes' },
      })
    }
    this.selectedTab = tab.index;
  }

  /* To open File delete pop up */
  openDeletePopup(file) {
    this.dialog.open(FileDeletePopupComponent, {
      data: { fileId: file.id }
    });
  }

  /* To open File rename pop up */
  openRenamePopup(fileName) {
    this.dialog.open(FileRenamePopupComponent, {
      data: { fileName: fileName }
    });
  }

  /* To open add file pop up */
  openAddFilePopup() {
    this.dialog.open(FileAddPopupComponent);
  }

  /* To restore a file */
  restoreFile(fileName) {
    this.FlyerService.restoreFile(fileName);
  }

  /* To Toggle the expansion panel */
  expandPanel(matExpansionPanel, event): void {
    event.stopPropagation();
    if (!this._isExpansionIndicator(event.target)) {
      matExpansionPanel.toggle();
    }
  }
  private _isExpansionIndicator(target: EventTarget): boolean {
    const expansionIndicatorClass = 'mat-expansion-indicator';
    return (target['classList'] && target['classList'].contains(expansionIndicatorClass));
  }

  /* Executed on file upload */
  onUpload() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {

      for (let index = 0; index < fileUpload.files.length; index++) {
        let file = fileUpload.files[index];

        // Do not allow files greater than 10 mb
        if (file.size > 9999999) {
          alert(" Size of this file is greater than 10mb \n ->" + file.name)
          if (index < fileUpload.files.length) {
            continue
          }
          else return false
        }

        // Do not allow files without extensions
        if (file.name.split('.').length == 1) {
          alert("Files without extensions cannot be uploaded \n ->" + file.name)
          if (index < fileUpload.files.length) {
            continue
          }
          else return false
        }

        // Do not allow invalid file types
        if (!this.FlyerService.isFileTypeValid(file.name)) {
          alert(file.name + " This type of file is not allowed")
          if (index < fileUpload.files.length) {
            continue
          }
          else return false
        }

        if (this.FlyerService.hasWhiteSpace(file.name)){
          // console.log("filename:", this.FlyerService.hasWhiteSpace(file.name));
          alert(file.name + " This filename contains spaces")
          if (index < fileUpload.files.length) {
            continue
          }
          else return false
        }

        // Do not allow files with duplicate file name
        if (this.FlyerService.checkDuplicateFilename(file.name)) {
          alert(file.name + " This filename already exists")
          if (index < fileUpload.files.length) {
            continue
          }
          else return false
        }

        const fileName = file.name.split('.')
        // console.log('New file info: ', file);

        if (fileName[fileName.length - 1] == "ttf") file = new File([file], file.name, { type: "font/ttf" });
        else if (fileName[fileName.length - 1] == "woff" || fileName[fileName.length - 1] == "woff2") file = new File([file], file.name, { type: "font/woff" });
        else if (fileName[fileName.length - 1] == "otf") file = new File([file], file.name, { type: "font/otf" });
        else if (fileName[fileName.length - 1] == "ico") file = new File([file], file.name, { type: "image/x-icon" });
        else if (fileName[fileName.length - 1] == "gif") file = new File([file], file.name, { type: "image/gif" });
        else if (fileName[fileName.length - 1] == "jpg") file = new File([file], file.name, { type: "image/jpeg" });
        else if (fileName[fileName.length - 1] == "png") file = new File([file], file.name, { type: "image/png" });
        else if (fileName[fileName.length - 1] == "svg") file = new File([file], file.name, { type: "image/svg+xml" });
        else if (fileName[fileName.length - 1] == "bmp") file = new File([file], file.name, { type: "image/bmp" });
        else if (fileName[fileName.length - 1] == "css") file = new File([file], file.name, { type: "text/css" });
        else if (fileName[fileName.length - 1] == "js") file = new File([file], file.name, { type: "text/javascript" });
        else if (fileName[fileName.length - 1] == "jpeg") file = new File([file], file.name, { type: "image/jpeg" });
        else if (fileName[fileName.length - 1] == "eot") file = new File([file], file.name, { type: "application/vnd.ms-fontobject" });
        else if (fileName[fileName.length - 1] == "json") file = new File([file], file.name, { type: "application/json" });
        else if (fileName[fileName.length - 1] == "pdf") file = new File([file], file.name, { type: "application/pdf" });
        else if (fileName[fileName.length - 1] == "ftl" || fileName[fileName.length - 1] == "ftlh") file = new File([file], file.name, { type: "text/plain" });

        // console.log('New file here:', file);
        if (fileUpload.files && fileUpload.files[0]) {
          const file = fileUpload.files[0];
          const reader = new FileReader();
          reader.onload = e => this.imageSource = reader.result;
          reader.readAsDataURL(file);
        }
        this.FlyerService.filesModified = true
        this.files.push({ data: file, inProgress: true, progress: 100 });
        // console.log("added new asset file");
        this.FlyerService.addNewAssetFile(file)
      }
    };
    fileUpload.click();
  }

  /* For file edit */
  onEdit(file) {
    // console.log(file.mediaType);
    if (file.mediaType == "PNG" || file.mediaType == "JPEG" || file.mediaType == "GIF" || file.mediaType == "ICON" || file.mediaType == "SVG" || file.mediaType == "BMP") {
      this.dialog.open(ShowImageFilePopupComponent, {
        data: {
          src: file.downloadUrl,
          fileName: file.name
        }
      });
      return false
    }
    else if (file.mediaType == "TTF" || file.mediaType == "WOFF" || file.mediaType == "OTF" || file.mediaType == "TIFF" || file.mediaType == "EOT" || file.mediaType == "PDF") {
      return false
    }
    else {
      this.selectedFile = file.name
      this.FlyerService.openFileInEditor(file.name)
    }
  }
  isActive(item) {
    return this.selectedFile == item;
  };

  /* To check if the details entered in basic section are valid or not */ 
  setIsValid() {
    if (this.flyerDetail.name.length > 0 && this.flyerDetail.type.length > 0 &&
      this.flyerDetail.slug.length > 0 && this.flyerDetail.description.length > 0) this.FlyerService.basicFormValid = true
    else this.FlyerService.basicFormValid = false;
  }

  /* To replace asset URLs */
  onReplaceURLs() {
    if (window.confirm('Are you sure you want to replace all the asset links?')) {
      this.FlyerService.showToastMessage("URLs are being replaced. Please wait", "build", 'info');
      this.FlyerService.replaceAssetUrls();
    }
  }

  /* To Open add attribute popup */
  openAttributePopUp() {
    this.dialog.open(AddAttributePopupComponent);
  };

  /* Toggle All properties selection */
  toggleAllSelection(event: any) {
    this.allSelected = event;

    if (this.allSelected) {
      this.allPropertiesEvent = true;
      // this.FlyerService.setnewSelectedPropertiesList([]);
      this.FlyerService.setnewSelectedPropertiesList(this.FlyerService.linkedpropertiesList.filter(item => item.status === 'DISABLED').map(item => {
        return {
          'id': item['id'],
          'propertyGlobalId': item['propertyGlobalId'],
          'status': "ENABLED",
          'flyerId': this.flyerDetail.flyerId
        }
      }));
      this.FlyerService.setremovePropertyLinkList([]);
      this.FlyerService.linkedpropertiesList = this.FlyerService.linkedpropertiesList.map(item => {
        item.isSelected = true;
        return item;
      })

      this.FlyerService.notSave = false;
    } else {
      this.allPropertiesEvent = false;
      this.FlyerService.setnewSelectedPropertiesList([]);
      // this.FlyerService.setremovePropertyLinkList([]);
      this.FlyerService.setremovePropertyLinkList(this.FlyerService.linkedpropertiesList.filter(item => item.status === 'ENABLED').map(item => {
        return {
          'id': item['id'],
          'propertyGlobalId': item['propertyGlobalId'],
          'status': "DISABLED"
        }
      }));
      this.FlyerService.linkedpropertiesList = this.FlyerService.linkedpropertiesList.map(item => {
        item.isSelected = false;
        return item;
      })
      this.FlyerService.notSave = true;
    }
    this.linkUnlinkProperties();
  }

  /* List of selected properties in property dropdown */
  selectedProperties(event){
    // console.log(event);
    this.FlyerService.linkedPropertiesModified = true;

    if (typeof event.htmlId != 'undefined') {
      // Remove a selected property
      // if (this.FlyerService.statusEnabledProperties.filter(x => x.propertyGlobalId === event.value.propertyGlobalId).length !== 0) {
        this.FlyerService.setremovePropertyLinkList([...this.FlyerService.removePropertyLinkList, {
          'propertyGlobalId': event.value['propertyGlobalId'],        
          'status': "DISABLED",
          'id': event.value['id']
        }]);

        this.FlyerService.linkedpropertiesList = this.FlyerService.linkedpropertiesList.map(item => {
          if (item.propertyGlobalId === event.value['propertyGlobalId']) {
            item.isSelected = false;
          }
          return item;
        });
        
      // }
      this.FlyerService.setnewSelectedPropertiesList(this.FlyerService.newSelectedPropertiesList.filter(x => x.propertyGlobalId !== event.value.propertyGlobalId));
    } else {
      // Add a selected property
      // if (this.FlyerService.statusEnabledProperties.filter(x => x.propertyGlobalId === event.propertyGlobalId).length === 0) {  
        
        this.FlyerService.setnewSelectedPropertiesList([...this.FlyerService.newSelectedPropertiesList, {
          'id': event['id'],
          'propertyGlobalId': event['propertyGlobalId'],
          'status': "ENABLED",
          'flyerId': this.flyerDetail.flyerId
        }]);

        this.FlyerService.linkedpropertiesList = this.FlyerService.linkedpropertiesList.map(item => {
          if (item.propertyGlobalId === event['propertyGlobalId']) {
            item.isSelected = true;
          }
          return item;
        });
      // }
      this.FlyerService.setremovePropertyLinkList(this.FlyerService.removePropertyLinkList.filter(x => x.propertyGlobalId !== event.propertyGlobalId));

    }

    // const calculatedPropertiesLength = ((this.FlyerService.statusEnabledProperties.length + this.FlyerService.newSelectedPropertiesList.length) - this.FlyerService.removePropertyLinkList.length);
    // this.allSelected = (calculatedPropertiesLength === this.FlyerService.linkedpropertiesList.length);
    this.allSelected = this.FlyerService.linkedpropertiesList.filter(item => item.isSelected).length === this.FlyerService.linkedpropertiesList.length;

    if (this.allSelected){
      this.allPropertiesEvent = true;
    } else {
      this.allPropertiesEvent = false;
    }

    // if (calculatedPropertiesLength > 0) {
      this.FlyerService.notSave = false;
    // } else {
      // this.FlyerService.notSave = true;
    // }
    

    this.linkUnlinkProperties();
  }

  /* To check select All options */
  optionClick() {
    // console.log("two");

    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;

    if (this.allSelected){
      this.allPropertiesEvent = true;
    } else {
      this.allPropertiesEvent = false;
    }
    this.linkUnlinkProperties();
  }

  linkUnlinkProperties(){
    if (this.allPropertiesEvent == true){
      this.FlyerService.attachToAllProps = true;
      this.FlyerService.flyerDetail.attachToNewProperties = true;
      // this.FlyerService.setnewSelectedPropertiesList(this.FlyerService.linkedpropertiesList.filter(x => x.status == 'DISABLED').map(x => {
      //   return {
      //     'id': x['id'],
      //     'propertyGlobalId': x['propertyGlobalId'],
      //     'status': "ENABLED",
      //     'flyerId': this.flyerDetail.flyerId
      //   }
      // }));
      // this.FlyerService.setnewSelectedPropertiesList([]);
      this.FlyerService.setremovePropertyLinkList([]);
    } else {
      this.FlyerService.attachToAllProps = false;
      this.FlyerService.flyerDetail.attachToNewProperties = false;
    }

    console.log('linkedpropertiesList', this.FlyerService.linkedpropertiesList);
    console.log('statusEnabledProperties', this.FlyerService.statusEnabledProperties);
    console.log('newSelectedPropertiesList', this.FlyerService.newSelectedPropertiesList);
    console.log('removePropertyLinkList', this.FlyerService.removePropertyLinkList);
    

  }

  addProperties(properties){
    for (var property of properties){
      // console.log("p", property);
      let propObject =  {
        'id': property['id'],
        'propertyGlobalId': property['propertyGlobalId'],
        'status': "ENABLED",
        'flyerId': this.flyerDetail.flyerId
      };
      this.FlyerService.newSelectedPropertiesList.push(propObject);
      // console.log("added prop/props", this.FlyerService.newSelectedPropertiesList);
    }  
  }

  removeProperties(properties){
    for (var property of properties){
      // console.log("p", property);
      let propObject =  {
        'propertyGlobalId': property['propertyGlobalId'],        
        'status': "DISABLED",
        'id': property['id']
      };
      this.FlyerService.removePropertyLinkList.push(propObject);
      // console.log("removed prop/props", this.FlyerService.removePropertyLinkList);
    }
  }
 
}