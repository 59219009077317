<mat-tab-group
  mat-align-tabs="start"
  *ngIf="dataLoaded | async"
  #tabs
  [selectedIndex]="selectedTab"
  (selectedTabChange)="selectTab($event)"
>
  <mat-tab label="BASIC">
    <!-- Start of Basic Tab -->
    <div class="container-fluid">
      <form novalidate #editMicrositePageForm="ngForm" (ngSubmit)="onSubmit(editMicrositePageForm)">
        <!-- Page Name -->
        <div class="row">
          <mat-form-field
            class="full-width"
            hintLabel="Max 60 characters"
            appearance="outline"
            [floatLabel]="'always'"
          >
            <mat-label>Name</mat-label>
            <input
              matInput
              #pageName
              placeholder="Page name"
              name="pageName"
              type="text"
              [maxLength]="60"
              ngModel
              #pageName="ngModel"
              (ngModelChange)="setIsValid($event, null, 'name')"
              required
            />
            <mat-error *ngIf="!pageName?.valid && (pageName?.dirty || pageName?.touched)">
              <strong>You must enter the page name!</strong>
            </mat-error>
            <mat-hint align="end">{{ pageName.value?.length || 0 }}/60</mat-hint>
          </mat-form-field>
        </div>

        <!-- Page Slug -->
        <div class="row">
          <mat-form-field class="full-width" appearance="outline" [floatLabel]="'always'">
            <mat-label class="page-slug-label" floatLabel="never">Slug</mat-label>
            <span class="building-hostname">{{ this.buildingHostname }}</span>
            <input
              matInput
              #micrositePageSlugInput
              class="page-slug"
              placeholder="pageslug"
              type="text"
              name="pageSlug"
              [maxLength]="60"
              ngModel
              #pageSlug="ngModel"
              pattern="^[A-Za-z\-]+$"
              (ngModelChange)="setIsValid($event, null, 'pageSlug')"
              required
            />
            <mat-hint align="start" *ngIf="!showDuplicateSlugErrorMessage">
              Max 60 characters
            </mat-hint>
            <mat-error *ngIf="!pageSlug?.valid && (pageSlug?.dirty || pageSlug?.touched)">
              <strong *ngIf="pageSlug.errors.required">You must enter a name!</strong>
              <strong *ngIf="pageSlug.errors.pattern"
                >Only `-` and alphabet characters are allowed !</strong
              >
            </mat-error>
            <mat-hint *ngIf="!showDuplicateSlugErrorMessage" align="end"
              >{{ micrositePageSlugInput.value?.length || 0 }}/60</mat-hint
            >
          </mat-form-field>
          <div class="custom-error-message" *ngIf="showDuplicateSlugErrorMessage">
            <strong>{{ duplicateSlugErrorMessage }}</strong>
          </div>
        </div>

        <!-- Page Description  -->
        <div class="row">
          <mat-form-field
            class="full-width custom-desc"
            hintLabel="Max 120 characters"
            appearance="outline"
          >
            <mat-label>Description</mat-label>
            <textarea
              matInput
              placeholder="Add page description"
              type="textarea"
              name="pageDescription"
              #pageDescription="ngModel"
              ngModel
              [maxLength]="120"
              (ngModelChange)="setIsValid($event, null, 'description')"
              required
            ></textarea>
            <mat-error
              *ngIf="
                !pageDescription?.valid && (pageDescription?.dirty || pageDescription?.touched)
              "
            >
              <strong *ngIf="pageDescription.errors.required">You must enter a Description!</strong>
            </mat-error>
            <mat-hint align="end">{{ pageDescription.value?.length || 0 }}/120</mat-hint>
          </mat-form-field>
        </div>

        <!-- Save Button -->
        <div class="save-button-wrapper">
          <button mat-raised-button class="save-btn" [disabled]="!micrositesService.isModified()">
            Save
          </button>
        </div>
      </form>
    </div>
    <!-- End of Basic Tab -->
  </mat-tab>

  <mat-tab label="DETAILS">
    <div class="custom-details">
      <button mat-flat-button (click)="onReplaceURLs()" class="replaceURLs">
        <mat-icon>find_replace</mat-icon>
        Replace Asset Links
      </button>
    </div>

    <!-- start of Details tab -->
    <button
      mat-icon-button
      (click)="onUpload()"
      class="upload-button"
      matTooltip="Upload an asset file"
      aria-label="Upload an asset file"
    >
      <span class="material-icons">cloud_upload</span>
      Upload Files
    </button>
    <button
      mat-icon-button
      (click)="openAddFilePopup()"
      class="upload-button"
      matTooltip="Upload a template file"
      aria-label="Upload a template file"
    >
      <span class="material-icons">note_add</span>
      Create File
    </button>
    <app-download-assets [source]="templateDetail"></app-download-assets>

    <mat-expansion-panel [expanded]="true" #expansionPanelTemplate>
      <mat-expansion-panel-header
        class="panel-header"
        (click)="expandPanel(expansionPanelTemplate, $event)"
      >
        <mat-panel-title> Template Files </mat-panel-title>
      </mat-expansion-panel-header>

      <input
        type="file"
        #fileUpload
        id="fileUpload"
        name="fileUpload"
        multiple="multiple"
        class="custom-file-upload"
      />

      <mat-list>
        <mat-list-item *ngFor="let file of files">
          <span id="file-label"></span>
          <h4 class="asset-file" matLine>{{ file.data.name }}</h4>
          <mat-progress-bar [value]="file.progress"></mat-progress-bar>
        </mat-list-item>
      </mat-list>

      <mat-list>
        <mat-list-item
          *ngFor="let file of templateFiles"
          (click)="onEdit(file)"
          [ngClass]="{ black: isActive(file.name) }"
        >
          <span id="file-label"></span>
          <h4 matLine>
            <div class="file-logo" [ngSwitch]="file.mediaType">
              <img *ngSwitchCase="'JS'" src="assets/icons/icons8-javascript-25.png" />
              <img *ngSwitchCase="'HTML'" src="assets/icons/icons8-html-5-25.png" />
              <img *ngSwitchCase="'CSS'" src="assets/icons/icons8-css3-25.png" />
              <img *ngSwitchDefault src="assets/icons/icons8-file-20.png" />
            </div>
            {{ file.name }}
          </h4>
          <span class="deleted" *ngIf="file.deleted === true">Deleted</span>
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="openRenamePopup(file.name)"
              *ngIf="file.name !== 'main.ftlh'"
            >
              <mat-icon>autorenew</mat-icon>
              <span>Rename</span>
            </button>
            <button
              mat-menu-item
              (click)="copyAssetDownloadURL(file.downloadUrl)"
              *ngIf="file.s3Url"
            >
              <mat-icon>file_copy</mat-icon>
              <span>Copy URL</span>
            </button>
            <button
              mat-menu-item
              (click)="openDeletePopup(file)"
              *ngIf="file.name !== 'main.ftlh'"
              [disabled]="true"
            >
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item *ngIf="file.deleted === true" (click)="restoreFile(file.name)">
              <mat-icon>undo</mat-icon>
              <span>Restore</span>
            </button>
          </mat-menu>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true" #expansionPanelAssets>
      <mat-expansion-panel-header
        class="panel-header"
        (click)="expandPanel(expansionPanelAssets, $event)"
      >
        <mat-panel-title>
          <span> Asset Files </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-list>
        <mat-list-item
          *ngFor="let file of assetFiles"
          (click)="onEdit(file)"
          [ngClass]="{ black: isActive(file.name) }"
        >
          <h4 matLine>
            <span class="file-logo" [ngSwitch]="file.mediaType">
              <img *ngSwitchCase="'JS'" src="assets/icons/icons8-javascript-25.png" />
              <img *ngSwitchCase="'HTML'" src="assets/icons/icons8-html-5-25.png" />
              <img *ngSwitchCase="'CSS'" src="assets/icons/icons8-css3-25.png" />
              <img *ngSwitchCase="'JPEG'" src="assets/icons/jpg-icon.png" />
              <img *ngSwitchCase="'PNG'" src="assets/icons/png-icon.png" />
              <img *ngSwitchCase="'SVG'" src="assets/icons/svg-icon.png" />
              <img *ngSwitchCase="'WEBP'" src="assets/icons/webp-icon.png" />
              <img *ngSwitchDefault src="assets/icons/icons8-file-20.png" />
            </span>
            {{ file.name }}
          </h4>
          <span class="deleted" *ngIf="file.deleted === true">Deleted</span>
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openRenamePopup(file.name)">
              <mat-icon>autorenew</mat-icon>
              <span>Rename</span>
            </button>
            <button
              mat-menu-item
              (click)="copyAssetDownloadURL(file.downloadUrl)"
              *ngIf="file.s3Url"
            >
              <mat-icon>file_copy</mat-icon>
              <span>Copy URL</span>
            </button>
            <button
              mat-menu-item
              (click)="openDeletePopup(file)"
              *ngIf="file.s3Url"
              [disabled]="true"
            >
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item *ngIf="file.deleted === true" (click)="restoreFile(file.name)">
              <mat-icon>undo</mat-icon>
              <span>Restore</span>
            </button>
          </mat-menu>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>

    <!-- end of details tab -->
  </mat-tab>
</mat-tab-group>
