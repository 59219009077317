<ng-select [items]="photosBuffer" 
    [virtualScroll]="true"
    bindLabel="propertyName" 
    bindValue="propertyGlobalId"
    (scroll)="onScroll($event)"
    (scrollToEnd)="onScrollToEnd()"
    [loading]="loading"
    placeholder="Select a Property" 
    (change)="propertySelected.emit($event)"
    [typeahead]="input$"
    [clearable]="false"
    *ngIf="!multiple">
</ng-select>

<ng-select [items]="photosBuffer" 
    [virtualScroll]="true" 
    [multiple]="multiple"
    bindLabel="propertyName" 
    bindValue="propertyGlobalId"
    (scroll)="onScroll($event)"
    (scrollToEnd)="onScrollToEnd()"
    [loading]="loading"
    placeholder="Linked properties" 
    (add)="propertySelected.emit($event)"
    (remove)="propertySelected.emit($event)"
    [typeahead]="input$"
    [closeOnSelect]="false"
    [clearable]="false"
    [(ngModel)]="selectedItems"
    appendTo="body"
    (open)="isBeingUsed = true"
    (close)="isBeingUsed = false"
    *ngIf="multiple">

     <ng-template ng-header-tmp>

        <div>
            <label for="select-all8384"><input id="select-all8384" type="checkbox" [ngModel]="selectAll" (change)="onSelectAll($event)"/> Select All</label>
        </div>
    
      </ng-template>
 
      <ng-template ng-multi-label-tmp>
        {{ isBeingUsed ? '' : getLinkedDisplayText() }}
      </ng-template>
    
    <!-- <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> Select All
    </ng-template> -->

    <ng-template ng-option-tmp let-item="item"  let-item$="item$" let-index="index" >
        <input id="checkbox-item-{{index + 1}}" type="checkbox" [ngModel]="item$.selected" /><span *ngIf="!item.propertyGlobalId" style="color: red;">(!)</span> {{item.propertyName}}
    </ng-template>
</ng-select>
<span *ngIf="multiple && isBeingUsed">{{getLinkedDisplayText()}}</span>