// Angular imports
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// Material imports
import { MatDialog } from '@angular/material/dialog';

// Service imports
import { WebpagesService } from 'src/app/services/webpages.service';
import { FlyerService } from 'src/app/services/flyer.service';
import { MicrositesService } from 'src/app/services/microsites.service';

@Component({
  selector: 'app-file-add-popup',
  templateUrl: './file-add-popup.component.html',
  styleUrls: ['./file-add-popup.component.css'],
})
export class FileAddPopupComponent implements OnInit {
  /* Store the file name*/
  newFileName = new FormControl('', [Validators.required]);

  /* Check for duplicate file names */
  duplicateFileName: any = false;

  /* Check for valid file types*/
  validFileType: boolean;

  /* Check for files without extension */
  fileWithoutExt: boolean = false;

  constructor(
    public webPageService: WebpagesService,
    public flyerService: FlyerService,
    public micrositesService: MicrositesService,
    public dialog: MatDialog,
    private _router: Router
  ) {}

  ngOnInit(): void {}

  /* To Create a new file */
  createFile() {
    this.fileWithoutExt = false;

    // console.log('Check the length of the file name entered: ', this.newFileName.value.split('.').length);
    if (!this.newFileName.value) {
      this.duplicateFileName = null;
    } else if (this.newFileName.value.split('.').length == 1) {
      this.fileWithoutExt = true;
      this.newFileName.setErrors({
        invalid: true,
      });
    } else {
      if (this._router.url.includes('/webpages/edit')) {
        this.duplicateFileName = this.webPageService.checkDuplicateFilename(this.newFileName.value);
        this.validFileType = this.webPageService.isFileTypeValidToCreate(this.newFileName.value);
        if (!this.duplicateFileName && this.validFileType) {
          this.webPageService.createFile(this.newFileName.value);
          this.dialog.closeAll();
        } else {
          this.newFileName.setValue('');
        }
      } else if (this._router.url.includes('/flyers/edit')) {
        this.duplicateFileName = this.flyerService.checkDuplicateFilename(this.newFileName.value);
        this.validFileType = this.flyerService.isFileTypeValidToCreate(this.newFileName.value);
        if (!this.duplicateFileName && this.validFileType) {
          this.flyerService.createFile(this.newFileName.value);
          this.dialog.closeAll();
        } else {
          this.newFileName.setValue('');
        }
      } else if (this._router.url.includes('/microsites/edit')) {
        this.duplicateFileName = this.micrositesService.checkDuplicateFilename(
          this.newFileName.value
        );
        this.validFileType = this.micrositesService.isFileTypeValidToCreate(this.newFileName.value);
        if (!this.duplicateFileName && this.validFileType) {
          this.micrositesService.createFile(this.newFileName.value);
          this.dialog.closeAll();
        } else {
          this.newFileName.setValue('');
        }
      }
    }
  }
}
