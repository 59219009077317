import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MicrositePagesAddPopupComponent } from 'src/app/components/popups/microsite-pages-add-popup/microsite-pages-add-popup.component';
import { MicrositeAddPopupComponent } from 'src/app/components/popups/microsite-add-popup/microsite-add-popup.component';
import { MicrositeEditPopupComponent } from '../components/popups/microsite-edit-popup/microsite-edit-popup.component';
import { Observable, throwError, forkJoin, Subject } from 'rxjs';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { IconSnackBarComponent } from '../components/icons-snack-bar/icon-snack-bar.component';
import { AppConfigService } from './app-config.service';
import { Router } from '@angular/router';

import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { toFormDataNew } from './convertFormdata';
/* Material imports */
import { MatTableDataSource } from '@angular/material/table';
import { MICROSITES_STATUS_OPTIONS } from 'src/app/constants/MicrositesStatus';
import { MicrositeClonePopupComponent } from '../components/popups/microsite-clone-popup/microsite-clone-popup.component';

@Injectable({
  providedIn: 'root',
})
export class MicrositesService {
  showCreateMicrositesButton: boolean = false;
  showAddMicrositePageButton: boolean = false;

  /* For pagination */
  pageIndex: number = 1;
  pageSize: number = 30;
  totalElements: number;

  /* Positions for displaying snackbar */
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  /* These variables maintains a copy of the datasource which can be shared between multiple components */
  dataSource = new MatTableDataSource();
  sharedDataSource = new MatTableDataSource();
  micrositesListDataSourceChanged: any = new Subject<any>();
  sharedPageNo$: any = new Subject<any>();
  sharedPageSize$: any = new Subject<any>();
  sharedTotalElements$: any = new Subject<any>();
  micrositeDataSourceChanged: any = new Subject<any>();
  micrositePageDataSourceChanged: any = new Subject<any>();
  micrositeEditHeaderDataSource: any = new Subject<any>();

  /* The details of the templates */
  templateDetail: any;
  basicDetails: any;
  templateFiles: any;
  newAssetFiles: any = [];
  templateId: any;

  /* Microsite Backup Data */
  currentMicrositeData: any = [];
  currentMicrositePageData: any = [];

  /* Validation for Basic form */
  basicFormValid: boolean = false;

  /* File opened in editor */
  fileOpenedInEditor: any;

  /* Content of opened file in editor */
  contentOfOpenedFile: any = new Subject<any>();

  /* main.ftlh file selected */
  mainFtlhSelected: any = new Subject<any>();

  /* Flag for file modification check */
  filesModified: any = false;

  /* Webpage Saved?? */
  webpageSaved: any = new Subject<any>();

  /* Microsite Saved for preview */
  micrositeSavedForPreview: any = new Subject<any>();

  /* Microsite Page Duplicate Slug Error */
  micrositePageDuplicateSlugError: any = new Subject<any>();

  /* To assign data to the template */
  data: any;

  /* To replace asset URLs */
  URLsReplaced: boolean = true;

  /* Back up data if anything goes wrong while editing */
  backupData: any;

  micrositesStatus = MICROSITES_STATUS_OPTIONS;

  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private env: AppConfigService,
    public router: Router,
    public dialog: MatDialog
  ) {}

  /* Http Options */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  openAddMicrositePopup() {
    this.dialog.open(MicrositeAddPopupComponent);
  }

  openCloneMicrositePopup(buildingName: string, micrositeId: number) {
    this.dialog.open(MicrositeClonePopupComponent, {
      data: { buildingName: buildingName, micrositeId: micrositeId },
    });
  }

  openEditMicrositePopup() {
    this.dialog.open(MicrositeEditPopupComponent);
  }

  openAddMicrositePagesPopup() {
    this.dialog.open(MicrositePagesAddPopupComponent);
  }

  loadMicrositesOnPagination(
    pageNo: Number,
    pageSize: Number,
    customerSlug: string,
    buildingId: string,
    status: string
  ) {
    let finalCustomerSlug = customerSlug == ' ALL' || customerSlug == undefined ? '' : customerSlug;
    let finalBuildingId = buildingId == ' ALL' || buildingId == undefined ? '' : buildingId;
    let finalStatus = status != '' ? this.convertStatusStringToEnum(status) : '';
    this.getMicrositesWithPagination(
      pageNo,
      pageSize,
      finalCustomerSlug,
      finalBuildingId,
      finalStatus
    ).subscribe((response: any) => {
      if (response) {
        this.dataSource.data = this.fixMicrositesStatus(response.contents);
        this.pageIndex = response.pageNo;
        this.pageSize = response.pageSize;
        this.totalElements = response.totalElements;
        this.micrositesListDataSourceChanged.next(this.dataSource.data);
        this.sharedPageNo$.next(this.pageIndex);
        this.sharedPageSize$.next(this.pageSize);
        this.sharedTotalElements$.next(this.totalElements);
      }
      this.showCreateMicrositesButton =
        response && response.contents ? response.contents.length : false;
    });
  }

  fixMicrositesStatus(microsites: any) {
    microsites.forEach((microsite) => {
      microsite.status = this.convertStatusEnumToString(microsite.status);
    });
    return microsites;
  }

  convertStatusStringToEnum(status: String) {
    return this.micrositesStatus
      .filter(function (item) {
        return item.string == status;
      })
      .map(function (status) {
        return status.enum;
      })[0];
  }

  convertStatusEnumToString(status: String) {
    return this.micrositesStatus
      .filter(function (item) {
        return item.enum == status;
      })
      .map(function (status) {
        return status.string;
      })[0];
  }

  /* To retrive microsite with pagination parameters */
  getMicrositesWithPagination(
    pageNo,
    pgSize,
    customerSlug?: string,
    buildingId?: string,
    status?: string
  ) {
    let pageIndex = pageNo.toString();
    let pageSize = pgSize.toString();
    return this.http
      .get(this.env.config.APP.MICROSITES_API_URL + '/sites', {
        params: new HttpParams()
          .set('pageNo', pageIndex)
          .set('pageSize', pageSize)
          .set('buildingId', buildingId)
          .set('customer', customerSlug)
          .set('status', status),
      })
      .pipe(catchError(this.errorHandler));
  }

  /* To fetch mirosite details and store it in services for easy retrieval */
  loadSingleMicrosite(micrositeId) {
    if (micrositeId) {
      this.getSingleMicrosite(micrositeId).subscribe(
        (data: any) => {
          if (data) {
            this.assignCurrentMicrositeData(data);
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          this.showToastMessage('Oops! Microsite not found. Redirecting in 3s', 'error', 'danger');
          setTimeout(() => {
            this.router.navigate(['microsites']);
          }, 3000);
        }
      );
    }
  }

  assignCurrentMicrositeData(data) {
    data.status = this.convertStatusEnumToString(data.status);
    this.currentMicrositeData = data;
    this.micrositeDataSourceChanged.next(this.currentMicrositeData);
    this.micrositeEditHeaderDataSource.next(this.currentMicrositeData);
  }

  /* Retrieve a single microsite data by ID */
  getSingleMicrosite(id) {
    return this.http
      .get(this.env.config.APP.MICROSITES_API_URL + '/sites/' + id)
      .pipe(catchError(this.errorHandler));
  }

  loadMicrositesPageDetails(micrositeId, micositePageId) {
    if (micrositeId && micositePageId) {
      this.getMicrositesPageDetails(micrositeId, micositePageId).subscribe(
        (data: any) => {
          if (data) {
            this.assignMicrositePageData(data);
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          this.showToastMessage(
            'Oops! Microsite Page not found. Redirecting in 3s',
            'error',
            'danger'
          );
          setTimeout(() => {
            this.router.navigate(['microsites/edit/' + micrositeId]);
          }, 3000);
        }
      );
    }
  }

  getMicrositesPageDetails(micrositeId, micositePageId) {
    return this.http
      .get(
        this.env.config.APP.MICROSITES_API_URL +
          '/sites/' +
          micrositeId +
          '/pages/' +
          micositePageId
      )
      .pipe(catchError(this.errorHandler));
  }

  assignMicrositePageData(data) {
    this.currentMicrositePageData = data;
    var { files, ...basicDetails } = this.currentMicrositePageData;
    this.basicDetails = basicDetails;
    this.micrositeEditHeaderDataSource.next(basicDetails);
    this.micrositePageDataSourceChanged.next(this.currentMicrositePageData);
    this.backupData = data;
  }

  createMicrosite(micosite) {
    return this.http
      .post(
        this.env.config.APP.MICROSITES_API_URL + '/sites',
        JSON.stringify(micosite),
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  cloneMicrosite(micrositeData, micrositeId) {
    return this.http
      .post(
        this.env.config.APP.MICROSITES_API_URL + '/sites/' + micrositeId + '/copy',
        JSON.stringify(micrositeData),
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  createMicrositeCopy(micrositeId: number) {
    if (micrositeId) {
      this.copyMicrosite(micrositeId, this.currentMicrositeData).subscribe(
        (data: any) => {
          if (data) {
            this.assignCurrentMicrositeData(data);
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          this.showToastMessage(
            'Oops! Microsite Page not found. Redirecting in 3s',
            'error',
            'danger'
          );
          setTimeout(() => {
            this.router.navigate(['microsites/edit/' + micrositeId]);
          }, 3000);
        }
      );
    }
  }

  updateMicrosite(micrositeData: any) {
    return this.http
      .put(this.env.config.APP.MICROSITES_API_URL + '/sites/' + micrositeData.id, micrositeData)
      .pipe(catchError(this.errorHandler));
  }

  copyMicrosite(micrositeId, microsite) {
    return this.http
      .post(
        this.env.config.APP.MICROSITES_API_URL + '/sites/' + micrositeId + '/copy',
        JSON.stringify(microsite)
      )
      .pipe(catchError(this.errorHandler));
  }

  copyMicrositePage(template: any, copyName, copySlug, copyDescription) {
    return this.http
      .put(
        this.env.config.APP.MICROSITES_API_URL +
          '/sites/' +
          template.micrositeId +
          '/pages/' +
          template.id +
          '/copy',
        {
          name: copyName,
          page: copySlug,
          description: copyDescription,
        }
      )
      .pipe(catchError(this.errorHandler));
  }

  /* To retrieve a list of existing customers */
  getCustomers() {
    return this.http
      .get(this.env.config.APP.MICROSITES_API_URL + '/customers')
      .pipe(catchError(this.errorHandler));
  }

  /* To retrieve a list of existing buildings */
  getBuildings(customerSlug: String, createdMicrosites: Boolean) {
    return this.http
      .get(
        this.env.config.APP.MICROSITES_API_URL +
          '/buildings?customer=' +
          (customerSlug && customerSlug.length ? customerSlug : '') +
          (createdMicrosites ? '&createdMicrosites=' + createdMicrosites : '')
      )
      .pipe(catchError(this.errorHandler));
  }

  addMicrositePage(micrositeId, micrositePage) {
    return this.http
      .post(
        this.env.config.APP.MICROSITES_API_URL + '/sites/' + micrositeId + '/pages',
        JSON.stringify(micrositePage),
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  /* Handle API errors */
  errorHandler(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = error.error.message;
      console.error('Client side error occurred:', errorMessage);
    } else {
      // The backend returned error message in the response body.
      if (error.error && error.error.message) {
        errorMessage = error.error.message;
        return throwError(errorMessage);
      } else {
        // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong.
        errorMessage = `Backend returned code: ${error.status}, ` + `Error message: ${error.error}`;
        console.error('Backend error message: ', errorMessage);
      }
    }
    // return an observable with a user-facing error message
    errorMessage = 'Something bad happened; please try again later.';
    return throwError(errorMessage);
    // return throwError(errorMessage);
  }

  /* All Toast messages */
  showToastMessage(message, icon, classname, duration?: number) {
    duration = duration || 2000;
    this._snackBar.openFromComponent(IconSnackBarComponent, {
      data: {
        message: message,
        icon: icon,
      },
      duration: duration,
      panelClass: classname,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  /* To get the MIME type for sorting files */
  getMIMEType(extension) {
    const extToMimes = {
      html: 'text/html',
      ftlh: 'text/plain',
      ftl: 'text/plain',
      css: 'text/css',
      js: 'text/javascript',
      gif: 'image/gif',
      jpg: 'image/jpeg',
      webp: 'image/webp',
      png: 'image/png',
      svg: 'image/svg+xml',
      bmp: 'image/bmp',
      ico: 'image/x-icon',
      tif: 'image/tiff',
      ttf: 'font/ttf',
      woff: 'font/woff',
      otf: 'font/otf',
      json: 'application/json',
      eot: 'application/vnd.ms-fontobject',
      pdf: 'application/pdf',
    };
    if (extToMimes.hasOwnProperty(extension)) {
      return extToMimes[extension];
    }
    return false;
  }

  /* To check if the file type is valid or invalid */
  isFileTypeValid(filename) {
    const fileName = filename.split('.');
    const extension = fileName[fileName.length - 1];
    const validFiles = [
      'pdf',
      'json',
      'eot',
      'woff2',
      'gif',
      'jpg',
      'jpeg',
      'png',
      'svg',
      'bmp',
      'ico',
      'html',
      'ftlh',
      'ftl',
      'css',
      'js',
      'tif',
      'tiff',
      'ttf',
      'woff',
      'otf',
      'webp',
    ];
    if (validFiles.includes(extension)) return true;
    else return false;
  }

  /* To check if file type is valid or create */
  isFileTypeValidToCreate(filename) {
    const fileName = filename.split('.');
    const extension = fileName[fileName.length - 1];
    const validFiles = ['html', 'ftlh', 'ftl', 'css', 'js'];
    if (validFiles.includes(extension)) return true;
    else return false;
  }

  /* To retrieve the media type */
  getMediaType(extension) {
    const extToMediaType = {
      html: 'HTML',
      ftlh: 'TEXT_PLAIN',
      ftl: 'TEXT_PLAIN',
      css: 'CSS',
      js: 'JS',
    };
    if (extToMediaType.hasOwnProperty(extension)) {
      return extToMediaType[extension];
    }
    return false;
  }

  /* To create a file */
  createFile(filename) {
    this.filesModified = true;
    const fileName = filename.split('.');
    const mediaType = this.getMediaType(fileName[fileName.length - 1]);
    this.currentMicrositePageData.files.push({
      id: 0,
      name: filename,
      mediaType: mediaType,
      content: '',
      modified: true,
    });
    this.micrositePageDataSourceChanged.next(this.currentMicrositePageData);
  }

  /* To open any file in editor */
  openFileInEditor(filename) {
    this.fileOpenedInEditor = filename;
    const file = this.currentMicrositePageData.files.filter(
      (file) => file.name == this.fileOpenedInEditor
    );
    this.contentOfOpenedFile.next(file[0]);
  }

  /* To get asset files */
  getAssetFile(fileId, mediaType) {
    if (
      mediaType == 'CSS' ||
      mediaType == 'JS' ||
      mediaType == 'HTML' ||
      mediaType == 'TEXT_PLAIN' ||
      mediaType == 'JSON'
    ) {
      return this.http
        .get(this.env.config.APP.MICROSITES_API_URL + '/files/' + fileId + '/download', {
          responseType: 'text',
        })
        .pipe(catchError(this.errorHandler));
    }
  }

  /* To save previous file */
  savePreviousFile(prevFile) {
    this.filesModified = true;
    const fileIndex = this.currentMicrositePageData.files.findIndex(
      (file) => file.name == prevFile.name
    );
    this.currentMicrositePageData.files[fileIndex].content = prevFile.content;
    this.currentMicrositePageData.files[fileIndex].modified = true;
  }

  /* To rename a file on the edit page */
  renameFile(newFileName, oldFilename) {
    this.filesModified = true;
    const fileIndex = this.currentMicrositePageData.files.findIndex(
      (file) => file.name == oldFilename
    );
    if (!this.currentMicrositePageData.files[fileIndex].modified) {
      this.getAssetFile(
        this.currentMicrositePageData.files[fileIndex].id,
        this.currentMicrositePageData.files[fileIndex].mediaType
      ).subscribe((content) => {
        this.addContent(this.currentMicrositePageData.files[fileIndex], content);
        this.currentMicrositePageData.files[fileIndex].name = newFileName;
        this.currentMicrositePageData.files[fileIndex].modified = true;
      });
    } else {
      this.addContent(
        this.currentMicrositePageData.files[fileIndex],
        this.currentMicrositePageData.files[fileIndex].content
      );
      this.currentMicrositePageData.files[fileIndex].name = newFileName;
      this.currentMicrositePageData.files[fileIndex].modified = true;
    }
  }

  /* To add content to the file */
  addContent(file, content) {
    file.content = content;
  }

  /* To delete a file */
  //   deleteFile(fileId) {
  // 	return this.http.delete(this.env.config.APP.MICROSITES_API_URL + '/template/' + this.templateId + '/file/' + fileId,
  // 	  { responseType: 'text' }
  // 	).subscribe(() => {
  // 	  const fileDeleted = this.currentMicrositePageData.files.filter(file => file.id == fileId)
  // 	  const newFiles = this.currentMicrositePageData.files.filter(file => file.id !== fileId)
  // 	  this.currentMicrositePageData.files = newFiles
  // 	  this.assignData(this.currentMicrositePageData)
  // 	  if (fileDeleted[0].name == this.fileOpenedInEditor) {
  // 		this.mainFtlhSelected.next(true)
  // 		this.openFileInEditor('main.ftlh')
  // 	  }
  // 	  this.showToastMessage("File Deleted SuccessFully", "delete", 'danger')
  // 	})
  //   }

  /* To restore a file */
  restoreFile(fileName) {
    const fileIndex = this.currentMicrositePageData.files.findIndex(
      (file) => file.name == fileName
    );
    this.currentMicrositePageData.files[fileIndex].deleted = false;
  }

  /* To Add new asset file to the existing flyer */
  addNewAssetFile(file) {
    this.filesModified = true;
    this.newAssetFiles.push({ id: 0, multipartFile: file });
    this.micrositePageDataSourceChanged.next(this.currentMicrositePageData);
  }

  /* To replace asset URLs */
  replaceAssetUrls() {
    this.contentOfOpenedFile.next(null);
    var { files, ...basicDetails } = this.currentMicrositePageData;
    const missingFiles = [];
    let observablesArray = [];
    let assetFiles = [];
    this.URLsReplaced = false;
    this.currentMicrositePageData.files.forEach((file, index) => {
      if (
        file.mediaType == 'TEXT_PLAIN' ||
        file.mediaType == 'JS' ||
        file.mediaType == 'CSS' ||
        file.mediaType == 'HTML'
      ) {
        if (file.s3Url && !file.modified) {
          observablesArray.push(this.getAssetFile(file.id, file.mediaType));
          assetFiles.push(file);
        }
      }
    });
    forkJoin(...observablesArray).subscribe(
      (data) => {
        for (let i = 0; i < data.length; i++) {
          let assetFileData = data[i];
          var href = /href="(.*?)"/g;
          var src = /src="(.*?)"/g;
          var url = /url\((?!['"]?(?:data:|https?:|\/\/))(['"]?)([^'")]*)\1\)/g;
          var match;
          while (
            (match =
              href.exec(assetFileData.toString()) ||
              src.exec(assetFileData.toString()) ||
              url.exec(assetFileData.toString()))
          ) {
            const assetName = match[2] ? match[2].split('/').pop() : match[1].split('/').pop();
            const toReplace = match[2] ? match[2] : match[1];
            if (!assetName.includes('{')) {
              const fileToReplace = this.currentMicrositePageData.files.filter((file) => {
                if (assetName.includes(file.name)) return file;
              });
              if (fileToReplace.length == 0) {
                continue;
              }
              var outputString;
              if (
                assetFiles[i].mediaType == 'JS' ||
                assetFiles[i].mediaType == 'CSS' ||
                assetFiles[i].mediaType == 'HTML'
              ) {
                outputString = "${getFileUrl('" + fileToReplace[0].name + "')}";
              } else if (assetFiles[i].mediaType == 'TEXT_PLAIN') {
                outputString = "${getFileUrl('" + fileToReplace[0].name + "')}";
              }
              assetFileData = assetFileData.toString().replace(toReplace, outputString);
              assetFiles[i].modified = true;
              this.filesModified = true;
              this.addContent(assetFiles[i], assetFileData);
            }
          }
        }
        this.URLsReplaced = true;
        if (this.fileOpenedInEditor) this.openFileInEditor(this.fileOpenedInEditor);
        this.showToastMessage('URLs Replaced Successfully!', 'check_circle', 'success');
      },
      () => {
        this.URLsReplaced = true;
        this.showToastMessage('Some Error Occured', 'close', 'danger');
      }
    );
  }

  /* While publishing a microsite page */
  publishMicrositePage(micrositeId: String, pageId: String, template: any) {
    return this.http
      .put(
        this.env.config.APP.MICROSITES_API_URL +
          '/sites/' +
          micrositeId +
          '/pages/' +
          pageId +
          '/publish',
        template
      )
      .pipe(catchError(this.errorHandler));
  }

  unPublishMicrositePage(micrositeId: string, pageId: string, template: any) {
    return this.http
      .put(
        this.env.config.APP.MICROSITES_API_URL +
          '/sites/' +
          micrositeId +
          '/pages/' +
          pageId +
          '/unPublish',
        template
      )
      .pipe(catchError(this.errorHandler));
  }

  /* While saving a microsite page */
  saveMicrositePage(previewOrPublish) {
    this.filesModified = false;
    this.contentOfOpenedFile.next(null);
    this.backupData = JSON.parse(JSON.stringify(this.currentMicrositePageData));
    var { files, ...basicDetails } = this.currentMicrositePageData;
    const templateDetail = { ...basicDetails };
    const fileWithoutContent = [];
    this.currentMicrositePageData.files.forEach((file) => {
      if (file.modified && file.content == '') {
        fileWithoutContent.push(file.name);
      }
    });
    if (fileWithoutContent.length > 0) {
      alert(
        'Files cannot be empty : \n ' + fileWithoutContent.map((el, i) => ++i + '. ' + el + '\n')
      );
      return false;
    }
    const updatedFiles = this.currentMicrositePageData.files.filter(
      (file) => file.modified == true
    );
    updatedFiles.forEach((newFile) => {
      const fileName = newFile.name.split('.');
      var extension;
      // Get MIME type
      if (newFile.name.split('.').pop() == 'ftlh') {
        if (fileName[fileName.length - 2] == 'css') {
          extension = 'css';
        } else {
          extension = 'ftlh';
        }
      } else {
        extension = newFile.name.split('.').pop();
      }
      const MIMEType = this.getMIMEType(extension);
      var file = new File([newFile.content], newFile.name, { type: MIMEType });
      delete newFile.content;
      delete newFile.mediaType;
      delete newFile.modified;
      delete newFile.name;
      delete newFile.s3Url;
      delete newFile.fileSize;
      delete newFile.textFile;
      delete newFile.downloadUrl;
      newFile.multipartFile = file;
    });
    templateDetail.files = [];
    if (updatedFiles.length > 0) updatedFiles.map((file) => templateDetail.files.push(file));
    if (this.newAssetFiles.length > 0)
      this.newAssetFiles.map((file) => {
        if (!file.s3Url) {
          templateDetail.files.push(file);
        }
      });
    delete templateDetail.lastModifiedDate;

    this.updateMicrositePage(
      templateDetail.micrositeId,
      templateDetail.id,
      toFormDataNew(templateDetail)
    ).subscribe(
      (data) => {
        this.assignMicrositePageData(data);
        if (previewOrPublish !== null) {
          if (typeof previewOrPublish === 'object') {
            previewOrPublish;
          }
          if (
            typeof previewOrPublish === 'string' &&
            previewOrPublish === 'get-preview-for-microsite'
          ) {
            this.micrositeSavedForPreview.next(true);
          }
        } else {
          this.showToastMessage('Microsite Page Updated Successfully!', 'check_circle', 'success');
        }

        this.webpageSaved.next(true);
        this.newAssetFiles = [];
        this.filesModified = false;
        if (this.fileOpenedInEditor) this.openFileInEditor(this.fileOpenedInEditor);
      },
      (err) => {
        this.assignMicrositePageData(this.backupData);
        this.filesModified = true;
        this.micrositePageDuplicateSlugError.next(err);
      }
    );
  }

  deleteMicrosite(micrositeId: string) {
    return this.http
      .delete(this.env.config.APP.MICROSITES_API_URL + '/sites/' + micrositeId)
      .pipe(catchError(this.errorHandler));
  }

  deleteMicrositePage(micrositeId: string, micrositePageId: string) {
    return this.http
      .delete(this.env.config.APP.MICROSITES_API_URL + '/sites/' + micrositeId + '/pages/' + micrositePageId)
      .pipe(catchError(this.errorHandler));
  }

  /* Get static preview response for Microsite pages */
  getStaticPreviewForSearch() {
    return this.http.get(this.getCurrentMicrositePagePreviewURL(), {
      responseType: 'text',
    });
  }

  getCurrentMicrositePagePreviewURL() {
    if (
      this.currentMicrositePageData &&
      this.currentMicrositePageData.micrositeId &&
      this.currentMicrositePageData.page
    ) {
      return (
        this.env.config.APP.MICROSITES_WEB_URL +
        '/sites/' +
        this.currentMicrositePageData.micrositeId +
        '/' +
        this.currentMicrositePageData.page
      );
    }
  }

  /* Update a single microsite page by ID */
  updateMicrositePage(siteId, pageId, pageData) {
    return this.http
      .put(
        this.env.config.APP.MICROSITES_API_URL + '/sites/' + siteId + '/pages/' + pageId,
        pageData
      )
      .pipe(catchError(this.errorHandler));
  }

  /* To check if template details are modified or not */
  isModified() {
    if (!this.currentMicrositePageData) return false;
    if (this.filesModified) {
      return this.basicFormValid;
    } else {
      var { files, ...basicDetails } = this.currentMicrositePageData;
      if (JSON.stringify(basicDetails) === JSON.stringify(this.basicDetails)) {
        return false;
      } else {
        return this.basicFormValid;
      }
    }
  }

  /* Flag to check if files are modified or not */
  fileModified() {
    this.filesModified = true;
  }

  /* To check for duplicate file name */
  checkDuplicateFilename(fileName) {
    if (this.currentMicrositePageData.files.some((file) => file.name === fileName)) {
      return true;
    } else {
      return false;
    }
  }

  hasWhiteSpace(fileName) {
    if (fileName.indexOf(' ') >= 0) {
      return true;
    } else {
      return false;
    }
  }
}
