import { Component, Input } from '@angular/core';

import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver-es';

@Component({
  selector: 'app-download-assets',
  templateUrl: './download-assets.component.html',
  styleUrls: ['./download-assets.component.css']
})


export class DownloadAssetsComponent {
  @Input() source: any;
  downloadZipText: string = "Download Zip";

  /**
   * Fetch the content and return the associated promise.
   * @param {String} url the url of the content to fetch.
   * @return {Promise} the promise containing the data.
   */
  urlToPromise1(url): Promise<any> {
    return new Promise(function(resolve, reject) {
        JSZipUtils.getBinaryContent(url, function (err, data) {
            if(err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
  }


  downloadZip() {
    this.downloadZipText = "Initializing..";
    // console.log('Files', this.source.files);

    var zip = new JSZip();
    var outputFilename = this.source.name.split(' ').join('_') + '.zip';

    this.source.files.forEach(element => {
      zip.file(element.name, this.urlToPromise1(element.downloadUrl), {binary:true});
    });

    var _this = this;

    // when everything has been downloaded, we can trigger the dl
    zip.generateAsync({type:"blob"}, function updateCallback(metadata) {
        var msg = "progression : " + metadata.percent.toFixed(2) + " %";
        if(metadata.currentFile) {
            msg += ", current file = " + metadata.currentFile;
        }
        // console.log(msg);
        // console.log(metadata.percent|0);

        _this.downloadZipText = "Zipping " + (metadata.percent|0) + "%";
    })
    .then(function callback(blob) {

        saveAs(blob, outputFilename);

        console.log('Success downloading');
        _this.downloadZipText = "Download Zip";
    }, function (e) {
        console.log('Error downloading', e);
    });

    return false;
  }


}
