<div class="container-fluid">

  <div class="row">
    <div class="container-fluid">
      <app-sub-header></app-sub-header>
    </div>
  </div>

  <div class="row">
    <div class="container-fluid custom-container">
      <app-flyer-data-table *ngIf="router.url.includes('/flyers')"></app-flyer-data-table>
      <app-data-table *ngIf="router.url.includes('/webpages')"></app-data-table>
      <app-microsites-data-table *ngIf="router.url.includes('/microsites')"></app-microsites-data-table>
    </div>
  </div>

</div>
