<div class="dialog">

    <h1 mat-dialog-title>
        <strong>Add file</strong>
    </h1>

    <div mat-dialog-content>
    
        <mat-form-field appearance="outline">
            <mat-label>File name</mat-label>
            <input matInput [formControl]="newFileName">
    
            <mat-error *ngIf="duplicateFileName == true">
                <strong>Filename already exists!</strong>
            </mat-error>
    
            <mat-error *ngIf="duplicateFileName == null">
                <strong>Filename cannot be empty.</strong>
            </mat-error>
    
            <mat-error *ngIf="fileWithoutExt == true">
                <strong>Cannot create file without Extension.</strong>
            </mat-error>
    
            <mat-error *ngIf="validFileType == false">
                <strong>This type of file is not allowed.</strong>
            </mat-error>
    
        </mat-form-field>
    
    </div>
    
    <div mat-dialog-actions class="custom-actions">
        <button mat-flat-button mat-dialog-close>Close</button>
        <button mat-raised-button class="custom-create" (click)="createFile()">Create</button>
    </div>
    
</div>