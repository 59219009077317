<h2 mat-dialog-title>Flyer Status</h2>
<mat-dialog-content>

  <div class="flyer-counts visible">
    <div class="count success-count" [ngClass]="{'active': dataSource && dataSource.filter === 'successful'}" (click)="filter.value = '';applyFilter('successful')">
      <p>
        <strong>{{successCount}}</strong>
      </p>
      Successful
    </div>
    <div class="count processing-count" [ngClass]="{'active': dataSource && dataSource.filter === 'processing'}" (click)="filter.value = '';applyFilter('processing')">
      <p>
        <strong>{{processingCount}}</strong>
      </p>
      Processing
    </div>
    <div class="count pending-count" [ngClass]="{'active': dataSource && dataSource.filter === 'pending'}" (click)="filter.value = '';applyFilter('pending')">
      <p>
        <strong>{{pendingCount}}</strong>
      </p>
      Pending
    </div>
    <div class="count" [ngClass]="{'error-count': errorCount > 0, 'active': dataSource && dataSource.filter === 'error'}" (click)="filter.value = '';applyFilter('error')">
      <p>
        <strong>{{errorCount}}</strong>
      </p>
      Error
    </div>
    <div class="count" [ngClass]="{'aborted-count': abortedCount > 0, 'active': dataSource && dataSource.filter === 'aborted'}" (click)="filter.value = '';applyFilter('aborted')">
      <p>
        <strong>{{abortedCount}}</strong>
      </p>
      Aborted
    </div>
    <div class="count total-count" [ngClass]="{'active': dataSource && dataSource.filter === ''}" (click)="applyFilter('')">
      <p>
        <strong>{{properties.length}}</strong>
      </p>
      Total Properties
    </div>
  </div>

  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter(filter.value)" placeholder="Ex. processing" #filter>
  </mat-form-field>
  <div class="flyer-status-table">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Request Id </th>
        <td mat-cell class="custom-cell" *matCellDef="let element">
          {{element.id}}
        </td>
      </ng-container>

      <ng-container matColumnDef="propertyName">
        <th mat-header-cell *matHeaderCellDef> Property Name </th>
        <td mat-cell class="custom-description-cell" *matCellDef="let element">
          {{element.propertyName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th class="header" mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell class="custom-cell" *matCellDef="let element">
          {{element.status}}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell class="custom-actions-cell" *matCellDef="let element">
          <div *ngIf="element.status == 'ERROR' || element.status == 'ABORTED'">
            <button (click)="regenerateFlyer(element)" mat-raised-button>
              Regenerate
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="example-loading-shade" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <mat-paginator [pageSizeOptions]="[25, 50, 100]" [pageSize]="50" [showFirstLastButtons]="true"></mat-paginator>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button
          (click)="regenerateFlyers()"
          *ngIf="errorCount || abortedCount"
          [disabled]="regeneratingAllFlyers">
    Regenerate for {{errorCount + abortedCount}}/{{properties.length}}
  </button>
  <button mat-stroked-button (click)="refresh()" *ngIf="properties">Refresh Status</button>

  <button mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>
