// Angular imports
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Service imports
import { FlyerService } from 'src/app/services/flyer.service';

// Material imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-flyer-publish-popup',
  templateUrl: './flyer-publish-popup.component.html',
  styleUrls: ['./flyer-publish-popup.component.css']
})


export class FlyerPublishPopupComponent implements OnInit {
  flyerId: number;

  constructor(public FlyerService: FlyerService,public router: Router,
    public dialogRef: MatDialogRef<FlyerPublishPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.router.url.includes('/edit')){
      this.flyerId = this.FlyerService.flyerDetail.flyerId;
    }
    else if (this.router.url.includes('/flyers')){
      this.flyerId = this.FlyerService.flyerId;
    } 
  }

  publishFlyers(flyerId){
    // console.log("flyerId", flyerId);
    this.FlyerService.publishFlyer(this.flyerId);
    this.FlyerService.showToastMessage("Flyer Published Successfully!", "check_circle", 'success');
  }
}
