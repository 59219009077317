<div class="container-fluid">
    
    <h2 mat-title><strong>Publish</strong></h2>

    <div mat-dialog-content class="center">
        <div class="row justify-content-center" >
            <p>The website page changes will be live on the customer's website. Do you want to Publish?</p>
        </div>
    </div>

    <div class="row justify-content-center">
        <div mat-dialog-actions class="custom-actions">
            <button mat-raised-button class="custom-cancel" [mat-dialog-close]="true">Cancel</button>
            <button mat-raised-button class="custom-yes" (click)="publishWebpage()" [mat-dialog-close]="true" >Yes</button>
        </div>
    </div>

</div>