<!-- Filter by Customers and Status Container-->
<div class="container-fluid custom-container">
  <div class="row">

    <!-- Filter by Customer-->
    <div class="col-4">
      <ng-select [items]="customers" bindLabel="name" bindValue="name" placeholder="Filter by Customer"
        [(ngModel)]="choosenCustomer" [formControl]="filterCustomer" [clearable]=false (change)="onFilter()">
      </ng-select>
    </div>

    <!-- Filter by Status -->
    <div class="col-4">
      <ng-select [items]="statusOptions" bindLabel="name" bindValue="name" placeholder="Filter by Status"
        [(ngModel)]="choosenStatus" [clearable]=false [formControl]="filterStatus" (change)="onFilter()">
      </ng-select>
    </div>

    <!-- Reset filters -->
    <div class="col-4 custom-col">
      <button mat-raised-button class="reset-btn" (click)="resetFilters()">
        Reset Filters
      </button>
    </div>

  </div>
</div>

<!-- Microsites Data/List table -->
<div>
  <table mat-table [dataSource]="dataSource">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell class="custom-cell" (click)="editTemplate(element.id, element.version)" *matCellDef="let element">
        {{element.name}}
        <br>
        <span [ngStyle]="{'color':element.status === 'DRAFT' ? '#EFB815' : '#5AB524'}">
          {{element.status}}
        </span>
      </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell class="custom-description-cell" (click)="editTemplate(element.id, element.version)"
        *matCellDef="let element">
        {{element.description}}
      </td>
    </ng-container>

    <!-- Customer Column -->
    <ng-container matColumnDef="customer">
      <th class="header" mat-header-cell *matHeaderCellDef> Customer </th>
      <td mat-cell class="custom-cell" (click)="editTemplate(element.id, element.version)" *matCellDef="let element">
        {{element.customerName}}
      </td>
    </ng-container>

    <!-- Last Modified Column -->
    <ng-container matColumnDef="lastModified">
      <th mat-header-cell *matHeaderCellDef> Last Modified </th>
      <td mat-cell class="custom-cell" (click)="editTemplate(element.id, element.version)" *matCellDef="let element">
        {{element.lastModifiedDate | date: 'medium'}}
      </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th class="header" mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell class="custom-type-cell" (click)="editTemplate(element.id, element.version)"
        *matCellDef="let element">
        {{element.type}}
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell class="custom-actions-cell" *matCellDef="let element">
        <button mat-button [matMenuTriggerFor]="menu">...</button>
        <mat-menu #menu="matMenu">
          <!-- Edit -->
          <button mat-menu-item class="custom-menu-item" *ngIf="(element.status === 'DRAFT')"
            (click)="editTemplate(element.id, element.version)">Edit</button>

          <!-- Copy -->
          <button mat-menu-item class="custom-menu-item" (click)="copy(element.id, element.version)">Copy</button>

          <!-- Publish -->
          <button mat-menu-item class="custom-menu-item" *ngIf="(element.status === 'DRAFT')"
            (click)="openDialog('publish', element.id, element.version)">Publish</button>

          <!-- Delete -->
          <button mat-menu-item class="custom-menu-item" *ngIf="(element.status === 'DRAFT')"
            (click)="openDialog('delete', element.id, element.version)">Delete</button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
  </table>

  <mat-paginator [length]="length" [pageIndex]="pageNo" [pageSize]="pageSize" [pageSizeOptions]="[30, 50, 100]"
    (page)=onPagination($event)>
  </mat-paginator>
</div>
