import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FlyerService } from 'src/app/services/flyer.service';
import { WebpagesService } from 'src/app/services/webpages.service';


@Component({
  selector: 'app-file-delete-popup',
  templateUrl: './file-delete-popup.component.html',
  styleUrls: ['./file-delete-popup.component.css']
})

export class FileDeletePopupComponent implements OnInit {

  constructor(
    private router: Router,
    public flyerService: FlyerService, public webPageService: WebpagesService,
    public dialogRef: MatDialogRef<FileDeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void { }

  deleteFile(fileId) {
    if (this.router.url.includes('/webpages/edit')) {
      this.webPageService.deleteFile(fileId);
    } else if (this.router.url.includes('/flyers/edit')) {
      this.flyerService.deleteFile(fileId);
    }
  }

  
}
