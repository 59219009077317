<div class="container-fluid">
    <div class="icon-box">
        <i class="material-icons custom-icon">priority_high</i>
    </div>
    <div class="row custom-row">
        <h2>
            <strong>Are you sure?</strong>
        </h2>
    </div>

    <div class="row custom-row">
        <p>Do you really want to delete this file? This cannot be undone.</p>
    </div>
    <div class="row custom-row">
        <div mat-dialog-actions>
            <button mat-raised-button [mat-dialog-close]="true">Cancel</button>
            <button mat-raised-button [mat-dialog-close]="true" class="btn btn-danger"
                (click)="deleteFile(data.fileId)">
                Yes, Delete
            </button>
        </div>
    </div>
</div>