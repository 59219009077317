// Angular core imports
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routes';
import { HttpRequestInterceptor } from './app.interceptor';

//Config
import { AppConfigService } from './services/app-config.service';
const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

// External modules
import { MaterialModule } from './material/material.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AceEditorModule } from 'ng2-ace-editor';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { SigninSignupModule } from '@npm_pc/signin-signup/signin-signup.module';
import { NgSelectModule } from '@ng-select/ng-select';

// Externally created components
import { HeaderComponent } from './components/header/header.component';
import { ListComponent } from './components/list/list.component';
import { SubHeaderComponent } from './components/list/sub-header/sub-header.component';
import { AddTemplateButtonComponent } from './components/list/sub-header/add-template-button/add-template-button.component';
import { DataTableComponent } from './components/list/data-table/data-table.component';
import { WebpageAddPopupComponent } from './components/popups/webpage-add-popup/webpage-add-popup.component';
import { FlyerEditComponent } from './components/flyer-edit/flyer-edit.component';
import { FlyerInfoComponent } from './components/flyer-edit/flyer-info/flyer-info.component';
import { WebLeftSectionComponent } from './components/flyer-edit/web-left-section/web-left-section.component';
import { TemplateEditorComponent } from './components/flyer-edit/template-editor/template-editor.component';
import { DeletePopupComponent } from './components/popups/delete-popup/delete-popup.component';
import { PdfViewerComponent } from './components/flyer-edit/pdf-viewer/pdf-viewer.component';
import { WebsitePreviewPopupComponent } from './components/popups/website-preview-popup/website-preview-popup.component';
import { WebsitePreviewButtonComponent } from './components/list/sub-header/website-preview-button/website-preview-button.component';
import { WebpagePublishPopupComponent } from './components/popups/webpage-publish-popup/webpage-publish-popup.component';
import { FlyerUnpublishPopupComponent } from './components/popups/flyer-unpublish-popup/flyer-unpublish-popup.component';
import { FlyerPublishPopupComponent } from './components/popups/flyer-publish-popup/flyer-publish-popup.component';
import { FileAddPopupComponent } from './components/popups/file-add-popup/file-add-popup.component';
import { FileDeletePopupComponent } from './components/popups/file-delete-popup copy/file-delete-popup.component';
import { FileRenamePopupComponent } from './components/popups/file-rename-popup copy/file-rename-popup.component';
import { ShowImageFilePopupComponent } from './components/popups/show-image-file-popup/show-image-file-popup.component';
import { IconSnackBarComponent } from './components/icons-snack-bar/icon-snack-bar.component';
import { SigninPopupComponent } from './components/popups/signin-popup/signin-popup.component';
import { UserService } from './services/user.service';
import { PreviewConfirmationPopupComponent } from './components/popups/preview-confirmation-popup/preview-confirmation-popup.component';
import { OpenInNewTabPopupComponent } from './components/popups/open-in-new-tab-popup/open-in-new-tab-popup.component';
import { FlyerAddPopupComponent } from './components/popups/flyer-add-popup/flyer-add-popup.component';
import { FlyerDataTableComponent } from './components/list/flyer-data-table/flyer-data-table.component';
import { AddAttributePopupComponent } from './components/popups/add-attribute-popup/add-attribute-popup.component';
import { FlyerAttributesComponent } from './components/flyer-edit/flyer-attributes/flyer-attributes.component';
import { AttributeDeletePopupComponent } from './components/popups/attribute-delete-popup/attribute-delete-popup.component';
import { AttributeEditPopupComponent } from './components/popups/attribute-edit-popup/attribute-edit-popup.component';
import { PropertiesDropdownComponent } from './components/flyer-edit/properties-dropdown/properties-dropdown.component';
import { DownloadAssetsComponent } from './components/download-assets/download-assets.component';
import { FlyerStatusComponent } from './components/popups/flyer-status/flyer-status.component';
import { MicrositesDataTableComponent } from './components/list/microsites-data-table/microsites-data-table.component';
import { MicrositesSubheaderComponent } from './components/list/sub-header/microsites-subheader/microsites-subheader.component';
import { MicrositeEditComponent } from './components/list/microsite-edit/microsite-edit.component';
import { MicrositePagesTableComponent } from './components/list/microsite-pages-table/microsite-pages-table.component';
import { MicrositeHomepageConfirmationPopupComponent } from './components/popups/microsite-homepage-confirmation-popup/microsite-homepage-confirmation-popup.component';
import { MicrositeAddPopupComponent } from './components/popups/microsite-add-popup/microsite-add-popup.component';
import { MicrositePagesAddPopupComponent } from './components/popups/microsite-pages-add-popup/microsite-pages-add-popup.component';
import { MicrositePageEditLeftSectionComponent } from './components/flyer-edit/microsite-page-edit-left-section/microsite-page-edit-left-section.component';
import { MicrositeEditHeaderComponent } from './components/list/microsite-edit/microsite-edit-header/microsite-edit-header.component';
import { MicrositeEditPopupComponent } from './components/popups/microsite-edit-popup/microsite-edit-popup.component';
import { MicrositePagePublishPopupComponent } from './components/popups/microsite-page-publish-popup/microsite-page-publish-popup.component';
import { MicrositePageUnpublishPopupComponentComponent } from './components/popups/microsite-page-unpublish-popup-component/microsite-page-unpublish-popup-component.component';
import { MicrositeClonePopupComponent } from './components/popups/microsite-clone-popup/microsite-clone-popup.component';
import { MicrositeCopyPagePopupComponent } from './components/popups/microsite-copy-page-popup/microsite-copy-page-popup.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ListComponent,
    SubHeaderComponent,
    AddTemplateButtonComponent,
    DataTableComponent,
    WebpageAddPopupComponent,
    FlyerEditComponent,
    FlyerInfoComponent,
    WebLeftSectionComponent,
    TemplateEditorComponent,
    DeletePopupComponent,
    PdfViewerComponent,
    WebsitePreviewButtonComponent,
    WebsitePreviewPopupComponent,
    WebsitePreviewPopupComponent,
    WebsitePreviewButtonComponent,
    WebpagePublishPopupComponent,
    FlyerUnpublishPopupComponent,
    FlyerPublishPopupComponent,
    FileDeletePopupComponent,
    FileRenamePopupComponent,
    FileAddPopupComponent,
    ShowImageFilePopupComponent,
    IconSnackBarComponent,
    SigninPopupComponent,
    PreviewConfirmationPopupComponent,
    OpenInNewTabPopupComponent,
    FlyerAddPopupComponent,
    FlyerDataTableComponent,
    AddAttributePopupComponent,
    FlyerAttributesComponent,
    AttributeDeletePopupComponent,
    AttributeEditPopupComponent,
    PropertiesDropdownComponent,
    DownloadAssetsComponent,
    FlyerStatusComponent,
    MicrositesDataTableComponent,
    MicrositesSubheaderComponent,
    MicrositeEditComponent,
    MicrositePagesTableComponent,
    MicrositeHomepageConfirmationPopupComponent,
    MicrositeAddPopupComponent,
    MicrositePagesAddPopupComponent,
    MicrositePageEditLeftSectionComponent,
    MicrositeEditHeaderComponent,
    MicrositeEditPopupComponent,
    MicrositePagePublishPopupComponent,
    MicrositePageUnpublishPopupComponentComponent,
    MicrositeClonePopupComponent,
    MicrositeCopyPagePopupComponent,
  ],

  imports: [
    BrowserModule,
    SigninSignupModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CommonModule,
    AceEditorModule,
    NgxExtendedPdfViewerModule,
    MaterialModule,
    ClipboardModule,
    NgSelectModule,
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    UserService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
