import { Component, OnInit, OnDestroy, Input, Output, EventEmitter  } from '@angular/core';
import { Subscription, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, filter, map } from 'rxjs/operators'


@Component({
  selector: 'app-properties-dropdown',
  templateUrl: './properties-dropdown.component.html',
  styleUrls: ['./properties-dropdown.component.css']
})
export class PropertiesDropdownComponent implements OnInit {
  photosBuffer = [];
  bufferSize = 50;
  numberOfItemsFromEndBeforeFetchingMore = 10;
  loading = true;
  subscription: Subscription;
  input$ = new Subject<string>();
  selectedItems = [];
  isBeingUsed: boolean = false;

  @Input() inputvar = [];
  @Input() inputSub: Observable<any>;
  @Input() multiple: boolean = false;
  @Input() selectAll: boolean = false;
  @Output() propertySelected = new EventEmitter<string>();
  @Output() checkAllAction = new EventEmitter<string>();

  ngOnInit(): void {
    if (this.inputvar && this.inputvar.length > 0) {
      this.photosBuffer = this.inputvar.slice(0, this.bufferSize);
      this.loading = false;  
    }
    
    if (this.inputSub) {
      this.subscription = this.inputSub.subscribe((data) => {
        
        if (data) {
          this.selectedItems = [];
          this.photosBuffer = data.slice(0, this.bufferSize);
          this.setSelectedItems(data);
        }
        this.loading = false;
        // console.log('selectedItems', this.selectedItems);
      });
    }

    

    this.onSearch();
  }

  setSelectedItems(more: Array<any>) {
    if (more) {
      more.forEach(item => {
        if (item.isSelected && item.propertyGlobalId) {
          // console.log('Pushing to selected', item);
          if (!this.selectedItems.includes(item.propertyGlobalId)) {
            this.selectedItems = [...this.selectedItems, item.propertyGlobalId];
          }
        }
      })
    }
  }

  onScrollToEnd() {
      this.fetchMore();
  }

  onScroll({ end }) {
      if (this.loading || this.inputvar.length <= this.photosBuffer.length) {
          return;
      }

      if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.photosBuffer.length) {
          this.fetchMore();
      }
  }

  private fetchMore() {
      const len = this.photosBuffer.length;
      let more = this.inputvar.slice(len, this.bufferSize + len);
      this.photosBuffer = this.photosBuffer.concat(more);
      this.setSelectedItems(more);
  }

  private onSearch() {
    this.input$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    )
      .subscribe((term: string) => {
        if (term) {
          // console.log('Searching term', term);
          var searchResults = [];

          for (let i = 0; i < this.inputvar.length; i++) {
            if (this.inputvar[i].propertyName && this.inputvar[i].propertyName.toLowerCase().includes(term.trim().toLowerCase())) {
              // console.log('Found a result! Pushing to searchResults. Value: ', term);
              searchResults.push(this.inputvar[i]);
            }

            if (searchResults.length > this.bufferSize) {
              // console.log('Enough search results found!', searchResults.length);
              break;
            }
          }

          this.photosBuffer = searchResults;
        } else {
          this.photosBuffer = this.inputvar.slice(0, this.bufferSize);
        }
      })
  }

  onSelectAll(event) {
    if (event.target.checked) {
      this.selectedItems = [...this.inputvar.map(x => x.propertyGlobalId)];
    } else {
      this.selectedItems = [];
    }
    this.checkAllAction.emit(event.target.checked);
  }

  onClearAll() {

  }

  getLinkedDisplayText(): string {
    return this.selectedItems.length + ' ' + (this.selectedItems.length == 1 ? 'Property': 'Properties') + ' linked';
  }


  ngOnDestroy(): void {
    this.inputSub && this.subscription.unsubscribe();
    this.input$.unsubscribe();
  }

}