<div class="microsite-pages-add-popup">
  <div class="row custom-close-row">
    <button class="custom-close-btn" mat-icon-button [mat-dialog-close]="true">
      <span class="material-icons">close</span>
    </button>
  </div>

  <div class="container-fluid">
    <div mat-dialog-title class="popup-heading">edit title/description</div>

    <div mat-dialog-content>
      <form novalidate #editTemplateForm="ngForm" (ngSubmit)="onSubmit(editTemplateForm)">
        <!-- Microsite page url -->
        <div class="main-row row">
          <div class="page-url-input-wrapper" *ngIf="this.status === 'Live'">
            <mat-form-field
              class="full-width custom-margin"
              appearance="outline"
              [floatLabel]="'always'"
            >
              <mat-label class="custom-label custom-color">Host name</mat-label>
              <span class="protocol">https://</span>
              <input
                matInput
                placeholder="Microsite host name"
                class="hostname-input"
                type="text"
                [(ngModel)]="hostname"
                name="micrositeHost"
                #micrositeHost="ngModel"
                pattern="^([a-z0-9]+(-[a-z0-9]+)*\.){1,}([a-z]{2,3})$"
                (ngModelChange)="onHostnameValueChange($event)"
                required
              />
              <mat-error
                *ngIf="
                  !micrositeHost?.valid &&
                  (micrositeHost?.dirty || micrositeHost?.touched) &&
                  micrositeHost.errors
                "
              >
                <strong *ngIf="micrositeHost.errors.required">You must enter a hoste name!</strong>
                <strong *ngIf="micrositeHost.errors.pattern"
                  >Only `-`, `.`, alphabet and numbers are allowed !</strong
                >
              </mat-error>
            </mat-form-field>
            <div class="custom-error-message" *ngIf="showDuplicateHostnameErrorMessage">
              <strong>{{ duplicateHostnameErrorMessage }}</strong>
            </div>
          </div>
          <div class="page-url-input-wrapper" *ngIf="this.status !== 'Live'">
            <mat-form-field
              class="full-width custom-margin"
              appearance="outline"
              [floatLabel]="'always'"
            >
              <mat-label class="custom-label custom-color">Host name</mat-label>
              <span class="protocol">https://</span>
              <input
                matInput
                class="hostname-input"
                placeholder="Microsite host name"
                type="text"
                [(ngModel)]="hostname"
                name="micrositeHost"
                #micrositeHost="ngModel"
                pattern="^([a-z0-9]+(-[a-z0-9]+)*\.){1,}([a-z]{2,3})$"
                (ngModelChange)="onHostnameValueChange($event)"
              />
              <mat-error
                *ngIf="
                  !micrositeHost?.valid &&
                  (micrositeHost?.dirty || micrositeHost?.touched) &&
                  micrositeHost.errors
                "
              >
                <strong *ngIf="micrositeHost.errors.pattern">Invalid Hostname !</strong>
              </mat-error>
            </mat-form-field>
            <div class="custom-error-message" *ngIf="showDuplicateHostnameErrorMessage">
              <strong>{{ duplicateHostnameErrorMessage }}</strong>
            </div>
          </div>
        </div>

        <!-- Microsite page name -->
        <div class="main-row row">
          <mat-form-field
            class="full-width"
            appearance="outline"
            hintLabel="Max 60 characters"
            [floatLabel]="'always'"
          >
            <mat-label class="custom-label custom-color">Name</mat-label>
            <input
              matInput
              #micrositeTitleInput
              placeholder="Enter microsite name*"
              type="text"
              [(ngModel)]="name"
              [maxLength]="60"
              name="micrositeName"
              required
            />
            <mat-error *ngIf="micrositeName.invalid">
              <strong>You must enter a name!</strong>
            </mat-error>
            <mat-hint align="end">{{ micrositeTitleInput.value?.length || 0 }}/60</mat-hint>
          </mat-form-field>
        </div>

        <!-- Microsite page description  -->
        <div class="main-row row">
          <mat-form-field
            class="full-width custom-description"
            appearance="outline"
            hintLabel="Max 120 characters"
            [floatLabel]="'always'"
          >
            <mat-label class="custom-label custom-color">Description</mat-label>
            <textarea
              matInput
              placeholder="Add microsite description"
              type="textarea"
              [(ngModel)]="description"
              name="micrositeDescription"
              #micrositeDescription="ngModel"
              [maxLength]="120"
              required
            ></textarea>
            <mat-error *ngIf="micrositeDescription.invalid">
              <strong>You must enter a Description!</strong>
            </mat-error>
            <mat-hint align="end">{{ micrositeDescription.value?.length || 0 }}/120</mat-hint>
          </mat-form-field>
        </div>

        <!-- Save button -->
        <div class="main-row row custom-row">
          <div mat-dialog-actions class="custom-actions">
            <button
              type="submit"
              mat-raised-button
              class="custom-save custom-label"
              [disabled]="editTemplateForm.form.invalid"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
