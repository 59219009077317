import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Clipboard } from '@angular/cdk/clipboard';

import { WebpagesService } from 'src/app/services/webpages.service';

import { FileDeletePopupComponent } from '../../popups/file-delete-popup copy/file-delete-popup.component';
import { FileRenamePopupComponent } from '../../popups/file-rename-popup copy/file-rename-popup.component';
import { FileAddPopupComponent } from '../../popups/file-add-popup/file-add-popup.component';
import { ShowImageFilePopupComponent } from '../../popups/show-image-file-popup/show-image-file-popup.component';

import { customers as Customer } from '../../../models/customers';

@Component({
  selector: 'app-web-left-section',
  templateUrl: './web-left-section.component.html',
  styleUrls: ['./web-left-section.component.css'],
})
export class WebLeftSectionComponent implements OnInit {
  /* To access the element fileUpload and tabs */
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  @ViewChild('tabs') tabGroup: MatTabGroup;

  /* An array to hold the uploaded files*/
  files = [];

  /* To navigate to the proper section */
  router: Router;

  /* To store details of templates */
  templateDetail: any;
  basicDetail: any;
  customers: Customer[];
  templateFiles: any;
  assetFiles: any;
  templateDetailChanged: Subscription;

  /* To edit fields in the basic section */
  name = new FormControl('', [Validators.required]);

  /* To open selected file in editor */
  selectedFile: any;

  /* Read image */
  imageSource;

  /* Data load promise */
  dataLoaded: Promise<boolean>;

  /* Selecting the correct tab */
  selectedTab: any;

  /* Flyer saved */
  webpageSaved: Subscription;

  /* File changed */
  fileChanged: any;

  constructor(
    private renderer: Renderer2,
    private _router: Router,
    public dialog: MatDialog,
    private clipboard: Clipboard,
    private _route: ActivatedRoute,
    public webPageService: WebpagesService,
  ) {
    this.router = _router;
    this._route.queryParams.subscribe(params => {
      if (params['section']) {
        if (params['section'] == 'basic') this.selectedTab = 0;
        else if (params['section'] == 'details') this.selectedTab = 1;
      } else {
        this.selectedTab = 0;
      }
    });
  }

  ngOnInit(): void {
    this.templateDetailChanged = this.webPageService.templateDetailChanged.subscribe(
      templateDetail => {
        this.templateDetail = templateDetail;
        const { files, ...basicDetails } = templateDetail;
        this.templateFiles = files.filter(
          file =>
            file.mediaType == 'HTML' ||
            file.mediaType == 'CSS' ||
            file.mediaType == 'JS' ||
            file.mediaType == 'TEXT_PLAIN',
        );
        this.assetFiles = files.filter(
          file =>
            file.mediaType !== 'HTML' &&
            file.mediaType !== 'CSS' &&
            file.mediaType !== 'JS' &&
            file.mediaType !== 'TEXT_PLAIN',
        );
        this.basicDetail = basicDetails;
        this.webPageService.basicFormValid = true;
        this._router.navigate([], {
          queryParams: { section: 'details' },
        });
        const mainFtlh = files.filter(file => file.name == 'main.ftlh');
        if (mainFtlh.length == 1 && !this.selectedFile) {
          this.selectedFile = mainFtlh[0].name;
          this.webPageService.openFileInEditor('main.ftlh');
        }
        this.dataLoaded = Promise.resolve(true);
      },
    );

    this.webpageSaved = this.webPageService.webpageSaved.subscribe(isSaved => {
      if (isSaved) this.files = [];
    });

    this.fileChanged = this.webPageService.mainFtlhSelected.subscribe(mainFtlhSelected => {
      if (mainFtlhSelected) this.selectedFile = 'main.ftlh';
    });

    this.webPageService.getCustomers().subscribe((res: Customer[]) => {
      if (res) {
        this.customers = res.sort((a, b) => a.name.localeCompare(b.name));
      }
    });
  }

  /* To copy S3 URL for downloading any file */
  copyS3URL(s3Url) {
    this.clipboard.copy(s3Url);
    this.webPageService.showToastMessage('URL Copied Successfully!', 'check_circle', 'success');
  }

  /* To select the right tab - basic or details */
  selectTab($event) {
    if ($event.index == 0) {
      this._router.navigate([], {
        queryParams: { section: 'basic' },
      });
    } else if ($event.index == 1) {
      this._router.navigate([], {
        queryParams: { section: 'details' },
      });
    }
    this.selectedTab = $event.index;
  }

  /* To open File delete pop up */
  openDeletePopup(file) {
    this.dialog.open(FileDeletePopupComponent, {
      data: { fileId: file.id },
    });
  }

  /* To open File rename pop up */
  openRenamePopup(fileName) {
    this.dialog.open(FileRenamePopupComponent, {
      data: { fileName: fileName },
    });
  }

  /* To open add file pop up */
  openAddFilePopup() {
    this.dialog.open(FileAddPopupComponent);
  }

  /* To restore a file */
  restoreFile(fileName) {
    this.webPageService.restoreFile(fileName);
  }

  /* To Toggle the expansion panel */
  expandPanel(matExpansionPanel, event): void {
    event.stopPropagation();

    if (!this._isExpansionIndicator(event.target)) {
      matExpansionPanel.toggle();
    }
  }
  private _isExpansionIndicator(target: EventTarget): boolean {
    const expansionIndicatorClass = 'mat-expansion-indicator';
    return target['classList'] && target['classList'].contains(expansionIndicatorClass);
  }

  /* Executed on file upload */
  onUpload() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        let file = fileUpload.files[index];
        if (file.size > 9999999) {
          alert(' Size of this file is greater than 10mb \n ->' + file.name);
          if (index < fileUpload.files.length) {
            continue;
          } else return false;
        }
        if (file.name.split('.').length == 1) {
          alert('Files without extensions cannot be uploaded \n ->' + file.name);
          if (index < fileUpload.files.length) {
            continue;
          } else return false;
        }
        if (!this.webPageService.isFileTypeValid(file.name)) {
          alert(file.name + ' This type of file is not allowed');
          if (index < fileUpload.files.length) {
            continue;
          } else return false;
        }

        if (this.webPageService.hasWhiteSpace(file.name)) {
          // console.log("filename:", this.webPageService.hasWhiteSpace(file.name));
          alert(file.name + ' This filename contains spaces');
          if (index < fileUpload.files.length) {
            continue;
          } else return false;
        }

        if (this.webPageService.checkDuplicateFilename(file.name)) {
          alert(file.name + ' This filename already exists');
          if (index < fileUpload.files.length) {
            continue;
          } else return false;
        }
        const fileName = file.name.split('.');

        if (fileName[fileName.length - 1] == 'ttf')
          file = new File([file], file.name, { type: 'font/ttf' });
        else if (
          fileName[fileName.length - 1] == 'woff' ||
          fileName[fileName.length - 1] == 'woff2'
        )
          file = new File([file], file.name, { type: 'font/woff' });
        else if (fileName[fileName.length - 1] == 'otf')
          file = new File([file], file.name, { type: 'font/otf' });
        else if (fileName[fileName.length - 1] == 'ico')
          file = new File([file], file.name, { type: 'image/x-icon' });
        else if (fileName[fileName.length - 1] == 'gif')
          file = new File([file], file.name, { type: 'image/gif' });
        else if (fileName[fileName.length - 1] == 'jpg')
          file = new File([file], file.name, { type: 'image/jpeg' });
        else if (fileName[fileName.length - 1] == 'png')
          file = new File([file], file.name, { type: 'image/png' });
        else if (fileName[fileName.length - 1] == 'webp')
          file = new File([file], file.name, { type: 'image/webp' });
        else if (fileName[fileName.length - 1] == 'svg')
          file = new File([file], file.name, { type: 'image/svg+xml' });
        else if (fileName[fileName.length - 1] == 'bmp')
          file = new File([file], file.name, { type: 'image/bmp' });
        else if (fileName[fileName.length - 1] == 'css')
          file = new File([file], file.name, { type: 'text/css' });
        else if (fileName[fileName.length - 1] == 'js')
          file = new File([file], file.name, { type: 'text/javascript' });
        else if (fileName[fileName.length - 1] == 'jpeg')
          file = new File([file], file.name, { type: 'image/jpeg' });
        else if (fileName[fileName.length - 1] == 'eot')
          file = new File([file], file.name, { type: 'application/vnd.ms-fontobject' });
        else if (fileName[fileName.length - 1] == 'json')
          file = new File([file], file.name, { type: 'application/json' });
        else if (fileName[fileName.length - 1] == 'ftl' || fileName[fileName.length - 1] == 'ftlh')
          file = new File([file], file.name, { type: 'text/plain' });
        else if (fileName[fileName.length - 1] == 'pdf')
          file = new File([file], file.name, { type: 'application/pdf' });

        if (fileUpload.files && fileUpload.files[0]) {
          const file = fileUpload.files[0];
          const reader = new FileReader();
          reader.onload = e => (this.imageSource = reader.result);
          reader.readAsDataURL(file);
        }
        this.webPageService.filesModified = true;
        this.files.push({ data: file, inProgress: true, progress: 100 });
        this.webPageService.addNewAssetFile(file);
      }
    };
    fileUpload.click();
  }

  /* For file edit */
  onEdit(file) {
    // console.log(file.mediaType);
    if (
      file.mediaType == 'PNG' ||
      file.mediaType == 'JPEG' ||
      file.mediaType == 'GIF' ||
      file.mediaType == 'ICON' ||
      file.mediaType == 'SVG' ||
      file.mediaType == 'BMP'
    ) {
      this.dialog.open(ShowImageFilePopupComponent, {
        data: {
          src: file.downloadUrl,
          fileName: file.name,
        },
      });
      return false;
    } else if (
      file.mediaType == 'TTF' ||
      file.mediaType == 'WOFF' ||
      file.mediaType == 'OTF' ||
      file.mediaType == 'TIFF' ||
      file.mediaType == 'EOT' ||
      file.mediaType == 'PDF'
    ) {
      return false;
    } else {
      this.selectedFile = file.name;
      this.webPageService.openFileInEditor(file.name);
    }
  }

  isActive(item) {
    return this.selectedFile == item;
  }

  /* To check if the details entered in basic section are valid or not */
  setIsValid() {
    if (
      this.templateDetail.name.length > 0 &&
      this.templateDetail.type.length > 0 &&
      this.templateDetail.description.length > 0
    )
      this.webPageService.basicFormValid = true;
    else this.webPageService.basicFormValid = false;
  }

  /* To replace asset URLs */
  onReplaceURLs() {
    this.webPageService.replaceAssetUrls();
  }
}
