<div class="micosites-list-wrapper">
  <table mat-table [dataSource]="dataSource">
    <!-- Microsite Page Name Column -->
    <ng-container matColumnDef="pageName">
      <th mat-header-cell *matHeaderCellDef class="table-headers">Page name</th>
      <td
        mat-cell
        class="table-cell"
        *matCellDef="let element; let i = index"
        [attr.name]="element.name"
        (click)="editMicrositePage(element.id)"
      >
        <label
          class="homepage-icon"
          *ngIf="element.page == micrositesService.currentMicrositeData.homePage"
        >
          <img src="assets/icons/home.png" />
        </label>
        <label class="page-name">
          {{ element.name }}
        </label>
      </td>
    </ng-container>

    <!-- Microsite Page Description Column -->
    <ng-container matColumnDef="pageDescription">
      <th mat-header-cell *matHeaderCellDef class="table-headers">Page description</th>
      <td
        mat-cell
        class="table-cell"
        *matCellDef="let element"
        (click)="editMicrositePage(element.id)"
      >
        <div class="description-wrapper">
          {{ element.description }}
        </div>
      </td>
    </ng-container>

    <!-- Microsite Page Status Column -->
    <ng-container matColumnDef="status">
      <th class="header" mat-header-cell *matHeaderCellDef class="table-headers">Status</th>
      <td
        mat-cell
        class="table-cell status-cell"
        *matCellDef="let element"
        (click)="editMicrositePage(element.id)"
      >
        {{ getMicrositePageStatus(element) }}
      </td>
    </ng-container>

    <!-- Microsite Page Last Modified Column -->
    <ng-container matColumnDef="lastPublished">
      <th mat-header-cell *matHeaderCellDef class="table-headers">Last published</th>
      <td
        mat-cell
        class="table-cell"
        *matCellDef="let element"
        (click)="editMicrositePage(element.id)"
      >
        <span *ngIf="element.lastPublishedDate == ''"> - </span>
        <!-- TODO-->
        <!-- {{element.lastPublishedDate | date: 'MM/d/yyyy'}} -->
      </td>
    </ng-container>

    <!-- Microsite Page Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="table-headers">Actions</th>
      <td align="left" mat-cell class="table-cell actions-cell" *matCellDef="let element">
        <button mat-button [matMenuTriggerFor]="menu">...</button>
        <mat-menu #menu="matMenu">
          <!-- Edit Button -->
          <button
            mat-menu-item
            class="custom-menu-item"
            *ngIf="element.page != micrositesService.currentMicrositeData.homePage"
            (click)="showHomepageConfirmationPopup(element.id)"
          >
            Set as homepage
          </button>

          <!-- Edit Button -->
          <button mat-menu-item class="custom-menu-item" (click)="editMicrositePage(element.id)">
            Edit
          </button>

          <!-- Copy Button -->
          <button mat-menu-item class="custom-menu-item" (click)="copyMicrositePage(element)">
            Copy
          </button>

          <!-- Publish Button -->
          <button
            mat-menu-item
            *ngIf="!element.published"
            [disabled]="true"
            class="custom-menu-item"
          >
            Publish
          </button>

          <!-- UnPublish Button -->
          <button
            mat-menu-item
            *ngIf="element.published"
            [disabled]="false"
            class="custom-menu-item"
            (click)="confirmUnpublish(element)"
          >
            Unpublish
          </button>

          <!-- Delete Button -->
          <button
            mat-menu-item
            *ngIf="!element.published"
            class="custom-menu-item"
            (click)="confirmDeletePage(element)"
          >
            Delete
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columnsToDisplay"
      [ngClass]="{ 'mat-row-active': row.page == micrositesService.currentMicrositeData.homePage }"
    ></tr>
  </table>

  <div
    *ngIf="!showMicrositeList"
    [ngClass]="{ 'show-empty-microsite-page-container': !dataSource.data.length }"
    class="empty-microsites-pages-container"
  >
    <div>
      <span class="empty-microsites-pages-message">You haven't created any pages yet</span>
    </div>
    <div class="add-microsite-page-button-wrapper">
      <button
        mat-raised-button
        class="add-microsite-page-button"
        (click)="micrositesService.openAddMicrositePagesPopup()"
      >
        Add page
      </button>
    </div>
  </div>
</div>
