import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { WebpagesService } from 'src/app/services/webpages.service';
import { templateDetail } from 'src/app/models/templateDetail';

import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-webpage-publish-popup',
  templateUrl: './webpage-publish-popup.component.html',
  styleUrls: ['./webpage-publish-popup.component.css']
})


export class WebpagePublishPopupComponent implements OnInit {

  // for getting the current datasource
  dataSource = new MatTableDataSource();
  sharedDataSourceChanged$: any = new Subject<any>();

  // for storing template id and version of the webpage to be published
  templateID: number;
  versionID: number;

  // To fetch the details of the template requested for publishing it
  requestedTemplate: any;

  constructor(public WebpagesService: WebpagesService,) { }

  ngOnInit(): void { }

  publishWebpage() {
    this.templateID = this.WebpagesService.templateID;
    this.versionID = this.WebpagesService.version;

    this.WebpagesService.getTemplate(this.templateID, this.versionID).subscribe((response: templateDetail) => {
      this.requestedTemplate = response;
      this.publishItem(this.templateID, this.versionID, this.requestedTemplate);
    })
  }


  publishItem(id, version, template) {
    this.WebpagesService.publishTemplate(id, version, template).subscribe((response) => {
      this.WebpagesService.loadTemplatesOnPagination(1, 30, '', '');
      this.WebpagesService.showToastMessage("Webpage Published Successfully!", "check_circle", 'success');
      this.WebpagesService.assignData(response);
    })
  }

  
}