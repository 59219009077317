import { Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Rx';

import { MatDialog } from '@angular/material/dialog';

import { WebpagePublishPopupComponent } from '../../popups/webpage-publish-popup/webpage-publish-popup.component';
import { FlyerPublishPopupComponent } from '../../popups/flyer-publish-popup/flyer-publish-popup.component';

import { FlyerService } from 'src/app/services/flyer.service';
import { WebpagesService } from 'src/app/services/webpages.service';
import { ThrowStmt } from '@angular/compiler';
import { FlyerStatusComponent } from '../../popups/flyer-status/flyer-status.component';

@Component({
  selector: 'app-flyer-info',
  templateUrl: './flyer-info.component.html',
  styleUrls: ['./flyer-info.component.css'],
})
export class FlyerInfoComponent implements OnInit {
  router: Router;
  path: string;
  routeQueryParams$: Subscription;

  // Webpage and Flyer info
  webpageInfo: any;
  webpageName: any;
  webpageType: any;
  webpageStatus: any;

  // Template and Flyer detail changed
  templateDetailChanged: Subscription;
  flyerDetailChanged: Subscription;

  // To show template and flyer info only when the data is available
  dataLoaded: Promise<boolean>;

  // To Do: This code is not required. Eliminate this.
  isDisable: boolean = true;

  // Flyer info
  flyerInfo: any;
  flyerName: any;
  flyerType: any;
  flyerStatus: any;

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private route: ActivatedRoute,
    public webPageService: WebpagesService,
    public flyerService: FlyerService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.router = _router;
  }

  ngOnInit(): void {
    // Fetch the webpage template details
    if (this._router.url.includes('/webpages/edit')) {
      this.templateDetailChanged = this.webPageService.templateNameChanged.subscribe(
        (templateDetail) => {
          // console.log('fetched template details:', templateDetail);
          this.webpageInfo = templateDetail;
          this.webpageName = this.webpageInfo.name;
          this.webpageType = this.webpageInfo.type;
          this.webpageStatus = this.webpageInfo.status;
          this.dataLoaded = Promise.resolve(true);
        }
      );
    }

    // Fetch the flyer template details
    if (this._router.url.includes('/flyers/edit')) {
      this.flyerDetailChanged = this.flyerService.flyerNameChanged.subscribe((flyerDetail) => {
        // console.log('fetched flyer details:', flyerDetail);
        this.flyerInfo = flyerDetail;
        this.flyerName = this.flyerInfo.name;
        this.flyerType = this.flyerInfo.type;
        this.flyerStatus = this.flyerInfo.status;
        this.dataLoaded = Promise.resolve(true);
      });
    }
  }

  // ON BACK BUTTON CLICK
  onBack() {
    if (this._router.url.includes('/webpages/edit')) {
      this.router.navigate(['/webpages']);
    } else if (this._router.url.includes('/flyers/edit')) {
      this.router.navigate(['/flyers']);
    }
  }

  // On publish click
  onPublish() {
    if (this._router.url.includes('/webpages/edit')) {
      this.webPageService.templateID = this.webpageInfo.id;
      this.webPageService.version = this.webpageInfo.version;
      const openSnackbar = this.dialog.open(WebpagePublishPopupComponent);
      this.webPageService.saveWebpage(openSnackbar);
    } else if (this._router.url.includes('/flyers/edit')) {
      this.flyerService.flyerStatus = this.flyerInfo.status;
      const openSnackbar = this.dialog.open(FlyerPublishPopupComponent, {
        data: { flyerId: this.flyerInfo.flyerId },
      });
      this.flyerService.saveFlyer(openSnackbar);
    }
  }

  // SAVE THE TEMPLATE OR FLYER
  onSaveTemplate() {
    if (this._router.url.includes('/webpages/edit')) {
      this.webPageService.saveWebpage(null);
    } else if (this._router.url.includes('/flyers/edit')) {
      this.flyerService.saveFlyer(
        this.flyerService.isFlyerBeingPreviewed ? 'get-preview-for-flyer' : null
      );
    }
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  showFlyerPropertyStatus() {
    this.dialog.open(FlyerStatusComponent, {
      data: this.flyerInfo,
    });
  }
}
