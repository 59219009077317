<!-- Filter by customers and status -->
<div class="container-fluid custom-container">
  <div class="row">
    <!-- Filter by Customer-->
    <div class="col">
      <ng-select
        [items]="customers"
        [(ngModel)]="chosenCustomer"
        bindLabel="name"
        bindValue="name"
        placeholder="Filter by Customer"
        [formControl]="filterCustomer"
        [clearable]="false"
        (change)="onFilter($event, 'customer')"
      >
      </ng-select>
    </div>

    <!-- Filter by Building -->
    <div class="col">
      <ng-select
        [items]="buildings"
        [(ngModel)]="chosenBuilding"
        bindLabel="name"
        placeholder="Filter by Building"
        [clearable]="false"
        [formControl]="filterBuilding"
        (change)="onFilter($event, 'building')"
        [compareWith]="doItemsHaveSameIds"
      >
      </ng-select>
    </div>

    <!-- Filter by Status -->
    <div class="col">
      <ng-select
        [items]="statusOptions"
        [(ngModel)]="chosenStatus"
        bindLabel="name"
        bindValue="name"
        placeholder="Filter by Status"
        [clearable]="false"
        [formControl]="filterStatus"
        (change)="onFilter($event, 'status')"
      >
      </ng-select>
    </div>

    <!-- reset filters -->
    <div class="col">
      <button
        [disabled]="areFilterEmpty"
        mat-raised-button
        class="reset-btn"
        (click)="resetFilters()"
      >
        Reset
      </button>
    </div>
  </div>
</div>

<!-- Data table -->

<div class="micosites-list-wrapper">
  <table mat-table [dataSource]="dataSource">
    <!-- Building Name Column -->
    <ng-container matColumnDef="buildingName">
      <th mat-header-cell *matHeaderCellDef class="table-headers">Building name</th>
      <td
        mat-cell
        class="table-cell name-cell"
        *matCellDef="let element"
        (click)="editMicrosite(element.id)"
      >
        <div class="buildingName">
          {{ element.buildingName }}
        </div>
        <div class="buildingAddress">
          {{ getBuildingAddress(element) }}
        </div>
      </td>
    </ng-container>

    <!-- Microsite Name Column -->
    <ng-container matColumnDef="micrositeName">
      <th mat-header-cell *matHeaderCellDef class="table-headers">Microsite name</th>
      <td mat-cell class="table-cell" *matCellDef="let element" (click)="editMicrosite(element.id)">
        {{ element.name }}
      </td>
    </ng-container>

    <!-- Microsite Description Column -->
    <ng-container matColumnDef="micrositeDescription">
      <th mat-header-cell *matHeaderCellDef class="table-headers">Microsite description</th>
      <td mat-cell class="table-cell" *matCellDef="let element" (click)="editMicrosite(element.id)">
        {{ element.description }}
      </td>
    </ng-container>

    <!-- Customer Column -->
    <ng-container matColumnDef="customer">
      <th class="header" mat-header-cell *matHeaderCellDef class="table-headers">Customer</th>
      <td mat-cell class="table-cell" *matCellDef="let element" (click)="editMicrosite(element.id)">
        {{ element.customerName }}
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th class="header" mat-header-cell *matHeaderCellDef class="table-headers">Status</th>
      <td
        mat-cell
        class="table-cell status-cell"
        *matCellDef="let element"
        (click)="editMicrosite(element.id)"
      >
        <span *ngIf="element.status == '' || element.status == null"> Onboarding </span>
        {{ element.status }}
      </td>
    </ng-container>

    <!-- Last Modified Column -->
    <ng-container matColumnDef="lastPublished">
      <th mat-header-cell *matHeaderCellDef class="table-headers">Last published</th>
      <td mat-cell class="table-cell" *matCellDef="let element" (click)="editMicrosite(element.id)">
        <span *ngIf="element.lastPublishedDate == '' || element.lastPublishedDate == null">
          -
        </span>
        {{ element.lastPublishedDate | date: "MM/d/yyyy" }}
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="table-headers">Actions</th>
      <td align="left" mat-cell class="table-cell actions-cell" *matCellDef="let element">
        <button mat-button [matMenuTriggerFor]="menu">...</button>
        <mat-menu #menu="matMenu">
          <!-- Edit -->
          <button mat-menu-item class="custom-menu-item" (click)="editMicrosite(element.id)">
            Edit
          </button>

          <!-- Copy -->
          <button
            mat-menu-item
            class="custom-menu-item"
            (click)="micrositesService.openCloneMicrositePopup(element.buildingName, element.id)"
          >
            Clone
          </button>

          <!-- Delete -->
          <button
            mat-menu-item
            class="custom-menu-item"
            (click)="confirmDelete(element.id)"
            [disabled]="!isMicrositeRetired(element.status)"
          >
            Delete
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
  </table>

  <div *ngIf="!showMicrositeList" class="empty-microsites-container">
    <div>
      <span class="empty-microsites-message">You haven't created any microsites yet</span>
    </div>
    <div class="create-microsites-button-wrapper">
      <button
        mat-raised-button
        class="create-microsites-button"
        (click)="micrositesService.openAddMicrositePopup()"
      >
        Create microsite
      </button>
    </div>
  </div>
  <mat-paginator
    *ngIf="showMicrositeList"
    [length]="length"
    [pageIndex]="pageNo"
    [pageSize]="pageSize"
    [pageSizeOptions]="[30, 50, 100]"
    (page)="onPagination($event)"
  ></mat-paginator>
</div>
