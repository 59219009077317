<div class="container-fluid">

  <div class="microsite-edit-header">
    <app-microsite-edit-header></app-microsite-edit-header>
  </div>

  <!-- Microsites pages table -->
  <div class="microsite-pages-table">
    <app-microsite-pages-table></app-microsite-pages-table>
  </div>

</div>
