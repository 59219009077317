import { Component, OnInit, OnDestroy } from '@angular/core';
import { MicrositesService } from '../../../../services/microsites.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MicrositePagePublishPopupComponent } from '../../../popups/microsite-page-publish-popup/microsite-page-publish-popup.component';
import { MicrositePageUnpublishPopupComponentComponent } from '../../../popups/microsite-page-unpublish-popup-component/microsite-page-unpublish-popup-component.component';

@Component({
  selector: 'app-microsite-edit-header',
  templateUrl: './microsite-edit-header.component.html',
  styleUrls: ['./microsite-edit-header.component.css'],
})
export class MicrositeEditHeaderComponent implements OnInit, OnDestroy {
  isTitleEditable: Boolean = false;
  isDescriptionEditable: Boolean = false;
  showPublishButton: Boolean = false;
  micrositeTitle: String = '';
  micrositeDescription: String = '';
  micrositeStatus: String = '';
  micrositeId: String = '';

  micrositeEditHeaderDataSource: Subscription;
  private routeSub: Subscription;

  constructor(
    public dialog: MatDialog,
    public micrositesService: MicrositesService,
    public router: Router,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe((params) => {
      this.micrositeId = params['id'];
    });
    this.micrositeEditHeaderDataSource =
      this.micrositesService.micrositeEditHeaderDataSource.subscribe((micrositeData) => {
        this.assignData(micrositeData);
      });
  }

  assignData(micrositeData) {
    this.micrositeTitle = micrositeData.name;
    this.micrositeDescription = micrositeData.description;

    //TODO change this
    if (this.router.url.includes('/pages')) {
      this.micrositeStatus = micrositeData.published ? 'Live' : 'Draft';
    } else {
      this.micrositeStatus = micrositeData.status;
    }

    this.showPublishButton =
      this.router.url.includes('/pages') && this.micrositeStatus.toLowerCase() !== 'live';
  }

  handlePageNavigationClick() {
    if (this.router.url.includes('/pages')) {
      this.router.navigateByUrl('microsites/edit/' + this.micrositeId);
    }
  }

  startMicrositePagePublish() {
    const openSnackbar = this.dialog.open(MicrositePagePublishPopupComponent, {
      data: {
        micrositeId: this.micrositesService.currentMicrositePageData.micrositeId,
        microstePageId: this.micrositesService.currentMicrositePageData.id,
      },
    });
    this.micrositesService.saveMicrositePage(openSnackbar);
  }

  confirmUnpublish() {
    this.dialog.open(MicrositePageUnpublishPopupComponentComponent, {
      data: {
        micrositeId: this.micrositesService.currentMicrositePageData.micrositeId,
        micrositePageId: this.micrositesService.currentMicrositePageData.id,
      },
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.micrositeEditHeaderDataSource.unsubscribe();
  }
}
