import { Component, OnInit } from '@angular/core';

import {FlyerService} from '../../../services/flyer.service';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.css']
})
export class SubHeaderComponent implements OnInit {

  constructor(
    private FlyerService: FlyerService
  ) { 
  }

  ngOnInit(): void { }

  
}