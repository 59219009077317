import { MicrositeCopyPagePopupComponent } from './../../popups/microsite-copy-page-popup/microsite-copy-page-popup.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MicrositesService } from '../../../services/microsites.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MicrositeHomepageConfirmationPopupComponent } from 'src/app/components/popups/microsite-homepage-confirmation-popup/microsite-homepage-confirmation-popup.component';
import { Subscription } from 'rxjs';
import { MicrositePageUnpublishPopupComponentComponent } from '../../popups/microsite-page-unpublish-popup-component/microsite-page-unpublish-popup-component.component';
import { DeletePopupComponent } from '../../popups/delete-popup/delete-popup.component';

interface TemplateDetail {
  dataApiBaseUrl: null;
  dataApiPathId: number;
  description: string;
  id: number;
  micrositeId: number;
  name: string;
  page: string;
  published: boolean;
  templateEngine: string;
}

@Component({
  selector: 'app-microsite-pages-table',
  templateUrl: './microsite-pages-table.component.html',
  styleUrls: ['./microsite-pages-table.component.css'],
})
export class MicrositePagesTableComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource();
  columnsToDisplay: string[] = [
    'pageName',
    'pageDescription',
    'status',
    'lastPublished',
    'actions',
  ];
  currentHomepageId: string = '';
  micrositeDataSource: Subscription;
  showMicrositeList: boolean = true;
  private dialogRef;

  constructor(
    private router: Router,
    public micrositesService: MicrositesService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    /* Retrieve the Microsite pages data list */
    this.micrositeDataSource = this.micrositesService.micrositeDataSourceChanged.subscribe(
      (data) => {
        this.assignData(data);
      }
    );
  }

  assignData(data) {
    let w = <any>window;
    if (data.templates && data.templates.length) {
      this.dataSource.data = this.micrositesService.currentMicrositeData.templates;
      this.showMicrositeList = true;
      w.Events.publish('detectChanges');
    } else {
      this.showMicrositeList = false;
    }
  }

  showHomepageConfirmationPopup(id) {
    this.currentHomepageId = id;
    this.dialogRef = this.dialog.open(MicrositeHomepageConfirmationPopupComponent, {
      data: {
        pageId: id,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event && result.event == 'Confirm') {
        this.onHomepageConfirmation(result.data.pageId);
      }
    });
  }

  onHomepageConfirmation(id) {
    let templateToSetAsHomepage = this.micrositesService.currentMicrositeData.templates.find(
      (element) => element.id == id
    );
    if (typeof templateToSetAsHomepage === 'object' && templateToSetAsHomepage.page) {
      this.micrositesService.currentMicrositeData.homePage = templateToSetAsHomepage.page;
      this.micrositesService
        .updateMicrosite(this.micrositesService.currentMicrositeData)
        .subscribe((data: any) => {
          if (data) {
            this.micrositesService.assignCurrentMicrositeData(data);
            this.micrositesService.showToastMessage(
              'Microsite Page Updated Successfully!',
              'check_circle',
              'success'
            );
          }
        });
    }
  }

  getMicrositePageStatus(element: TemplateDetail) {
    return element.published ? 'Live' : 'Draft';
  }

  navigateToEdit(micrositeId: string) {
    this.router.navigateByUrl('microsites/edit/' + micrositeId);
  }

  confirmUnpublish(element: TemplateDetail) {
    this.dialog.open(MicrositePageUnpublishPopupComponentComponent, {
      data: {
        micrositeId: element.micrositeId,
        micrositePageId: element.id,
        reload: true,
      },
    });
  }

  confirmDeletePage(element: TemplateDetail) {
    this.dialog.open(DeletePopupComponent, {
      data: {
        micrositeId: element.micrositeId,
        micrositePageId: element.id,
        reload: true,
      },
    });
  }

  copyMicrositePage(element: TemplateDetail) {
    this.dialog.open(MicrositeCopyPagePopupComponent, { data: element });
  }

  editMicrositePage(pageId: number) {
    this.router.navigateByUrl(
      'microsites/edit/' + this.micrositesService.currentMicrositeData.id + '/pages/' + pageId
    );
  }

  ngOnDestroy() {
    this.micrositeDataSource && this.micrositeDataSource.unsubscribe();
  }
}
