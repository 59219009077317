import {
  Component,
  OnInit,
  ViewChild,
  Directive,
  HostListener,
  ElementRef,
  Input,
} from '@angular/core';
import 'brace/index';
import 'brace/theme/monokai';
import 'brace/mode/html';
import 'brace/mode/javascript';
import 'brace/mode/text';
import 'brace/ext/searchbox';
import { Renderer2 } from '@angular/core';
import { fromEvent } from 'rxjs';
import { skipUntil, takeUntil } from 'rxjs/operators';
import { WebpagesService } from 'src/app/services/webpages.service';
import { Subscription, Subject } from 'rxjs';
import { FlyerService } from 'src/app/services/flyer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MicrositesService } from 'src/app/services/microsites.service';

const Range = (<any>window).ace.acequire('ace/range').Range;

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.css'],
})
export class TemplateEditorComponent implements OnInit {
  @ViewChild('editor') editor;
  text: string = '';
  options: any = { printMargin: false };
  height: any = 550;
  draggable: any = false;
  sub: any;
  box: any;
  mousedown$;
  mouseup$;
  file: any;
  fileChanged: Subscription;
  notChanged: any = true;
  mode = 'javascript';
  isPublished: any = false;
  templateDetailChanged: any;
  isEditable: boolean = false;
  previousText: any;
  freeMarkerSyntax: any = [
    /\${(.*?)}/g, //Freemarker Syntax 1: '${ **** }'
    /<\#(.*?)>/g, //Freemarker Syntax 2: '<# **** >'
    /<\@(.*?)\/>/g, //Freemarker Syntax 3: '<@ **** />'
    /<\/\#(.*?)>/g, //Freemarker Syntax 4: '</# **** >'
  ];
  flyerTemplate: boolean = false;
  flyerDetailChanged: any;

  micrositePageDetailsChanged: any;
  editWebpage: boolean = false;
  editFlyer: boolean = false;
  editMicrosite: boolean = false;
  micrositeTemplate: boolean = false;

  constructor(
    public renderer: Renderer2,
    public webPageService: WebpagesService,
    public flyerService: FlyerService,
    public micrositesService: MicrositesService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this._router.url.includes('/webpages/edit')) {
      this.editWebpage = true;
    }

    if (this._router.url.includes('/flyers/edit')) {
      this.editFlyer = true;
    }

    if (this._router.url.includes('/microsites/edit')) {
      this.editMicrosite = true;
    }
    this.box = this.renderer.selectRootElement('#smyles_dragbar');
    this.mousedown$ = fromEvent(this.box, 'mousedown');
    this.mouseup$ = fromEvent(document, 'mouseup');
    this.mouseup$.subscribe((_) => {
      this.register();
    });
    this.mousedown$.subscribe((_) => {});
    this.register();
    this.initTemplateEditor();
  }

  initTemplateEditor() {
    if (this.editFlyer) {
      this.flyerTemplate = true;
      this.flyerDetailChanged = this.flyerService.flyerNameChanged.subscribe((flyerDetail) => {
        if (flyerDetail.status == 'PUBLISHED') this.isPublished = true;
      });
      this.fileChanged = this.flyerService.contentOfOpenedFile.subscribe((file) => {
        this.handleFileChange(file);
      });
    }

    if (this.editWebpage) {
      this.templateDetailChanged = this.webPageService.templateNameChanged.subscribe(
        (templateDetail) => {
          if (templateDetail.status == 'PUBLISHED') this.isPublished = true;
        }
      );
      this.fileChanged = this.webPageService.contentOfOpenedFile.subscribe((file) => {
        this.handleFileChange(file);
      });
    }

    if (this.editMicrosite) {
      this.micrositeTemplate = true;
      this.micrositePageDetailsChanged =
        this.micrositesService.micrositePageDataSourceChanged.subscribe((templateDetail) => {
          this.isPublished = templateDetail.published;
        });
      this.fileChanged = this.micrositesService.contentOfOpenedFile.subscribe((file) => {
        this.handleFileChange(file);
      });
    }
  }

  handleFileChange(file) {
    if (this.file && !this.notChanged) {
      this.file.content = this.text;
      if (this.file.s3Url) {
        this.editFlyer && this.flyerService.savePreviousFile(this.file);
        this.editWebpage && this.webPageService.savePreviousFile(this.file);
        this.editMicrosite && this.micrositesService.savePreviousFile(this.file);
      }
    }
    if (file !== null) {
      this.isEditable = true;
      if (file.s3Url && !file.modified) {
        this.editFlyer &&
          this.flyerService
            .getDataS3URL(file.downloadUrl, file.mediaType)
            .subscribe((text) => this.newFileInstance(text, file));
        this.editWebpage &&
          this.webPageService
            .getDataS3URL(file.downloadUrl, file.mediaType)
            .subscribe((text) => this.newFileInstance(text, file));
        this.editMicrosite &&
          this.micrositesService
            .getAssetFile(file.id, file.mediaType)
            .subscribe((text) => this.newFileInstance(text, file));
      } else {
        this.newFileInstance(file.content, file);
      }
    } else {
      this.notChanged = true;
    }
  }

  newFileInstance(text, file) {
    this.file = file;
    this.previousText = JSON.parse(JSON.stringify(text));
    this.text = text;
    //this.editor.getEditor().session.getUndoManager().markClean()
    this.notChanged = true;
  }

  register() {
    try {
      this.sub.unsubscribe();
    } catch (err) {
    } finally {
    }

    let mousemove$ = fromEvent(document, 'mousemove');
    mousemove$ = mousemove$.pipe(skipUntil(this.mousedown$));
    mousemove$ = mousemove$.pipe(takeUntil(this.mouseup$));
    this.sub = mousemove$.subscribe((e: any) => {
      this.height = e.y - (150 - window.scrollY);
      this.editor.getEditor().setOptions({
        maxLines: this.height / 15,
      });
    });
  }

  ngAfterViewInit() {
    this.editor.getEditor().setOptions({
      enableBasicAutocompletion: true,
      useWorker: false,
    });
    this.editor.getEditor().commands.addCommand({
      name: 'showOtherCompletions',
      bindKey: 'Ctrl-.',
      exec: function (editor) {},
    });
  }

  onChange() {
    this.notChanged = this.text == this.previousText; //this.editor.getEditor().session.getUndoManager().isClean()
    if (this.text && !this.notChanged) {
      if (this.editWebpage) {
        this.webPageService.fileModified();
      } else if (this.editFlyer) {
        this.flyerService.fileModified();
      } else {
        this.micrositesService.fileModified();
      }
    }
    this.updateFreeMarkerSyntax();
  }

  updateFreeMarkerSyntax() {
    //Remove existing Free Markers Syntax
    this.removeExistingHighlightedFreeMarkerSyntax();

    //Add Free Markers Syntax
    if (this.freeMarkerSyntax.length) {
      for (var key in this.freeMarkerSyntax) {
        this.highlightFreeMarkerSyntax(this.freeMarkerSyntax[key]);
      }
    }
  }

  removeExistingHighlightedFreeMarkerSyntax() {
    let markers = this.editor.getEditor().session.getMarkers();

    if (markers.constructor === Object && Object.keys(markers).length) {
      for (var key in markers) {
        if (markers[key].type === 'text') {
          this.editor.getEditor().session.removeMarker(markers[key].id);
        }
      }
    }
  }

  highlightFreeMarkerSyntax(regexp) {
    this.editor.getEditor().$search.setOptions({
      needle: regexp,
      regExp: true,
    });

    let matchedElements = this.editor.getEditor().$search.findAll(this.editor.getEditor().session);
    if (matchedElements.length) {
      for (let i = 0; i < matchedElements.length; i++) {
        let range = new Range(
          matchedElements[i].start.row,
          matchedElements[i].start.column,
          matchedElements[i].end.row,
          matchedElements[i].end.column
        );
        let marker = this.editor
          .getEditor()
          .getSession()
          .addMarker(range, 'freemarker-syntax', 'text');
      }
    }
  }

  ngOnDestroy() {}
}
