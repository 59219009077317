import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs/Rx';

import { MatDialog } from '@angular/material/dialog';

import { FlyerAddPopupComponent } from 'src/app/components/popups/flyer-add-popup/flyer-add-popup.component';
import { WebpageAddPopupComponent } from 'src/app/components/popups/webpage-add-popup/webpage-add-popup.component';


@Component({
  selector: 'app-add-template-button',
  templateUrl: './add-template-button.component.html',
  styleUrls: ['./add-template-button.component.css']
})


export class AddTemplateButtonComponent implements OnInit, OnDestroy {

  router: Router;
  path: string;
  routeQueryParams$: Subscription;
  private dialogRef;

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private route: ActivatedRoute
  ) {
    this.router = _router;
    this.routeQueryParams$ = route.queryParams.subscribe(params => {
      if (params['add']) { this.openDialog() }
    });
  }

  ngOnInit(): void {
    this.path = this.route.snapshot.url[0].path
  }

  openDialog() {
    if (this._router.url.includes('/webpages')) {
      this.dialogRef = this.dialog.open(WebpageAddPopupComponent);
    } else if (this._router.url.includes('/flyers')){
      this.dialogRef = this.dialog.open(FlyerAddPopupComponent);
    }

    this.dialogRef.afterClosed().subscribe(result => {
      this.router.navigate([], {
        queryParams: { add: null },
        queryParamsHandling: 'merge'
      })
    });
  }

  openAddDialog() {
    this._router.navigate([], {
      queryParams: { add: true },
      queryParamsHandling: 'merge'
    });
  }

  ngOnDestroy() {
    this.routeQueryParams$.unsubscribe();
  }

  
}
