import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import * as Cookie from 'es-cookie';
import { AppConfigService } from '../services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardGuard implements CanActivate {
  constructor(
    private env: AppConfigService,
    public userService: UserService,
    private router: Router
  ) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (
      this.env.appLoaded &&
      !this.userService.loggedIn &&
      !Cookie.get('propertycapsule-' + this.env.config.name + '.loggedIn')
    ) {
      this.router.navigate(['signin']);
      return false;
    }
    return true;
  }
}
