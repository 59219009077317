import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from 'src/app/services/user.service';
import { SubjectSubscription } from 'rxjs/internal-compatibility';
import { AppConfigService } from 'src/app/services/app-config.service';


@Component({
  selector: 'app-signin-popup',
  templateUrl: './signin-popup.component.html',
  styleUrls: ['./signin-popup.component.css']
})


export class SigninPopupComponent implements OnInit, OnDestroy {

  sigupSigninOptions: any = {}

  updatePopupRouteSubscription: SubjectSubscription<any>;

  constructor(private env: AppConfigService, public router: Router, public userService: UserService) { }

  ngOnInit(): void {
    this.sigupSigninOptions.AUTH_URL= this.env.config.APP.AUTH_URL,
    this.sigupSigninOptions.environmentName= this.env.config.name,
    this.sigupSigninOptions.applicationName= this.env.config.APP.APPLICATION

    this.updatePopupRouteSubscription = this.userService.loginStatusChanged.subscribe(status => {
       // if user is logged in send him to webpages
      if (status) {
        this.router.navigate(["webpages"]);
      }
    });
  }

  ngOnDestroy() {
    this.updatePopupRouteSubscription.unsubscribe();
  } 
}
