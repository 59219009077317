import {Component, OnInit, ViewChild} from '@angular/core';
import {FlyerPropertyStatus, FlyerStatus} from "../../../models/FlyerPropertyStatus";
import {FlyerService} from "../../../services/flyer.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-flyer-status',
  templateUrl: './flyer-status.component.html',
  styleUrls: ['./flyer-status.component.css']
})
export class FlyerStatusComponent implements OnInit {

  properties: FlyerPropertyStatus[] = [];
  errorProperties: FlyerPropertyStatus[];
  private flyerId: number;
  successCount: number;
  processingCount: number;
  pendingCount: number;
  errorCount: number;
  abortedCount: number;
  displayedColumns = ['id', 'propertyName', 'status', 'actions'];
  dataSource: MatTableDataSource<FlyerPropertyStatus>;
  isLoading: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  regeneratingAllFlyers: boolean;
  filterVal: string;

  constructor(public flyerService: FlyerService) {
    this.flyerId = this.flyerService.flyerDetail.flyerId;
  }

  ngOnInit(): void {
    // call the get api
    this.initComponent();

  }

  private initComponent() {
    this.isLoading = true;
    this.properties = [];
    this.successCount = 0;
    this.processingCount = 0;
    this.pendingCount = 0;
    this.errorCount = 0;
    this.abortedCount = 0;
    this.dataSource = null;
    this.flyerService.getFlyerProcessingStatus(this.flyerId).subscribe(res => {
      this.isLoading = false;
      this.properties = res as FlyerPropertyStatus[];
      this.initForProperties();
    });
  }

  private initForProperties() {
    this.dataSource = new MatTableDataSource<FlyerPropertyStatus>(this.properties);
    this.doCountSearch();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter
    this.dataSource.filterPredicate = ((flyerPropertyStatus: FlyerPropertyStatus, filterString: string) => {
      const byName = !!flyerPropertyStatus.propertyName
        && flyerPropertyStatus.propertyName.toLowerCase().includes(filterString.toLowerCase());
      const byStatus = !!flyerPropertyStatus.status
        && FlyerStatus[FlyerStatus[flyerPropertyStatus.status]].toString().toLowerCase().includes(filterString.toLowerCase());
      return byName || byStatus;
    });
  }

  private doCountSearch() {
    this.successCount = this.properties.filter(x => x.status.toString() == FlyerStatus[FlyerStatus.SUCCESSFUL]).length;
    this.processingCount = this.properties.filter(x => x.status.toString() == FlyerStatus[FlyerStatus.PROCESSING]).length;
    this.pendingCount = this.properties.filter(x => x.status.toString() == FlyerStatus[FlyerStatus.PENDING]).length;
    this.errorCount = this.properties.filter(x => x.status.toString() == FlyerStatus[FlyerStatus.ERROR]).length;
    this.abortedCount = this.properties.filter(x => x.status.toString() == FlyerStatus[FlyerStatus.ABORTED]).length;
  }

  applyFilter(value: any) {
    this.dataSource.filter = value;
    this.filterVal = value;
  }

  regenerateFlyer(item: FlyerPropertyStatus) {
    // do the regeneration
    this.flyerService
      .regenerateFlyer(item)
      .subscribe((res: FlyerPropertyStatus) => {
        item.status = res.status;
        this.applyFilter(this.filterVal);
        this.doCountSearch();
        this.flyerService.showToastMessage("Flyer has been sent to Regeneration", "check_circle", "success");
    });
  }

  regenerateFlyers() {
    // TODO @Tino disable it when it is giving error
    this.regeneratingAllFlyers = true;
    this.flyerService
      .regenerateFlyers(this.flyerService.flyerDetail.flyerId)
      .subscribe((res: FlyerPropertyStatus) => {
        this.regeneratingAllFlyers = false;
        this.flyerService.showToastMessage('Flyer Properties has been sent to Regeneration', 'check_circle', 'success');
        this.initComponent();
        this.doCountSearch();
      });
  }

  // will be called on refresh button
  refresh() {
    this.initComponent()
  }
}
