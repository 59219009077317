<mat-tab-group mat-align-tabs="start" *ngIf="router.url.includes('/webpages/edit') && dataLoaded | async" #tabs
    [selectedIndex]="selectedTab" (selectedTabChange)="selectTab($event)">

    <mat-tab label="BASIC">
        <!-- start of basic tab -->
        <div class="container-fluid">

            <!-- name -->
            <div class="row">
                <mat-form-field class="full-width" hintLabel="Max 60 characters" appearance="outline">
                    <mat-label>Webpage Name</mat-label>
                    <input matInput #input [maxLength]="60" [(ngModel)]="this.templateDetail.name"
                        placeholder="Webpage" [disabled]="this.templateDetail.status == 'PUBLISHED'" required
                        (ngModelChange)="setIsValid()">
                    <mat-error *ngIf="this.templateDetail.name.length == 0">
                        <strong>You must enter a name!</strong>
                    </mat-error>
                    <mat-hint align="end">{{input.value?.length || 0}}/60</mat-hint>
                </mat-form-field>
            </div>

            <!-- description  -->
            <div class="row">
                <mat-form-field class="full-width custom-desc" hintLabel="Max 120 characters" appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea matInput #text [maxLength]="120" placeholder="Sample description..."
                        [(ngModel)]="this.templateDetail.description"
                        [disabled]="this.templateDetail.status == 'PUBLISHED'" required
                        (ngModelChange)="setIsValid()"></textarea>
                    <mat-error *ngIf="this.templateDetail.description.length == 0">
                        <strong>You must enter a description!</strong>
                    </mat-error>
                    <mat-hint align="end">{{text.value?.length || 0}}/120</mat-hint>
                </mat-form-field>
            </div>

            <!-- select type of webpage -->
            <div class="row">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Web Page Type</mat-label>
                    <mat-select disableRipple [(ngModel)]="this.templateDetail.type"
                        [disabled]="this.templateDetail.status == 'PUBLISHED'" required (ngModelChange)="setIsValid()">
                        <mat-option value="SEARCH">Search</mat-option>
                        <mat-option value="PDP">PDP</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.templateDetail.type.length == 0">
                        <strong>You must select a type!</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="row">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label class="custom-label">Customer</mat-label>
                    <mat-select disableRipple [(ngModel)]="this.templateDetail.customerGlobalId"
                                required (ngModelChange)="setIsValid()" [disabled]="this.templateDetail.status == 'PUBLISHED'">
                      <mat-option *ngFor="let customer of this.customers"
                                  [value]="customer.customerGlobalId">{{customer.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="!this.templateDetail.customerGlobalId">
                      <strong>You must select a customer!</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- System api base url -->
            <div class="row" *ngIf="this.templateDetail.system == 'VTS'">
                <mat-form-field class="full-width" appearance="outline">
                <mat-label class="custom-label">Api base Url</mat-label>
                <input matInput #input [maxLength]="100" [(ngModel)]="this.templateDetail.apiBaseUrl">
                </mat-form-field>
            </div>

             <div class="row">
                    <mat-checkbox [(ngModel)]="this.templateDetail.useDataFunction">
                        Use Data functions
                    </mat-checkbox>
            </div>

        </div>
        <!-- end of basic tab -->
    </mat-tab>

    <mat-tab label="DETAILS">
        <div class="custom-details">
            <button mat-flat-button (click)="onReplaceURLs()" class="replaceURLs">
                <mat-icon>find_replace</mat-icon>
                Replace Asset Links
            </button>
        </div>

        <!-- start of Details tab -->
        <button mat-icon-button (click)="onUpload()" class="upload-button"  matTooltip="Upload an asset file"
            aria-label="Upload an asset file"
            [disabled]="this.templateDetail.status == 'PUBLISHED'">
            <span class="material-icons">cloud_upload</span>
            Upload Files
        </button>
        <button mat-icon-button (click)="openAddFilePopup()" class="upload-button" matTooltip="Upload a template file"
            aria-label="Upload a template file"
            [disabled]="this.templateDetail.status == 'PUBLISHED'">
            <span class="material-icons">note_add</span>
            Create File
        </button>
        <app-download-assets [source]="templateDetail"></app-download-assets>

        <mat-expansion-panel [expanded]="true" #expansionPanelTemplate>
            <mat-expansion-panel-header class="panel-header" (click)="expandPanel(expansionPanelTemplate, $event)">
                <mat-panel-title>
                    Template Files
                </mat-panel-title>
            </mat-expansion-panel-header>

            <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple"
                class="custom-file-upload" />

            <mat-list>
                <mat-list-item *ngFor="let file of files">
                    <span id="file-label"></span>
                    <h4 class="asset-file" matLine>{{file.data.name}}</h4>
                    <mat-progress-bar [value]="file.progress"></mat-progress-bar>
                </mat-list-item>
            </mat-list>

            <mat-list>
                <mat-list-item *ngFor="let file of templateFiles" (click)="onEdit(file)"
                    [ngClass]="{black: isActive(file.name)}">
                    <span id="file-label"></span>
                    <h4 matLine>
                        <span class="file-logo" [ngSwitch]="file.mediaType">
                            <img *ngSwitchCase="'JS'" src="assets/icons/icons8-javascript-25.png" />
                            <img *ngSwitchCase="'HTML'" src="assets/icons/icons8-html-5-25.png" />
                            <img *ngSwitchCase="'CSS'" src="assets/icons/icons8-css3-25.png" />
                            <img *ngSwitchDefault src="assets/icons/icons8-file-20.png" />
                        </span>
                        {{file.name}}
                    </h4>
                    <span class="deleted" *ngIf="file.deleted === true">Deleted</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                        (click)=" $event.stopPropagation()" [disabled]="this.templateDetail.status == 'PUBLISHED'">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openRenamePopup(file.name)" *ngIf="file.name !== 'main.ftlh'">
                            <mat-icon>autorenew</mat-icon>
                            <span>Rename</span>
                        </button>
                        <button mat-menu-item (click)="copyS3URL(file.downloadUrl)" *ngIf="file.s3Url">
                            <mat-icon>file_copy</mat-icon>
                            <span>Copy URL</span>
                        </button>
                        <button mat-menu-item (click)="openDeletePopup(file)" *ngIf="file.name !== 'main.ftlh'"
                            >
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                        <button mat-menu-item *ngIf="file.deleted === true" (click)="restoreFile(file.name)">
                            <mat-icon>undo</mat-icon>
                            <span>Restore</span>
                        </button>
                    </mat-menu>
                </mat-list-item>
            </mat-list>

        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true" #expansionPanelAssets>
            <mat-expansion-panel-header class="panel-header" (click)="expandPanel(expansionPanelAssets, $event)">
                <mat-panel-title>
                    <span>
                        Asset Files
                    </span>
                </mat-panel-title>

            </mat-expansion-panel-header>



            <mat-list>
                <mat-list-item *ngFor="let file of assetFiles" (click)="onEdit(file)"
                    [ngClass]="{black: isActive(file.name)}">
                    <h4 matLine>
                        <span class="file-logo" [ngSwitch]="file.mediaType">
                            <img *ngSwitchCase="'JS'" src="assets/icons/icons8-javascript-25.png" />
                            <img *ngSwitchCase="'HTML'" src="assets/icons/icons8-html-5-25.png" />
                            <img *ngSwitchCase="'CSS'" src="assets/icons/icons8-css3-25.png" />
                            <img *ngSwitchCase="'JPEG'" src="assets/icons/jpg-icon.png" />
                            <img *ngSwitchCase="'PNG'" src="assets/icons/png-icon.png" />
                            <img *ngSwitchCase="'SVG'" src="assets/icons/svg-icon.png" />
                            <img *ngSwitchCase="'WEBP'" src="assets/icons/webp-icon.png" />
                            <img *ngSwitchDefault src="assets/icons/icons8-file-20.png" />
                        </span>
                        {{file.name}}
                    </h4>
                    <span class="deleted" *ngIf="file.deleted === true">Deleted</span>
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                        (click)=" $event.stopPropagation()" [disabled]="this.templateDetail.status == 'PUBLISHED'">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openRenamePopup(file.name)">
                            <mat-icon>autorenew</mat-icon>
                            <span>Rename</span>
                        </button>
                        <button mat-menu-item (click)="copyS3URL(file.downloadUrl)" *ngIf="file.s3Url">
                            <mat-icon>file_copy</mat-icon>
                            <span>Copy URL</span>
                        </button>
                        <button mat-menu-item (click)="openDeletePopup(file)" *ngIf="file.s3Url">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                        <button mat-menu-item *ngIf="file.deleted === true" (click)="restoreFile(file.name)">
                            <mat-icon>undo</mat-icon>
                            <span>Restore</span>
                        </button>
                    </mat-menu>

                </mat-list-item>
            </mat-list>

        </mat-expansion-panel>

        <!-- end of details tab -->
    </mat-tab>

</mat-tab-group>
