import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

import { WebpagesService } from 'src/app/services/webpages.service';
import { FormControl, Validators } from '@angular/forms';
import { FlyerService } from 'src/app/services/flyer.service';
import { MicrositesService } from 'src/app/services/microsites.service';

@Component({
  selector: 'app-file-rename-popup',
  templateUrl: './file-rename-popup.component.html',
  styleUrls: ['./file-rename-popup.component.css'],
})
export class FileRenamePopupComponent implements OnInit {
  newFileName = new FormControl('');
  duplicateFileName: any = false;

  editWebpage: boolean = false;
  editFlyer: boolean = false;
  editMicrosite: boolean = false;

  constructor(
    public webPageService: WebpagesService,
    public flyerService: FlyerService,
    public micrositeService: MicrositesService,
    private router: Router,

    public dialogRef: MatDialogRef<FileRenamePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('/webpages/edit')) {
      this.editWebpage = true;
    }

    if (this.router.url.includes('/flyers/edit')) {
      this.editFlyer = true;
    }

    if (this.router.url.includes('/microsites/edit')) {
      this.editMicrosite = true;
    }
  }

  renameFile(oldFilename) {
    const fileNameExt = this.newFileName.value.split('.').pop();
    const oldNameExt = oldFilename.split('.').pop();
    if (!this.newFileName.value) {
      this.duplicateFileName = null;
    } else if (fileNameExt !== oldNameExt) {
      this.duplicateFileName = 'incorrectExtension';
      this.newFileName.markAsTouched();
    } else {
      if (this.editWebpage) {
        this.duplicateFileName = this.webPageService.checkDuplicateFilename(this.newFileName.value);
        if (!this.duplicateFileName) {
          this.webPageService.renameFile(this.newFileName.value, oldFilename);
          this.dialog.closeAll();
        } else {
          this.newFileName.setValue('');
        }
      }
      if (this.editFlyer) {
        this.duplicateFileName = this.flyerService.checkDuplicateFilename(this.newFileName.value);
        if (!this.duplicateFileName) {
          this.flyerService.renameFile(this.newFileName.value, oldFilename);
          this.dialog.closeAll();
        } else {
          this.newFileName.setValue('');
        }
      }
      if (this.editMicrosite) {
        this.duplicateFileName = this.micrositeService.checkDuplicateFilename(
          this.newFileName.value
        );
        if (!this.duplicateFileName) {
          this.micrositeService.renameFile(this.newFileName.value, oldFilename);
          this.dialog.closeAll();
        } else {
          this.newFileName.setValue('');
        }
      }
    }
  }
}
