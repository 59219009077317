// Angular imports
import { NgModule } from '@angular/core';

// Material imports
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSliderModule} from '@angular/material/slider';
import {DragDropModule} from '@angular/cdk/drag-drop';


const MaterialComponents = [
  MatPaginatorModule,
  MatMenuModule,
  MatTableModule,
  MatFormFieldModule,
  MatDialogModule,
  MatInputModule,
  MatButtonModule,
  MatSelectModule,
  MatIconModule,
  MatAutocompleteModule,
  MatSnackBarModule,
  MatButtonToggleModule,
  MatToolbarModule,
  MatCheckboxModule,
  MatCardModule,
  MatProgressBarModule,
  MatListModule,
  MatTabsModule,
  MatSortModule,
  MatButtonModule,
  MatInputModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatSliderModule,
  DragDropModule,
];


@NgModule({
  exports: [
    MaterialComponents
  ],
  imports: [
    MaterialComponents
  ]
})

export class MaterialModule { }
