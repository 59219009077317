import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { Router, ActivatedRoute } from '@angular/router'

import { MatDialog } from '@angular/material/dialog';

import {WebsitePreviewPopupComponent} from '../../../popups/website-preview-popup/website-preview-popup.component';


@Component({
  selector: 'app-website-preview-button',
  templateUrl: './website-preview-button.component.html',
  styleUrls: ['./website-preview-button.component.css']
})


export class WebsitePreviewButtonComponent implements OnInit {

  router: Router;
  path: string;
  routeQueryParams$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private route: ActivatedRoute
    ){
    this.router = _router;
    this.routeQueryParams$ = route.queryParams.subscribe(params => {
      if (params['preview']) { this.openDialog() }
    });
  }

  ngOnInit(): void {
    this.path = this.route.snapshot.url[0].path
  }

  openDialog() {
    const dialogRef = this.dialog.open(WebsitePreviewPopupComponent, {
        panelClass: 'custom-modalbox'})
    dialogRef.afterClosed().subscribe(result => {
    this.router.navigate([], {
        queryParams: { preview: null },
        queryParamsHandling: 'merge'
      })
    });
  }

  openPreviewDialog() {
    this._router.navigate([], {
      queryParams: { preview: true },
      queryParamsHandling: 'merge'
    });
  }

  ngOnDestroy() {
    this.routeQueryParams$.unsubscribe();
  }

  
}