import { Component, OnInit } from '@angular/core';
import { MicrositesService } from '../../../services/microsites.service';
import { FormControl, Validators } from '@angular/forms';
import { micrositeData } from 'src/app/models/micrositeData';

@Component({
  selector: 'app-microsite-edit-popup',
  templateUrl: './microsite-edit-popup.component.html',
  styleUrls: ['./microsite-edit-popup.component.css'],
})
export class MicrositeEditPopupComponent implements OnInit {
  /* Form field validations */
  micrositeName = new FormControl('', [Validators.required]);
  micrositeHost = new FormControl('', [Validators.required]);
  micrositeDescription = new FormControl('', [Validators.required]);

  hostname: string = '';
  name: string = '';
  description: string = '';
  status: string = '';

  formData: micrositeData = {
    id: null,
    customerId: '',
    customerName: '',
    buildingName: '',
    buildingId: '',
    hostname: '',
    name: '',
    description: '',
  };

  showDuplicateHostnameErrorMessage: boolean = false;
  duplicateHostnameErrorMessage: string = '';

  constructor(public micrositesService: MicrositesService) {}

  ngOnInit(): void {
    this.assignData(this.micrositesService.currentMicrositeData);
  }

  assignData(currentMicrositeData: any) {
    this.name = currentMicrositeData.name ? currentMicrositeData.name : '';
    this.hostname = currentMicrositeData.hostname ? currentMicrositeData.hostname : '';
    this.description = currentMicrositeData.description ? currentMicrositeData.description : '';
    this.status = currentMicrositeData.status ? currentMicrositeData.status : '';
  }

  onSubmit(form) {
    this.formData = JSON.parse(JSON.stringify(this.micrositesService.currentMicrositeData));
    this.formData.name = this.name;
    this.formData.hostname = this.hostname || null;
    this.formData.description = this.description;
    this.updateCurrentMicrosite(this.formData, form);
  }

  updateCurrentMicrosite(micrositeData, form) {
    this.micrositesService.updateMicrosite(micrositeData).subscribe(
      (data: any) => {
        if (data) {
          this.showDuplicateHostnameErrorMessage = false;
          this.micrositesService.assignCurrentMicrositeData(data);
          this.micrositesService.dialog && this.micrositesService.dialog.closeAll();
          this.micrositesService.showToastMessage(
            'Microsite Page Updated Successfully!',
            'check_circle',
            'success'
          );
        }
      },
      (error) => {
        this.showDuplicateHostnameErrorMessage = true;
        this.duplicateHostnameErrorMessage = error;
        if (form && form.controls) {
          form.form.controls['micrositeHost'].setErrors({ incorrect: true });
        }
      }
    );
  }

  onHostnameValueChange(value) {
    this.hostname = value.toLowerCase();
    this.showDuplicateHostnameErrorMessage = false;
  }
}
