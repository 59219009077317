import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MicrositesService } from '../../../services/microsites.service';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { micrositeData } from 'src/app/models/micrositeData';

@Component({
  selector: 'app-microsite-add-popup',
  templateUrl: './microsite-add-popup.component.html',
  styleUrls: ['./microsite-add-popup.component.css'],
})
export class MicrositeAddPopupComponent implements OnInit {
  // To select customers while filtering data
  customers: any;
  buildings: any;
  selectedCustomer: any;
  selectedBuilding: any;
  showNoBuildingExistMessage: boolean = false;
  showDuplicateHostnameErrorMessage: boolean = false;
  duplicateHostnameErrorMessage: string = '';

  /* Form field validations */
  selectCustomer = new FormControl('', [Validators.required]);
  selectBuilding = new FormControl('', [Validators.required]);
  micrositeUrl = new FormControl('', [Validators.required]);
  micrositeName = new FormControl('', [Validators.required]);
  micrositeDescription = new FormControl('', [Validators.required]);

  formData: micrositeData = {
    id: null,
    customerId: '',
    customerName: '',
    buildingName: '',
    buildingId: '',
    hostname: '',
    name: '',
    description: '',
  };

  constructor(private _router: Router, public micrositesService: MicrositesService) {}

  ngOnInit(): void {
    this.fetchCustomers();
  }

  fetchCustomers() {
    this.micrositesService.getCustomers().subscribe((customers: any) => {
      this.customers = customers;
      this.customers = customers.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  onCustomerChange(event) {
    if (this.buildings) this.buildings = [];
    if (this.selectedBuilding) this.selectedBuilding = [];
    if (event.value.truvaLandlordSlug) {
      this.fetchBuildings(event.value.truvaLandlordSlug);
    }
  }

  fetchBuildings(customerSlug) {
    this.micrositesService.getBuildings(customerSlug, false).subscribe((buildings: any) => {
      if (buildings.length) {
        this.showNoBuildingExistMessage = false;
        this.buildings = buildings;
        this.buildings = this.buildings.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        this.showNoBuildingExistMessage = true;
      }
    });
  }

  onSubmit(form) {
    this.formData.customerId = this.selectedCustomer.id;
    this.formData.customerName = this.selectedCustomer.name;
    this.formData.buildingId = this.selectedBuilding.id;
    this.formData.buildingName = this.selectedBuilding.name;
    this.formData.hostname = this.formData.hostname;
    this.addMicrosite(this.formData, form);
  }

  addMicrosite(micrositeData, form) {
    this.micrositesService.createMicrosite(micrositeData).subscribe(
      (response: any) => {
        if (response) {
          this.showDuplicateHostnameErrorMessage = false;
          this.micrositesService.dialog && this.micrositesService.dialog.closeAll();
          this.micrositesService.showToastMessage(
            'Microsite Added Successfully!',
            'check_circle',
            'success'
          );
          this._router.navigate(['microsites/edit/' + response['id']]);
        }
      },
      (error) => {
        this.showDuplicateHostnameErrorMessage = true;
        this.duplicateHostnameErrorMessage = error;
        if (form && form.controls) {
          form.form.controls['micrositeUrl'].setErrors({ incorrect: true });
        }
      }
    );
  }

  onHostnameValueChange(value) {
    this.formData.hostname = value.toLowerCase();
    this.showDuplicateHostnameErrorMessage = false;
  }
}
