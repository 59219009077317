import { Component, OnInit } from '@angular/core';

import { FlyerService } from 'src/app/services/flyer.service';


@Component({
  selector: 'app-flyer-unpublish-popup',
  templateUrl: './flyer-unpublish-popup.component.html',
  styleUrls: ['./flyer-unpublish-popup.component.css']
})


export class FlyerUnpublishPopupComponent implements OnInit {

  constructor(public FlyerService: FlyerService) { }

  ngOnInit(): void { }

  unPublishFlyers(){
    if (this.FlyerService.flyerStatus == 'PUBLISHED') { 
      this.FlyerService.UnPublishFlyer(this.FlyerService.flyerId);
      this.FlyerService.showToastMessage("Flyer(s) Unpublished Successfully!", "check_circle", 'success');
    } else if (this.FlyerService.flyerStatus == 'DRAFT'){
      this.FlyerService.showToastMessage("You can only unpublish a Published Flyer!", "error", 'warning'); 
    }
  }

  
}
