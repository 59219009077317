import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-microsite-homepage-confirmation-popup',
  templateUrl: './microsite-homepage-confirmation-popup.component.html',
  styleUrls: ['./microsite-homepage-confirmation-popup.component.css'],
})
export class MicrositeHomepageConfirmationPopupComponent implements OnInit {
  data: any;

  constructor(
    public dialogRef: MatDialogRef<MicrositeHomepageConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public pageId: Object
  ) {
    this.data = { ...pageId };
  }

  ngOnInit(): void {}

  onConfirmDialog() {
    this.dialogRef.close({
      event: 'Confirm',
      data: this.data,
    });
  }

  onCloseDialog() {
    this.dialogRef.close({
      event: 'Cancel',
    });
  }
}
