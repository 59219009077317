/* Angular core imports */
import { Injectable } from '@angular/core';
import { Subject, throwError, Observable, forkJoin, Subscription } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/internal/operators/catchError';
import { retry } from 'rxjs/internal/operators/retry';
import { Router } from '@angular/router';

/* Other imports - components and services */
import { IconSnackBarComponent } from '../components/icons-snack-bar/icon-snack-bar.component';
import { flyerDetail } from '../models/FlyerDetail';
import { toFormDataNew } from './convertFormdata';

/* Material imports */
import { MatTableDataSource } from '@angular/material/table';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FlyerPropertyStatus } from '../models/FlyerPropertyStatus';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class FlyerService {
  /* These variables maintain a copy of the datasource which can be shared between multiple components */
  dataSource = new MatTableDataSource();
  sharedDataSource = new MatTableDataSource();
  sharedDataSourceChanged$: any = new Subject<any>();

  /* Attribute information to be shared while editing or deleting an attribute */
  attributeId: any;
  attributeInfo: any;

  /* For Pagination */
  pageIndex: number = 1;
  pageSize: number = 100;
  totalElements: number;
  sharedPageNo$: any = new Subject<any>();
  sharedPageSize$: any = new Subject<any>();
  sharedTotalElements$: any = new Subject<any>();

  /* Positions for displaying snackbar */
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  /* These variables maintain a copy of flyer details for operations like - DELETE, PUBLISH, UNPUBLISH etc. Also used for navigation after creating flyer. */
  templateID: number; //The template Id of the flyer
  flyerId: number; //The FlyerId of the flyer
  flyerVersion: number;
  flyerStatus: string;

  /* Basic Form validation -Edit section */
  basicFormValid: boolean = false;

  /* Details of the template */
  flyerDetail: any;

  /* The basic details from the template details */
  basicDetails: any;

  /* The template files */
  templateFiles: any;

  /* If the template details are changed, then they're tracked here */
  flyerDetailChanged: any = new Subject<any>();

  /* For flyerAttributes */
  flyerAttributesChanged: any = new Subject<any>();

  /* If file is opened in editor */
  fileOpenedInEditor: any;

  /* The contents of opened files */
  contentOfOpenedFile: any = new Subject<any>();

  /* select flyer.ftlh */
  flyerFtlhSelected: any = new Subject<any>();

  /* The newly uploaded asset files */
  newAssetFiles: any = [];

  /* check the changed template name */
  flyerNameChanged: any = new Subject<any>();

  /* Flyer Saved */
  flyerSaved: any = new Subject<any>();

  /*The data fetched for the flyer using parameters */
  data: any;

  /* Check if the files are modified or not */
  filesModified: any = false;

  /* back up of the data for the fetched flyer */
  backupData: any;

  /* Boolean for url's replaced */
  URLsReplaced: boolean = true;

  /* Properties linked to the Flyer */
  linkedProperties: any = new Subject<any>();
  linkedpropertiesList: any;
  flyerFiles: any;
  flyerAttributes: any;
  flyerAttributesModified: boolean = false;

  /* If linked properties are modified */
  linkedPropertiesModified: boolean = false;

  /* For flyer preview */
  flyerSavedForPreview: any = new Subject<any>();
  isFlyerBeingPreviewed: Boolean = false;

  /* Property global Id for HTML preview */
  propertyGlobalId: any;

  /* Newly linked properties to the flyer */
  newSelectedPropertiesList: any = [];

  /* Remove linked properties array */
  removePropertyLinkList: any = [];

  /* Removed and Newly linked properties array when attachToNewProperties is true initially */
  removedPropertyLinkList: any = [];
  newlySelectedPropertiesList: any = [];

  /* Fetch status enabled properties */
  statusEnabledProperties: any = [];
  statusEnabledLinkedProperties: any = new Subject<any>();

  /* To check for duplicate attribute name */
  duplicateAttribute: boolean;

  /* Disable check incase of all properties */
  selectAllCheck: boolean = false;

  /* Disable save if no properties are selected */
  notSave: boolean = false;

  attachToAllProps: boolean = false;

  constructor(
    private env: AppConfigService,
    private http: HttpClient,
    public router: Router,
    private _snackBar: MatSnackBar
  ) {}

  /* Http Options */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    withCredentials: true,
  };

  /* Handle API errors */
  errorHandler(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = error.error.message;
      console.error('Client side error occurred:', errorMessage);
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong.
      errorMessage = `Backend returned code: ${error.status}, ` + `Error message: ${error.error}`;
      console.error('Backend error message: ', errorMessage);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
    // return throwError(errorMessage);
  }

  // ******************* POST calls ************************

  /* To create a new flyer */
  createFlyer(flyer) {
    return this.http
      .post(
        this.env.config.APP.FLYER_API_URL + '/template',
        JSON.stringify(flyer),
        this.httpOptions
      )
      .pipe(retry(2), catchError(this.errorHandler));
  }

  /* Copy flyer by Id and version */
  copyFlyer(id, version) {
    return this.http
      .post(
        this.env.config.APP.FLYER_API_URL + '/template/' + id + '/version/' + version + '/copy',
        this.httpOptions
      )
      .pipe(retry(2), catchError(this.errorHandler));
  }

  /* Publish a single flyer by flyerId */
  publishFlyer(flyerId) {
    return this.http
      .post(this.env.config.APP.FLYER_API_URL + '/flyer/' + flyerId + '/publish', '', {
        responseType: 'text',
      })
      .subscribe(() => {
        if (this.router.url.includes('/flyers/edit')) {
          this.getFlyerDetails(this.flyerDetail.id, this.flyerDetail.version);
        } else {
          this.loadFlyers();
        }
      });
  }

  /* Unpublish a single flyer by flyerId */
  UnPublishFlyer(flyerID) {
    return this.http
      .post(this.env.config.APP.FLYER_API_URL + '/flyer/' + flyerID + '/unpublish', '', {
        responseType: 'text',
      })
      .subscribe(() => {
        this.loadFlyers();
      });
  }

  // ******************* GET calls ************************

  /* To retrieve a list of existing customers */
  getCustomers(system = 'PC') {
    return this.http
      .get(this.env.config.APP.FLYER_API_URL + '/customer' + (system ? '?system=' + system : ''))
      .pipe(retry(2), catchError(this.errorHandler));
  }

  /* To retrieve a list of existing properties of the customers */
  getProperties(customerGlobalId) {
    return this.http
      .get(this.env.config.APP.FLYER_API_URL + '/customer/' + customerGlobalId + '/properties')
      .pipe(retry(2), catchError(this.errorHandler));
  }

  /* To retreive a list of existing flyers */
  getFlyers(pgSize) {
    let pageSize = pgSize.toString();
    return this.http
      .get(this.env.config.APP.FLYER_API_URL + '/template', {
        params: new HttpParams().set('pageSize', pageSize),
      })
      .pipe(retry(2), catchError(this.errorHandler));
  }

  /* To filters flyers by customer name and flyer template status */
  getFlyersByParams(customerName, templateStatus, pgSize) {
    let pageSize = pgSize.toString();
    return this.http.get(this.env.config.APP.FLYER_API_URL + '/template', {
      params: new HttpParams()
        .set('customerName', customerName)
        .set('templateStatus', templateStatus)
        .set('pageSize', pageSize),
    });
  }

  /* To retreive flyers with pagination parameters */
  getFlyersWithPagination(pageNo, pgSize, customerName, templateStatus) {
    let pageIndex = pageNo.toString();
    let pageSize = pgSize.toString();
    return this.http
      .get(this.env.config.APP.FLYER_API_URL + '/template', {
        params: new HttpParams()
          .set('pageNo', pageIndex)
          .set('pageSize', pageSize)
          .set('customerName', customerName)
          .set('templateStatus', templateStatus),
      })
      .pipe(retry(2), catchError(this.errorHandler));
  }

  /* To retrieve single flyer using Id and version */
  getFlyer(id, version): Observable<flyerDetail> {
    return this.http.get<flyerDetail>(
      this.env.config.APP.FLYER_API_URL + '/template/' + id + '/version/' + version
    );
  }

  /* To retreive a list of properties linked to the flyer */
  getLinkedProperties(flyerId) {
    return this.http
      .get(this.env.config.APP.FLYER_API_URL + '/flyer/' + flyerId + '/property/link/')
      .pipe(retry(2), catchError(this.errorHandler));
  }

  /* To retreive a list of attributes associated with the flyer */
  getFlyerAttributes(flyerId) {
    return this.http
      .get(this.env.config.APP.FLYER_API_URL + '/flyer/' + flyerId + '/attribute')
      .pipe(retry(2), catchError(this.errorHandler));
  }

  /* To retreive the HTML response */
  previewHTML(propertyGlobalId, flyerId) {
    return this.http.get(
      this.env.config.APP.FLYER_API_URL +
        '/render/flyer/html/preview' ,
        { 
          params: new HttpParams().set('propertyGlobalId', propertyGlobalId).set('flyerId', flyerId),
          responseType: 'text'
        }
    );
  }

  /* To render flyer PDF */
  renderFlyerPDF(propertyGlobalId, flyerId) {
    return this.http.get(this.env.config.APP.FLYER_API_URL + '/render/flyer/pdf/preview', {
      params: new HttpParams().set('propertyGlobalId', propertyGlobalId).set('flyerId', flyerId),
    });
  }

  /* Polling - To generate flyer PDF link */
  flyerGenerationStatus(requestId) {
    return this.http.get(this.env.config.APP.FLYER_API_URL + '/render/flyer/status', {
      params: new HttpParams().set('requestId', requestId),
    });
  }

  /* To retreive the flyer PDF link after polling returns success status */
  flyerPDFLink(propertyGlobalId, flyerId) {
    return this.http.get(this.env.config.APP.FLYER_API_URL + '/flyer/pdf/download/url', {
      params: new HttpParams()
        .set('propertyGlobalId', propertyGlobalId)
        .set('flyerId', flyerId)
        .set('preview', 'true'),
    });
  }

  // ******************* PUT calls ************************

  /* To link properties to a newly created flyer */
  linkProperties(flyerId, properties) {
    return this.http
      .put(
        this.env.config.APP.FLYER_API_URL + '/flyer/' + flyerId + '/property/link/bulk/',
        properties
      )
      .pipe(retry(2), catchError(this.errorHandler));
  }

  /* To update a single flyer by Id, version and flyer info */
  updateFlyer(id, version, flyerInfo) {
    return this.http
      .put(this.env.config.APP.FLYER_API_URL + '/template/' + id + '/version/' + version, flyerInfo)
      .pipe(retry(2), catchError(this.errorHandler));
  }

  /* To update properties linked to the flyer */
  updateLinkedProperties(flyerId, propertiesToLink) {
    return this.http
      .put(
        this.env.config.APP.FLYER_API_URL + '/flyer/' + flyerId + '/property/link/bulk/',
        propertiesToLink
      )
      .pipe(retry(2), catchError(this.errorHandler));
  }

  /* To create or update a flyer attributes */
  updateAttributes(flyerId, attributes) {
    return this.http
      .put(this.env.config.APP.FLYER_API_URL + '/flyer/' + flyerId + '/attribute/bulk/', attributes)
      .pipe(retry(2), catchError(this.errorHandler));
  }

  // ******************* DELETE calls ************************

  /* To delete a single flyer */
  deleteFlyer(id, version) {
    return this.http
      .delete(this.env.config.APP.FLYER_API_URL + '/template/' + id + '/version/' + version, {
        responseType: 'text',
      })
      .subscribe(() => {
        this.loadFlyers();
      });
  }

  /* To delete flyer attributes */
  deleteFlyerAttribute(flyerid, attributeId) {
    return this.http
      .delete(
        this.env.config.APP.FLYER_API_URL + '/flyer/' + flyerid + '/attribute/' + attributeId,
        { responseType: 'text' }
      )
      .subscribe(() => {
        this.getFlyerAttributes(flyerid).subscribe((attributes) => {
          this.flyerAttributesChanged.next(attributes);
          this.showToastMessage('Attribute Deleted Successfully!', 'delete', 'danger');
        });
      });
  }

  // ******************* OTHER FUNCTIONS ************************

  /* To store data in central location */
  loadFlyers() {
    this.getFlyers(100).subscribe((response: any) => {
      this.dataSource.data = response.contents;
      this.pageIndex = response.pageNo;
      this.pageSize = response.pageSize;
      this.totalElements = response.totalElements;

      this.sharedDataSourceChanged$.next(this.dataSource.data);
      this.sharedPageNo$.next(this.pageIndex);
      this.sharedPageSize$.next(this.pageSize);
      this.sharedTotalElements$.next(this.totalElements);
    });
  }

  /* To filter flyers in services itself! */
  filterFlyers(customer, status) {
    this.getFlyersByParams(customer, status, 100).subscribe((response: any) => {
      this.dataSource.data = response.contents;
      this.pageIndex = response.pageNo;
      this.pageSize = response.pageSize;
      this.totalElements = response.totalElements;

      this.sharedDataSourceChanged$.next(this.dataSource.data);
      this.sharedPageNo$.next(this.pageIndex);
      this.sharedPageSize$.next(this.pageSize);
      this.sharedTotalElements$.next(this.totalElements);
    });
  }

  /* To fetch flyers with pagination requirements */
  loadFlyersOnPagination(pageNo, pageSize, templateStatus, customerName) {
    this.getFlyersWithPagination(pageNo, pageSize, customerName, templateStatus).subscribe(
      (response: any) => {
        this.dataSource.data = response.contents;
        this.pageIndex = response.pageNo;
        this.pageSize = response.pageSize;
        this.totalElements = response.totalElements;

        this.sharedDataSourceChanged$.next(this.dataSource.data);
        this.sharedPageNo$.next(this.pageIndex);
        this.sharedPageSize$.next(this.pageSize);
        this.sharedTotalElements$.next(this.totalElements);
      }
    );
  }

  /* To fetch flyer details and store it in services for easy retrieval */
  getFlyerDetails(templateId, templateVersion) {
    if (templateId && templateVersion) {
      this.getFlyer(templateId, templateVersion).subscribe(
        (data) => {
          this.assignData(data);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          if (error.status === 404) {
            this.showToastMessage('Oops! Template not found. Redirecting in 3s', 'error', 'danger');

            setTimeout(() => {
              this.router.navigate(['flyers']);
            }, 3000);
          }
        }
      );
    }
  }

  /* To fetch the list of properties linked to the flyer and store it in services for easy retrieval */
  getLinkedPropertiesDetails(flyerId) {
    this.getLinkedProperties(flyerId).subscribe((data) => {
      this.setLinkedProperties(data);
    });
  }

  /* All toast messages */
  showToastMessage(message, icon, classname) {
    this._snackBar.openFromComponent(IconSnackBarComponent, {
      data: {
        message: message,
        icon: icon,
      },
      duration: 2000,
      panelClass: classname,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  /* To fetch template details on the edit page */
  assignData(data) {
    // console.log('fetching Flyer details: ', data);
    this.flyerDetail = data;

    this.flyerId = data.id;
    this.flyerVersion = data.version;

    var { files, flyerAttributes, ...basicDetails } = this.flyerDetail;
    this.basicDetails = basicDetails;
    this.flyerFiles = files;
    this.flyerAttributes = flyerAttributes;

    this.flyerDetailChanged.next(this.flyerDetail);
    this.flyerNameChanged.next(this.basicDetails);
    this.flyerAttributesChanged.next(this.flyerAttributes);

    this.getLinkedProperties(data.flyerId).subscribe((data) => {
      this.setLinkedProperties(data);
      this.statusEnabledProperties = this.linkedpropertiesList.filter(
        (linkedProperty) => linkedProperty.status == 'ENABLED'
      );
      this.statusEnabledLinkedProperties.next(this.statusEnabledProperties);

      if (
        this.basicDetails.attachToNewProperties ||
        this.linkedpropertiesList.length == this.statusEnabledProperties.length
      ) {
        this.selectAllCheck = true;
      }
    });

    this.backupData = data;
  }

  /* To rename a file on the edit page */
  renameFile(newFileName, oldFilename) {
    this.filesModified = true;
    const fileIndex = this.flyerDetail.files.findIndex((file) => file.name == oldFilename);
    if (!this.flyerDetail.files[fileIndex].modified) {
      this.getDataS3URL(
        this.flyerDetail.files[fileIndex].downloadUrl,
        this.flyerDetail.files[fileIndex].mediaType
      ).subscribe((content) => {
        this.addContent(this.flyerDetail.files[fileIndex], content);
        this.flyerDetail.files[fileIndex].name = newFileName;
        this.flyerDetail.files[fileIndex].modified = true;
      });
    } else {
      this.addContent(this.flyerDetail.files[fileIndex], this.flyerDetail.files[fileIndex].content);
      this.flyerDetail.files[fileIndex].name = newFileName;
      this.flyerDetail.files[fileIndex].modified = true;
    }
  }

  /* Flag to check if files are modified or not */
  fileModified() {
    this.filesModified = true;
  }

  /* To save previous file */
  savePreviousFile(prevFile) {
    console.log('save previous file called this.filesModified = true');
    this.filesModified = true;
    const fileIndex = this.flyerDetail.files.findIndex((file) => file.name == prevFile.name);
    this.flyerDetail.files[fileIndex].content = prevFile.content;
    this.flyerDetail.files[fileIndex].modified = true;
  }

  /* To open any file in editor */
  openFileInEditor(filename) {
    this.fileOpenedInEditor = filename;
    const file = this.flyerDetail.files.filter((file) => file.name == this.fileOpenedInEditor);
    this.contentOfOpenedFile.next(file[0]);
  }

  /* To get the MIME type for sorting files */
  getMIMEType(extension) {
    const extToMimes = {
      html: 'text/html',
      ftlh: 'text/plain',
      ftl: 'text/plain',
      css: 'text/css',
      js: 'text/javascript',
      gif: 'image/gif',
      jpg: 'image/jpeg',
      png: 'image/png',
      svg: 'image/svg+xml',
      bmp: 'image/bmp',
      ico: 'image/x-icon',
      tif: 'image/tiff',
      ttf: 'font/ttf',
      woff: 'font/woff',
      otf: 'font/otf',
      json: 'application/json',
      eot: 'application/vnd.ms-fontobject',
      pdf: 'application/pdf',
    };
    if (extToMimes.hasOwnProperty(extension)) {
      return extToMimes[extension];
    }
    return false;
  }

  /* To check if flyer details or linked properties are modified or not */
  isModified() {
    if (!this.flyerDetail) {
      return false;
    }
    if (this.linkedPropertiesModified) {
      return true;
    }
    if (this.filesModified) {
      return true;
    } else {
      var { files, flyerAttributes, ...basicDetails } = this.flyerDetail;
      if (JSON.stringify(basicDetails) === JSON.stringify(this.basicDetails)) {
        return false;
      } else {
        return true;
      }
    }
  }

  /* To check if the file type is valid or invalid */
  isFileTypeValid(filename) {
    const fileName = filename.split('.');
    const extension = fileName[fileName.length - 1];
    const validFiles = [
      'pdf',
      'json',
      'eot',
      'woff2',
      'gif',
      'jpg',
      'jpeg',
      'png',
      'svg',
      'bmp',
      'ico',
      'html',
      'ftlh',
      'ftl',
      'css',
      'js',
      'tif',
      'tiff',
      'ttf',
      'woff',
      'otf',
    ];
    if (validFiles.includes(extension)) return true;
    else return false;
  }

  /* To retrieve the media type */
  getMediaType(extension) {
    const extToMediaType = {
      html: 'HTML',
      ftlh: 'TEXT_PLAIN',
      ftl: 'TEXT_PLAIN',
      css: 'CSS',
      js: 'JS',
    };
    if (extToMediaType.hasOwnProperty(extension)) {
      return extToMediaType[extension];
    }
    return false;
  }

  /* To create a file */
  createFile(filename) {
    this.filesModified = true;
    const fileName = filename.split('.');
    const mediaType = this.getMediaType(fileName[fileName.length - 1]);
    this.flyerDetail.files.push({
      id: 0,
      name: filename,
      mediaType: mediaType,
      content: '',
      modified: true,
    });
    this.flyerDetailChanged.next(this.flyerDetail);
  }

  /* To check for duplicate file name */
  checkDuplicateFilename(fileName) {
    if (this.flyerDetail.files.some((file) => file.name === fileName)) {
      return true;
    } else {
      return false;
    }
  }

  hasWhiteSpace(fileName) {
    if (fileName.indexOf(' ') >= 0) {
      return true;
    } else {
      return false;
    }
  }

  /* To check for duplicate attribute name */
  checkDuplicateAttributeName(atttributeName, flyerId) {
    this.getFlyerAttributeDetails(flyerId);

    if (this.flyerDetail.flyerAttributes.some((attribute) => attribute.name === atttributeName)) {
      return true;
    } else {
      return false;
    }
  }

  /* To fetch attribute details */
  getFlyerAttributeDetails(flyerId) {
    this.getFlyerAttributes(flyerId).subscribe((attributes) => {
      this.flyerAttributesChanged.next(attributes);
      this.flyerDetail.flyerAttributes = attributes;
    });
  }

  /* To Add new asset file to the existing flyer */
  addNewAssetFile(file) {
    this.filesModified = true;
    this.newAssetFiles.push({ id: 0, multipartFile: file });
    this.flyerDetailChanged.next(this.flyerDetail);
  }

  /* While saving a flyer */
  async saveFlyer(publish) {
    // console.log('Inside saveFlyer function');
    // console.log('this.newSelectedPropertiesList', this.newSelectedPropertiesList);
    // console.log('this.removePropertyLinkList', this.removePropertyLinkList);
    // return;

    this.contentOfOpenedFile.next(null);
    this.filesModified = false;
    this.linkedPropertiesModified = false;
    this.backupData = JSON.parse(JSON.stringify(this.flyerDetail));
    var { files, flyerAttributes, ...basicDetails } = this.flyerDetail;
    const flyerDetail = { ...basicDetails };

    // console.log("this.attachToAllProps", this.attachToAllProps);

    if (this.attachToAllProps == false) {
      flyerDetail.attachToNewProperties = false;
    } else if (this.attachToAllProps == true) {
      flyerDetail.attachToNewProperties = true;
    }

    // console.log("flyerDetail.attachToNewProperties", flyerDetail.attachToNewProperties);

    // to update flyer attributes
    if (this.flyerAttributes) {
      const attributes = await this.updateAttributes(
        this.flyerDetail.flyerId,
        this.flyerAttributes
      ).toPromise();
      this.flyerDetail.flyerAttributes = attributes;
    }

    // check if the files has content or not? If not, raise an alert.
    const fileWithoutContent = [];
    this.flyerDetail.files.forEach((file) => {
      if (file.modified && file.content == '') {
        fileWithoutContent.push(file.name);
      }
    });

    if (fileWithoutContent.length > 0) {
      alert(
        'Files cannot be empty : \n ' + fileWithoutContent.map((el, i) => ++i + '. ' + el + '\n')
      );
      return false;
    }

    let updatedFiles = this.flyerDetail.files.filter((file) => file.modified == true);
    updatedFiles = JSON.parse(JSON.stringify(updatedFiles));
    updatedFiles.forEach((newFile) => {
      const fileName = newFile.name.split('.');
      const MIMEType = this.getMIMEType(fileName[fileName.length - 1]);
      var file = new File([newFile.content], newFile.name, { type: MIMEType });
      delete newFile.content;
      delete newFile.mediaType;
      delete newFile.modified;
      delete newFile.name;
      delete newFile.s3Url;
      delete newFile.fileSize;
      delete newFile.textFile;
      delete newFile.downloadUrl;
      delete newFile.deleted;
      newFile.multipartFile = file;
    });

    flyerDetail.files = [];
    if (updatedFiles.length > 0) updatedFiles.map((file) => flyerDetail.files.push(file));

    if (this.newAssetFiles.length > 0)
      this.newAssetFiles.map((file) => {
        if (!file.s3Url) {
          flyerDetail.files.push(file);
        }
      });

    delete flyerDetail.lastModifiedDate;
    delete flyerDetail.customerGlobalId;
    delete flyerDetail.customerName;
    delete flyerDetail.deleted;

    // console.log("FD", flyerDetail);

    this.updateFlyer(this.flyerId, this.flyerVersion, toFormDataNew(flyerDetail)).subscribe(
      (data) => {
        if (publish !== null) {
          if (typeof publish === 'object') {
            publish;
          }
          if (typeof publish === 'string' && publish === 'get-preview-for-flyer') {
            this.flyerSavedForPreview.next(true);
          }
        } else {
          this.showToastMessage('Flyer Updated Successfully!', 'check_circle', 'success');
        }

        if (
          this.basicFormValid === true &&
          (this.newSelectedPropertiesList.length > 0 || this.removePropertyLinkList.length > 0) &&
          this.attachToAllProps === false
        ) {
          console.log('linked properties', this.newSelectedPropertiesList);
          console.log('unlinked properties', this.removePropertyLinkList);
          this.updateLinkedProperties(this.flyerDetail.flyerId, [
            ...this.newSelectedPropertiesList,
            ...this.removePropertyLinkList,
          ]).subscribe((data1) => {
            this.assignData(data);
            this.setremovePropertyLinkList([]);
            this.setnewSelectedPropertiesList([]);
          });
        } else {
          this.assignData(data);
        }

        this.flyerSaved.next(true);
        this.newAssetFiles = [];
        this.filesModified = false;

        if (this.fileOpenedInEditor) this.openFileInEditor(this.fileOpenedInEditor);
      },
      (err) => {
        if (!publish) {
          this.showToastMessage('Non unique slug!', 'error', 'danger');
        }
        this.assignData(this.backupData);
        this.filesModified = true;
      }
    );
  }

  /* To get S3 URL */
  getDataS3URL(s3Url, mediaType) {
    if (
      mediaType == 'CSS' ||
      mediaType == 'JS' ||
      mediaType == 'HTML' ||
      mediaType == 'TEXT_PLAIN' ||
      mediaType == 'JSON'
    ) {
      return this.http.get(s3Url, { responseType: 'text' });
    } else {
      const headers = new HttpHeaders();
      headers.set('Accept', this.getMIMEType(mediaType.toLowerCase()));
      return this.http.get(s3Url, { headers, responseType: 'blob' as 'json' });
    }
  }

  /* To check if file type is valid or create */
  isFileTypeValidToCreate(filename) {
    const fileName = filename.split('.');
    const extension = fileName[fileName.length - 1];
    const validFiles = ['html', 'ftlh', 'ftl', 'css', 'js'];
    if (validFiles.includes(extension)) return true;
    else return false;
  }

  /* To add content to the file */
  addContent(file, content) {
    file.content = content;
  }

  /* To delete a file */
  deleteFile(fileId) {
    return this.http
      .delete(
        this.env.config.APP.FLYER_API_URL +
          '/template/' +
          this.flyerDetail.id +
          '/version/' +
          this.flyerDetail.version +
          '/file/' +
          fileId,
        { responseType: 'text' }
      )
      .subscribe(() => {
        const fileDeleted = this.flyerDetail.files.filter((file) => file.id == fileId);
        const newFiles = this.flyerDetail.files.filter((file) => file.id !== fileId);
        this.flyerDetail.files = newFiles;
        this.assignData(this.flyerDetail);
        if (fileDeleted[0].name == this.fileOpenedInEditor) {
          this.flyerFtlhSelected.next(true);
          this.openFileInEditor('flyer.ftlh');
        }
        this.showToastMessage('File Deleted SuccessFully', 'delete', 'danger');
      });
  }

  /* To restore a file */
  restoreFile(fileName) {
    const fileIndex = this.flyerDetail.files.findIndex((file) => file.name == fileName);
    this.flyerDetail.files[fileIndex].deleted = false;
  }

  /* To replace asset URLs */
  replaceAssetUrls() {
    this.contentOfOpenedFile.next(null);
    var { files, flyerattributes, ...basicDetails } = this.flyerDetail;
    const missingFiles = [];
    let observablesArray = [];
    let assetFiles = [];
    this.URLsReplaced = false;
    this.flyerDetail.files.forEach((file, index) => {
      if (
        file.mediaType == 'TEXT_PLAIN' ||
        file.mediaType == 'JS' ||
        file.mediaType == 'CSS' ||
        file.mediaType == 'HTML'
      ) {
        if (file.s3Url && !file.modified) {
          observablesArray.push(this.getDataS3URL(file.downloadUrl, file.mediaType));
          assetFiles.push(file);
        }
      }
    });
    forkJoin(...observablesArray).subscribe(
      (data) => {
        for (let i = 0; i < data.length; i++) {
          let assetFileData = data[i];
          var href = /href="(.*?)"/g;
          var src = /src="(.*?)"/g;
          var url = /url\((?!['"]?(?:data:|https?:|\/\/))(['"]?)([^'")]*)\1\)/g;
          var match;
          while (
            (match =
              href.exec(assetFileData.toString()) ||
              src.exec(assetFileData.toString()) ||
              url.exec(assetFileData.toString()))
          ) {
            const assetName = match[2] ? match[2].split('/').pop() : match[1].split('/').pop();
            const toReplace = match[2] ? match[2] : match[1];
            if (!assetName.includes('{')) {
              const fileToReplace = this.flyerDetail.files.filter((file) => {
                if (assetName.includes(file.name)) return file;
              });
              if (fileToReplace.length == 0) {
                continue;
              }
              var outputString;
              if (
                assetFiles[i].mediaType == 'JS' ||
                assetFiles[i].mediaType == 'CSS' ||
                assetFiles[i].mediaType == 'HTML'
              ) {
                outputString = "${getFileUrl('" + fileToReplace[0].name + "')}";
              } else if (assetFiles[i].mediaType == 'TEXT_PLAIN') {
                outputString = "${getFileUrl('" + fileToReplace[0].name + "')}";
              }
              assetFileData = assetFileData.toString().replace(toReplace, outputString);
              assetFiles[i].modified = true;
              this.filesModified = true;
              this.addContent(assetFiles[i], assetFileData);
            }
          }
        }
        this.URLsReplaced = true;
        if (this.fileOpenedInEditor) this.openFileInEditor(this.fileOpenedInEditor);
        this.showToastMessage('URLs Replaced Successfully!', 'check_circle', 'success');
      },
      () => {
        this.URLsReplaced = true;
        this.showToastMessage('Some Error Occured', 'close', 'danger');
      }
    );
  }

  setnewSelectedPropertiesList(value) {
    this.newSelectedPropertiesList = value;
  }

  setremovePropertyLinkList(value) {
    this.removePropertyLinkList = value;
  }

  setLinkedProperties(data: any) {
    // console.log('Setting data', data);

    this.linkedpropertiesList = data
      .sort((a: any, b: any) => {
        let avalue = a.status == 'ENABLED' ? 1 : 0;
        let bvalue = b.status == 'ENABLED' ? 1 : 0;
        return bvalue - avalue;
      })
      .filter((item) => item.propertyGlobalId)
      .map((item) => {
        item.isSelected = item.status === 'ENABLED';
        return item;
      });
    // console.log('Setting data 1', this.linkedpropertiesList);
    this.linkedProperties.next([...this.linkedpropertiesList]);
  }

  getFlyerProcessingStatus(flyerId: number) {
    return this.http
      .get(this.env.config.APP.FLYER_API_URL + '/flyer/' + flyerId + '/status', {})
      .pipe(catchError(this.errorHandler));
  }

  regenerateFlyer(item: FlyerPropertyStatus) {
    return this.http
      .get(this.env.config.APP.FLYER_API_URL + '/render/flyer/pdf', {
        params: {
          propertyGlobalId: item.propertyGlobalId,
          flyerId: item.flyerId,
        },
      })
      .pipe(catchError(this.errorHandler));
  }

  regenerateFlyers(flyerId) {
    return this.http
      .post(this.env.config.APP.FLYER_API_URL + '/flyer/pdf/regenerate', null, {
        params: {
          flyerId: flyerId,
        },
      })
      .pipe(catchError(this.errorHandler));
  }
}
