import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import {WebpagesService} from '../../../services/webpages.service';


@Component({
  selector: 'app-website-preview-popup',
  templateUrl: './website-preview-popup.component.html',
  styleUrls: ['./website-preview-popup.component.css']
})


export class WebsitePreviewPopupComponent implements OnInit {

  /* To show preview link */
  showLink: boolean = false;
  link:string;

  /* To select customers, search and property detail pages for dynamic preview URL */
  public customers: any;
  public searchPages: any;
  public propertyDetailPages: any;

  /* Selected customer, search and property detail page */
  selectedCustomer:any;
  selectedSearchPage:any;
  selectedPDP:any;

  /* Query params for preview link generation */
  customerGlobalId:any;
  pdpTemplateId:any;
  pdpTemplateVersionId:any;
  searchTemplateId:any;
  searchTemplateVersionId:any;

  /* To get the total number of templates - required for sending as pagesize as the default number of templates returned is 30 */
  pgTotalElements: Subscription;
  
  
  constructor(
    public WebpagesService: WebpagesService 
  ) { }
  
  ngOnInit(): void {

    /* Customers dropdown */
    this.WebpagesService.getCustomers().subscribe(res => { 
      this.customers = res 
      this.customers = this.customers.sort((a, b) => a.name.localeCompare(b.name))
    });

    /* Property Detail pages dropdown */
    this.WebpagesService.getTemplatesByType('PDP','', '100000000').subscribe(data => { this.propertyDetailPages = data['contents'] });
    
    /* Search pages dropdown */
    this.WebpagesService.getTemplatesByType('SEARCH', '', '100000000').subscribe(res => { this.searchPages = res['contents'] });

  }

  /* Select respective templates for the selected customer */
  selectPages(){
    let customer = this.selectedCustomer.name;
    this.getPropertyDetailPages(customer);
    this.getSearchPages(customer)
  }

  /* Select PDP templates for the selected customer */
  getPropertyDetailPages(customer){
    this.WebpagesService.getTemplatesByType('PDP', customer , '100000000').subscribe(data => { this.propertyDetailPages = data['contents'] });
  }

  /* Select Search templates for the selected customer */
  getSearchPages(customer){
    this.WebpagesService.getTemplatesByType('SEARCH', customer, '100000000').subscribe(res => { this.searchPages = res['contents'] });
  }

  /* Generate preview link for selected templates of the customer */
  generateLink(){
    this.customerGlobalId = this.selectedCustomer.customerGlobalId;

    this.pdpTemplateId = this.selectedPDP.id
    this.pdpTemplateVersionId = this.selectedPDP.version
    
    this.searchTemplateId = this.selectedSearchPage.id
    this.searchTemplateVersionId = this.selectedSearchPage.version
    
    this.createDynamicLink(this.customerGlobalId, this.searchTemplateId, this.searchTemplateVersionId, this.pdpTemplateId, this.pdpTemplateVersionId);
  }

  /* Call dynamic link endpoint */
  createDynamicLink( customerGlobalId, searchTemplateId, searchTemplateVersionId, pdpTemplateId, pdpTemplateVersionId ){
    this.WebpagesService.createDynamicPreviewLink(customerGlobalId, searchTemplateId, searchTemplateVersionId, pdpTemplateId, pdpTemplateVersionId).subscribe((data: any) => {
      if(data && data.previewUrl) {
        this.assignLink(data.previewUrl);
        this.showLink = true;
      }
    })
  }

  /* Assign link */
  assignLink = link => this.link = link;

  
}
