import { Component, OnInit, Inject } from '@angular/core';

import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';


@Component({
  selector: 'app-icon-snack-bar',
  template:`<mat-icon>{{ data?.icon }}</mat-icon><span>{{ data?.message }}</span>`,
  styles: ['mat-icon {float: right; color: white}', 'span {font-family:Open Sans; color:white}']
})


export class IconSnackBarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit(): void { }


}

