import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { WebpagesService } from './services/webpages.service';
import { FlyerService } from './services/flyer.service';
import { MicrositesService } from './services/microsites.service';
import { AppConfigService } from './services/app-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'template-app';
  constructor(
    private env: AppConfigService,
    public ref: ChangeDetectorRef,
    public webPageService: WebpagesService,
    public flyerService: FlyerService,
    public micrositesService: MicrositesService
  ) {}

  ngOnInit() {}

  showSpinner() {
    return (
      !this.webPageService.URLsReplaced ||
      !this.flyerService.URLsReplaced ||
      !this.micrositesService.URLsReplaced
    );
  }
}
