import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { WebpagesService } from '../../../services/webpages.service';
import { FlyerService } from '../../../services/flyer.service';
import { MicrositesService } from './../../../services/microsites.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.css'],
})
export class DeletePopupComponent implements OnInit {
  /* Routing */
  router: Router;

  constructor(
    private _router: Router,
    public WebpagesService: WebpagesService,
    public FlyerService: FlyerService,
    public MicrositeService: MicrositesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.router = _router;
  }

  ngOnInit(): void {}

  deleteTemplate() {
    if (this._router.url.includes('/webpages')) {
      this.WebpagesService.deleteTemplate(
        this.WebpagesService.templateID,
        this.WebpagesService.version
      );
      this.WebpagesService.showToastMessage('Webpage Deleted Successfully!', 'delete', 'danger');
    } else if (this._router.url.includes('/flyers')) {
      this.FlyerService.deleteFlyer(this.FlyerService.templateID, this.FlyerService.flyerVersion);
      this.FlyerService.showToastMessage('Flyer Deleted Successfully!', 'delete', 'danger');
    } else if (this._router.url.includes('/microsites')) {
      if (this._router.url.includes('/microsites/edit')) {
        this.MicrositeService.deleteMicrositePage(this.data.micrositeId, this.data.micrositePageId).subscribe(() => {
          this.MicrositeService.showToastMessage(
            'Microsite Page Deleted Successfully!',
            'delete',
            'danger'
          );
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/microsites/edit/' + this.MicrositeService.currentMicrositeData.id]);
          });
        });
      }else {
        this.MicrositeService.deleteMicrosite(this.data.micrositeId).subscribe(() => {
          this.MicrositeService.showToastMessage(
            'Microsite Deleted Successfully!',
            'delete',
            'danger'
          );
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/microsites']);
          });
        });
      }
    }
  }
}
