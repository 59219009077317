import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MicrositesService } from '../../../../services/microsites.service';
@Component({
  selector: 'app-microsites-subheader',
  templateUrl: './microsites-subheader.component.html',
  styleUrls: ['./microsites-subheader.component.css'],
})
export class MicrositesSubheaderComponent implements OnInit {
  path: string;

  constructor(
    private route: ActivatedRoute,
    public micrositesService: MicrositesService
  ) {}

  ngOnInit(): void {
    this.path = this.route.snapshot.url[0]?.path;
  }
}
