import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { MicrositesService } from 'src/app/services/microsites.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

interface TemplateDetail {
  dataApiBaseUrl: null;
  dataApiPathId: number;
  description: string;
  id: number;
  micrositeId: number;
  name: string;
  page: string;
  published: boolean;
  templateEngine: string;
}

interface MicrositePageData {
  name: string;
  description: string;
  page: string;
  micrositeId: null;
  buildingId: null;
  buildingName: string;
  buildingHostname: string;
}

@Component({
  selector: 'app-microsite-copy-page-popup',
  templateUrl: './microsite-copy-page-popup.component.html',
  styleUrls: ['./microsite-copy-page-popup.component.css'],
})
export class MicrositeCopyPagePopupComponent implements OnInit {
  @ViewChild('copyMicrositePageForm') form: NgForm;
  micrositePageCopyName = new FormControl('');
  duplicateMicrositePage = false;
  showDuplicatePageNameError = false;
  duplicatePageNameErrorMessage =
    'A page with same name already exists. Please enter a different name.';
  buildingHostname: string = '';
  showDuplicateSlugErrorMessage = false;
  duplicateSlugErrorMessage: string = '';

  formData: MicrositePageData = {
    name: '',
    page: '',
    description: '',
    micrositeId: null,
    buildingId: null,
    buildingName: '',
    buildingHostname: '',
  };

  constructor(
    public micrositesService: MicrositesService,
    public dialogRef: MatDialogRef<MicrositeCopyPagePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TemplateDetail,

    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.buildingHostname = this.getBuildingHostname(this.micrositesService.currentMicrositeData);
  }

  getBuildingHostname(templateDetail) {
    return templateDetail && templateDetail.hostname
      ? templateDetail.hostname + '/'
      : 'Microsite.com/';
  }

  onSubmit(form) {
    this.duplicateMicrositePage = this.checkDuplicatePageName(form.value.pageName);

    if (this.duplicateMicrositePage) {
      this.showDuplicatePageNameError = true;
      form.controls.pageName.setErrors({ incorrect: true });

      return;
    }

    this.micrositesService
      .copyMicrositePage(
        this.data,
        form.value.pageName,
        form.value.pageSlug.toLowerCase(),
        form.value.pageDescription
      )
      .subscribe(
        (data: any) => {
          if (data) {
            this.micrositesService.showToastMessage(
              'Microsite Page Copied Successfully!',
              'check_circle',
              'success'
            );
            this.micrositesService.assignCurrentMicrositeData(data);
            this.dialog.closeAll();
            this.router.navigateByUrl('microsites/edit/' + data.micrositeId + '/pages/' + data.id);
          }
        },
        (error) => this.handleSlugError(error)
      );
  }

  handleSlugError(error) {
    this.showDuplicateSlugErrorMessage = true;
    this.duplicateSlugErrorMessage = error;
    if (this.form && this.form.controls) {
      this.form.form.controls.pageSlug.setErrors({ incorrect: true });
    }
  }

  onPageSlugValueChange(value: string) {
    this.form.value.pageSlug = value.toLowerCase();
    this.showDuplicateSlugErrorMessage = false;
  }

  checkDuplicatePageName(pageName: string) {
    return this.micrositesService.currentMicrositeData.templates.some(
      (template) => template.name === pageName
    );
  }

  clearPageNameInput() {
    this.showDuplicatePageNameError = false;
  }
}
