<div class="row custom-close-row">
  <button class="custom-close-btn" mat-icon-button [mat-dialog-close]="true">
    <span class="material-icons">close</span>
  </button>
</div>

<div class="container-fluid center">
  <h1 mat-dialog-title><b>Add Webpage</b></h1>

  <div mat-dialog-content class="center">
    <form novalidate #addTemplateForm="ngForm" (ngSubmit)=onSubmit()>

      <!-- Edit webpage name -->
      <div class="row">
        <mat-form-field class="full-width custom-margin" hintLabel="Max 60 characters" appearance="outline">
          <mat-label class="custom-label custom-color">Name</mat-label>
          <input matInput placeholder="Name" type="text" [(ngModel)]="templateData.name" name="name" #name="ngModel"
            [maxLength]="60" required>
          <mat-error *ngIf="name.invalid">
            <strong>You must enter a name!</strong>
          </mat-error>
          <mat-hint align="end">{{name.value?.length || 0}}/60</mat-hint>
        </mat-form-field>
      </div>

      <!-- Description -->
      <div class="row">
        <mat-form-field class="full-width custom-description custom-margin" hintLabel="Max 120 characters"
          appearance="outline">
          <mat-label class="custom-label custom-color">Description</mat-label>
          <textarea matInput placeholder="Ex. This is an example of description..." type="textarea"
            [(ngModel)]="templateData.description" name="description" #description="ngModel" [maxLength]="120"
            required></textarea>
          <mat-error *ngIf="description.invalid">
            <strong>You must enter a Description!</strong>
          </mat-error>
          <mat-hint align="end">{{description.value?.length || 0}}/120</mat-hint>
        </mat-form-field>
      </div>

      <!-- Webpage type choice -->
      <div class="row">
        <mat-form-field class="full-width custom-margin" appearance="outline">
          <mat-label class="custom-label">Webpage Type</mat-label>
          <mat-select required disableRipple [(value)]="selectedWebpageType" [formControl]="selectType">
            <mat-option *ngFor="let webPageType of webPageTypes" [value]="webPageType.id">{{webPageType.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="selectType.hasError('required')">
            <strong>Please select the Webpage Type!</strong>
          </mat-error>
          <mat-hint>Choose the webpage Type.</mat-hint>
        </mat-form-field>
      </div>

      <!-- Web page system choice -->
      <div class="row">
        <mat-form-field class="full-width custom-margin" appearance="outline">
          <mat-label class="custom-label">System</mat-label>
          <mat-select required disableRipple [(value)]="selectedSystem" [formControl]="selectSystem">
            <mat-option value="PC">PropertyCapsule</mat-option>
            <mat-option value="VTS">VTS</mat-option>
          </mat-select>
          <mat-error *ngIf="selectSystem.hasError('required')">
            <strong>Please select the System!</strong>
          </mat-error>
          <mat-hint>Select the system.</mat-hint>
        </mat-form-field>
      </div>

      <!-- Customer name choice -->
      <div class="row">
        <mat-form-field class="full-width custom-margin" appearance="outline">
          <mat-label class="custom-label">Customer</mat-label>
          <mat-select required disableRipple [(value)]="selectedCustomer" [formControl]="selectCustomer">
            <mat-option *ngFor="let customer of customers" [value]="customer">{{customer.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="selectCustomer.hasError('required')">
            <strong>Please select the Customer!</strong>
          </mat-error>
          <mat-hint>Choose the Customer.</mat-hint>
        </mat-form-field>
      </div>

      <!-- Save button -->
      <div class="row custom-row">
        <div mat-dialog-actions class="custom-actions">
          <button type="submit" mat-raised-button class="custom-save custom-label"
            [disabled]="addTemplateForm.form.invalid || selectType.hasError('required') || selectCustomer.hasError('required')"
            [mat-dialog-close]="true">Save</button>
        </div>
      </div>

    </form>
  </div>
</div>
