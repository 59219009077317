<div class="microsite-edit-header-container">
  <div class="info-wrapper">
    <!-- Pagination Section -->
    <div class="pagination-section">
      <div class="pagination-wrapper">
        <span class="pagination-option" [routerLink]="['/microsites']">MICROSITES</span>
        <mat-icon class="pagination-icon">keyboard_arrow_right</mat-icon>
        <span
          class="pagination-option"
          [ngClass]="{ 'active-option': !router.url.includes('/pages') }"
          (click)="handlePageNavigationClick()"
          >PAGES</span
        >
        <mat-icon class="pagination-icon" *ngIf="router.url.includes('/pages')"
          >keyboard_arrow_right</mat-icon
        >
        <span
          class="pagination-option"
          [ngClass]="{ 'active-option': router.url.includes('/pages') }"
          *ngIf="router.url.includes('/pages')"
          >EDITOR</span
        >
      </div>
    </div>

    <!-- Title & Description Section -->
    <div class="title-and-description-section">
      <div class="microsite-title-section">
        <div class="microsite-title">{{ micrositeTitle }}</div>
      </div>
      <div *ngIf="micrositeDescription">
        <div class="divider"></div>
        <div class="microsite-description-section">
          <div class="microsite-description">{{ micrositeDescription }}</div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="microsite-status-section">
        <!-- TODO -->
        <div
          class="microsite-status"
          *ngIf="micrositeStatus != ''"
          [ngClass]="micrositeStatus.toLowerCase() == 'live' ? 'microsite-status-active' : ''"
        >
          {{ micrositeStatus }}
        </div>
      </div>
    </div>
  </div>

  <div class="add-microsite-page-button-wrapper">
    <button
      *ngIf="
        micrositeStatus.toLowerCase() != '' &&
        micrositeStatus.toLowerCase() != 'active' &&
        !router.url.includes('/pages')
      "
      mat-raised-button
      class="edit-btn"
      (click)="micrositesService.openEditMicrositePopup()"
    >
      Edit
    </button>
    <button
      *ngIf="micrositesService.showAddMicrositePageButton && !router.url.includes('/pages')"
      mat-raised-button
      class="add-microsite-page-button"
      (click)="micrositesService.openAddMicrositePagesPopup()"
    >
      Add page
    </button>
    <button
      *ngIf="showPublishButton"
      mat-raised-button
      class="btn view-btn"
      [disabled]="micrositeStatus.toLowerCase() == 'live'"
      (click)="startMicrositePagePublish()"
    >
      Publish
    </button>

    <button
      *ngIf="!showPublishButton && router.url.includes('/pages')"
      mat-raised-button
      class="btn view-btn"
      [disabled]="micrositeStatus.toLowerCase() != 'live'"
      (click)="confirmUnpublish()"
    >
      Unpublish
    </button>
    <!-- TODO Add Unpublish button while integrating PC-11170 -->
    <button
      *ngIf="router.url.includes('/pages')"
      mat-raised-button
      class="btn save-btn"
      [disabled]="!micrositesService.isModified()"
      (click)="micrositesService.saveMicrositePage(null)"
    >
      Save
    </button>
  </div>
</div>
