import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Clipboard } from '@angular/cdk/clipboard';

import { MicrositesService } from 'src/app/services/microsites.service';

import { FileDeletePopupComponent } from '../../popups/file-delete-popup copy/file-delete-popup.component';
import { FileRenamePopupComponent } from '../../popups/file-rename-popup copy/file-rename-popup.component';
import { FileAddPopupComponent } from '../../popups/file-add-popup/file-add-popup.component';
import { ShowImageFilePopupComponent } from '../../popups/show-image-file-popup/show-image-file-popup.component';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-microsite-page-edit-left-section',
  templateUrl: './microsite-page-edit-left-section.component.html',
  styleUrls: ['./microsite-page-edit-left-section.component.css'],
})
export class MicrositePageEditLeftSectionComponent implements OnInit, OnDestroy {
  /* To access the element fileUpload and tabs */
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  @ViewChild('tabs') tabGroup: MatTabGroup;

  /* Ng Form declaration */
  @ViewChild('editMicrositePageForm') form: NgForm;

  /* An array to hold the uploaded files*/
  files = [];

  /* To store details of templates */
  templateDetail: any;
  basicDetail: any;
  templateFiles: any;
  assetFiles: any;
  buildingHostname: string = '';
  templateDetailChanged: Subscription;

  /* To open selected file in editor */
  selectedFile: any;

  /* Read image */
  imageSource;

  /* Data load promise */
  dataLoaded: Promise<boolean>;

  /* Selecting the correct tab */
  selectedTab: any;

  /* Flyer saved */
  webpageSaved: Subscription;

  /* File changed */
  fileChanged: any;

  /* Duplicate Slug Error */
  duplicateSlugError$: any;

  showDuplicateSlugErrorMessage: boolean = false;
  duplicateSlugErrorMessage: string = '';
  currentFormControls: any;

  constructor(
    private renderer: Renderer2,
    private _router: Router,
    public dialog: MatDialog,
    private clipboard: Clipboard,
    private _route: ActivatedRoute,
    public micrositesService: MicrositesService
  ) {
    this._route.queryParams.subscribe((params) => {
      switch (params['section']) {
        case 'basic':
          this.selectedTab = 0;
          break;
        case 'details':
          this.selectedTab = 1;
          break;
        default:
          this.selectedTab = 0;
      }
    });
  }

  ngOnInit(): void {
    this.templateDetailChanged = this.micrositesService.micrositePageDataSourceChanged.subscribe(
      (templateDetail) => {
        this.templateDetail = templateDetail;
        const { files, ...basicDetails } = templateDetail;
        this.templateFiles = files.filter(
          (file) =>
            file.mediaType == 'HTML' ||
            file.mediaType == 'CSS' ||
            file.mediaType == 'JS' ||
            file.mediaType == 'TEXT_PLAIN'
        );
        this.assetFiles = files.filter(
          (file) =>
            file.mediaType !== 'HTML' &&
            file.mediaType !== 'CSS' &&
            file.mediaType !== 'JS' &&
            file.mediaType !== 'TEXT_PLAIN'
        );
        this.basicDetail = basicDetails;
        this.micrositesService.basicFormValid = true;
        this._route.queryParams.subscribe((params) => {
          if (params['section'] != 'basic') {
            this._router.navigate([], {
              queryParams: { section: 'details' },
            });
          }
        });
        const mainFtlh = files.filter((file) => file.name == 'main.ftlh');
        if (mainFtlh.length == 1 && !this.selectedFile) {
          this.selectedFile = mainFtlh[0].name;
          this.micrositesService.openFileInEditor('main.ftlh');
        }
        this.dataLoaded = Promise.resolve(true);
        this.setBasicMicrositeDetails(templateDetail);
        this.buildingHostname = this.getBuildingHostname(this.templateDetail);
      }
    );

    this.webpageSaved = this.micrositesService.webpageSaved.subscribe((isSaved) => {
      if (isSaved) this.files = [];
    });

    this.fileChanged = this.micrositesService.mainFtlhSelected.subscribe((mainFtlhSelected) => {
      if (mainFtlhSelected) this.selectedFile = 'main.ftlh';
    });

    this.duplicateSlugError$ = this.micrositesService.micrositePageDuplicateSlugError.subscribe(
      (error: any) => {
        if (error) {
          setTimeout(() => {
            this.showDuplicateSlugError(error);
          }, 0);
        }
      }
    );
  }

  setBasicMicrositeDetails(templateDetail) {
    let backupTempDetails = JSON.parse(JSON.stringify(templateDetail));
    setTimeout(() => {
      this.form.form.patchValue({
        pageName: backupTempDetails.name,
        pageSlug: backupTempDetails.page,
        pageDescription: backupTempDetails.description,
      });
      this.setIsValid('', backupTempDetails, '');
    });
  }

  getBuildingHostname(templateDetail) {
    return templateDetail && templateDetail.buildingHostname
      ? templateDetail.buildingHostname + '/'
      : 'Microsite.com/';
  }

  /* To copy S3 URL for downloading any file */
  copyAssetDownloadURL(s3Url) {
    this.clipboard.copy(s3Url);
    this.micrositesService.showToastMessage('URL Copied Successfully!', 'check_circle', 'success');
  }

  /* To select the right tab - basic or details */
  selectTab($event) {
    if ($event.index == 0) {
      this._router.navigate([], {
        queryParams: { section: 'basic' },
      });
    } else if ($event.index == 1) {
      this._router.navigate([], {
        queryParams: { section: 'details' },
      });
    }
    this.selectedTab = $event.index;
  }

  /* To open File delete pop up */
  openDeletePopup(file) {
    this.dialog.open(FileDeletePopupComponent, {
      data: { fileId: file.id },
    });
  }

  /* To open File rename pop up */
  openRenamePopup(fileName) {
    this.dialog.open(FileRenamePopupComponent, {
      data: { fileName: fileName },
    });
  }

  /* To open add file pop up */
  openAddFilePopup() {
    this.dialog.open(FileAddPopupComponent);
  }

  /* To restore a file */
  restoreFile(fileName) {
    this.micrositesService.restoreFile(fileName);
  }

  /* To Toggle the expansion panel */
  expandPanel(matExpansionPanel, event): void {
    event.stopPropagation();

    if (!this._isExpansionIndicator(event.target)) {
      matExpansionPanel.toggle();
    }
  }
  private _isExpansionIndicator(target: EventTarget): boolean {
    const expansionIndicatorClass = 'mat-expansion-indicator';
    return target['classList'] && target['classList'].contains(expansionIndicatorClass);
  }

  /* Executed on file upload */
  onUpload() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        let file = fileUpload.files[index];
        if (file.size > 9999999) {
          alert(' Size of this file is greater than 10mb \n ->' + file.name);
          if (index < fileUpload.files.length) {
            continue;
          } else return false;
        }
        if (file.name.split('.').length == 1) {
          alert('Files without extensions cannot be uploaded \n ->' + file.name);
          if (index < fileUpload.files.length) {
            continue;
          } else return false;
        }
        if (!this.micrositesService.isFileTypeValid(file.name)) {
          alert(file.name + ' This type of file is not allowed');
          if (index < fileUpload.files.length) {
            continue;
          } else return false;
        }

        if (this.micrositesService.hasWhiteSpace(file.name)) {
          // console.log("filename:", this.webPageService.hasWhiteSpace(file.name));
          alert(file.name + ' This filename contains spaces');
          if (index < fileUpload.files.length) {
            continue;
          } else return false;
        }

        if (this.micrositesService.checkDuplicateFilename(file.name)) {
          alert(file.name + ' This filename already exists');
          if (index < fileUpload.files.length) {
            continue;
          } else return false;
        }
        const fileName = file.name.split('.');

        if (fileName[fileName.length - 1] == 'ttf')
          file = new File([file], file.name, { type: 'font/ttf' });
        else if (
          fileName[fileName.length - 1] == 'woff' ||
          fileName[fileName.length - 1] == 'woff2'
        )
          file = new File([file], file.name, { type: 'font/woff' });
        else if (fileName[fileName.length - 1] == 'otf')
          file = new File([file], file.name, { type: 'font/otf' });
        else if (fileName[fileName.length - 1] == 'ico')
          file = new File([file], file.name, { type: 'image/x-icon' });
        else if (fileName[fileName.length - 1] == 'gif')
          file = new File([file], file.name, { type: 'image/gif' });
        else if (fileName[fileName.length - 1] == 'jpg')
          file = new File([file], file.name, { type: 'image/jpeg' });
        else if (fileName[fileName.length - 1] == 'png')
          file = new File([file], file.name, { type: 'image/png' });
        else if (fileName[fileName.length - 1] == 'svg')
          file = new File([file], file.name, { type: 'image/svg+xml' });
        else if (fileName[fileName.length - 1] == 'bmp')
          file = new File([file], file.name, { type: 'image/bmp' });
        else if (fileName[fileName.length - 1] == 'css')
          file = new File([file], file.name, { type: 'text/css' });
        else if (fileName[fileName.length - 1] == 'js')
          file = new File([file], file.name, { type: 'text/javascript' });
        else if (fileName[fileName.length - 1] == 'jpeg')
          file = new File([file], file.name, { type: 'image/jpeg' });
        else if (fileName[fileName.length - 1] == 'webp')
          file = new File([file], file.name, { type: 'image/webp' });
        else if (fileName[fileName.length - 1] == 'eot')
          file = new File([file], file.name, {
            type: 'application/vnd.ms-fontobject',
          });
        else if (fileName[fileName.length - 1] == 'json')
          file = new File([file], file.name, { type: 'application/json' });
        else if (fileName[fileName.length - 1] == 'ftl' || fileName[fileName.length - 1] == 'ftlh')
          file = new File([file], file.name, { type: 'text/plain' });
        else if (fileName[fileName.length - 1] == 'pdf')
          file = new File([file], file.name, { type: 'application/pdf' });

        if (fileUpload.files && fileUpload.files[0]) {
          const file = fileUpload.files[0];
          const reader = new FileReader();
          reader.onload = (e) => (this.imageSource = reader.result);
          reader.readAsDataURL(file);
        }
        this.micrositesService.filesModified = true;
        this.files.push({ data: file, inProgress: true, progress: 100 });
        this.micrositesService.addNewAssetFile(file);
      }
    };
    fileUpload.click();
  }

  /* For file edit */
  onEdit(file) {
    // console.log(file.mediaType);
    if (
      file.mediaType == 'PNG' ||
      file.mediaType == 'JPEG' ||
      file.mediaType == 'GIF' ||
      file.mediaType == 'ICON' ||
      file.mediaType == 'SVG' ||
      file.mediaType == 'BMP'
    ) {
      this.dialog.open(ShowImageFilePopupComponent, {
        data: {
          src: file.downloadUrl,
          fileName: file.name,
        },
      });
      return false;
    } else if (
      file.mediaType == 'TTF' ||
      file.mediaType == 'WOFF' ||
      file.mediaType == 'OTF' ||
      file.mediaType == 'TIFF' ||
      file.mediaType == 'EOT' ||
      file.mediaType == 'PDF'
    ) {
      return false;
    } else {
      this.selectedFile = file.name;
      this.micrositesService.openFileInEditor(file.name);
    }
  }

  isActive(item) {
    return this.selectedFile == item;
  }

  /* To check if the details entered in basic section are valid or not */
  setIsValid(value: String, templateDetail: any, type: String) {
    if (type === 'pageSlug') {
      this.form.value.pageSlug = value.toLowerCase();
      this.showDuplicateSlugErrorMessage = false;
    }
    if (templateDetail) {
      this.templateDetail.name = templateDetail.name;
      this.templateDetail.page = templateDetail.page;
      this.templateDetail.description = templateDetail.description;
    } else {
      this.templateDetail.name = this.form.value.pageName;
      this.templateDetail.page = this.form.value.pageSlug;
      this.templateDetail.description = this.form.value.pageDescription;
    }

    if (
      this.templateDetail.name.length > 0 &&
      this.templateDetail.page.length > 0 &&
      this.templateDetail.description.length > 0
    ) {
      this.micrositesService.currentMicrositePageData.name = this.templateDetail.name;
      this.micrositesService.currentMicrositePageData.page = this.templateDetail.page.toLowerCase();
      this.micrositesService.currentMicrositePageData.description = this.templateDetail.description;
    }

    this.micrositesService.basicFormValid = this.form && this.form.status === 'VALID';
  }

  /* To replace asset URLs */
  onReplaceURLs() {
    this.micrositesService.replaceAssetUrls();
  }

  onSubmit(form) {
    this.micrositesService.currentMicrositePageData.name = form.value.pageName;
    this.micrositesService.currentMicrositePageData.page = form.value.pageSlug.toLowerCase();
    this.micrositesService.currentMicrositePageData.description = form.value.pageDescription;
    this.micrositesService.saveMicrositePage(null);
  }

  showDuplicateSlugError(error: string) {
    this.showDuplicateSlugErrorMessage = true;
    this.duplicateSlugErrorMessage = error;
    this.form.form.controls['pageSlug'].markAsTouched();
    if (this.form && this.form.controls) {
      this.form.form.controls['pageSlug'].setErrors({ incorrect: true });
    }
  }

  ngOnDestroy() {
    this.duplicateSlugError$ && this.duplicateSlugError$.unsubscribe();
  }
}
