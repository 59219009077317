// Angular imports
import { Component, OnInit, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// Material imports
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

// Other imports
import { DeletePopupComponent } from '../../popups/delete-popup/delete-popup.component';
import { WebpagePublishPopupComponent } from '../../popups/webpage-publish-popup/webpage-publish-popup.component';
import { WebpagesService } from '../../../services/webpages.service';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent implements OnInit {

  /* Filters */
  // Value of the customer and/or status from the filter field
  choosenCustomer: any = ""
  choosenStatus: string = "";
  // To reset the form fields of the filters
  filterCustomer = new FormControl();
  filterStatus = new FormControl();
  // The options for Status field
  statusOptions = [
    { name: "DRAFT", id: "1" },
    { name: "PUBLISHED", id: "2" }
  ]
  // To select customers while filtering data
  public customers: any;
  // ALL option in the filter
  allAOptionArray = [{
    customerGlobalId: 0,
    id:0,
    name: ' ALL' //The space is introduced to show this option as first
  }];

  allCustomers:any;

  /* Routing */
  router: Router;
  route: string;
  path: string;

  /* Data table */
  dataSource = new MatTableDataSource();
  columnsToDisplay: string[];
  data: Subscription;
  PaginatedData: Subscription;
  pgNo: Subscription;
  pgSize: Subscription;
  pgTotalElements: Subscription;

  /* Pagination */
  pageNo: number; //The current page index
  pageSize: number; //Items per page
  length: number; //To get total number of elements in the data table

  /* Sorting */
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageEvent: PageEvent;
  previousPageIndex: any;

  constructor(
    public dialog: MatDialog, private _router: Router, private _route: ActivatedRoute, public WebpagesService: WebpagesService
  ) {
    this.router = _router;

    this._route.queryParams.subscribe(params => {
      if (params['customer']) this.choosenCustomer = params['customer'];
      if (params['status']) this.choosenStatus = params['status'];
    });
  }

  ngOnInit(): void {

    /* Customers filter dropdown */
    this.WebpagesService.getCustomers().subscribe(customers => { 
      this.customers = customers 
      Array.prototype.push.apply(this.customers,this.allAOptionArray); 
      this.customers = this.customers.sort((a, b) => a.name.localeCompare(b.name))
    });

    /* select the correct columns*/
    if (this._router.url.includes('/webpages')) {
      this.columnsToDisplay = ['name', 'description', 'customer', 'lastModified', 'type', 'actions']
    }

    /* Get the hardcoded values of page number and page size from webservice */
    this.pageNo = this.WebpagesService.pageIndex - 1;
    this.pageSize = this.WebpagesService.pageSize;

    /* Retrieve the template data list */
    this.data = this.WebpagesService.sharedDataSourceChanged$.subscribe( (data) => { this.assignData(data) })

    /* Get the total elements */
    this.pgTotalElements = this.WebpagesService.sharedTotalElements$.subscribe( (pageTotalements) => { this.setLength(pageTotalements); })

    /* Get the page No. */
    this.pgNo = this.WebpagesService.sharedPageNo$.subscribe( (pageNo) => { this.pageNo = pageNo - 1 })

    /* Get the page size */
    this.pgSize = this.WebpagesService.sharedPageSize$.subscribe( (pageSize) => { this.setPageSize(pageSize) })

    /* Loading template data */
    this.WebpagesService.loadTemplatesOnPagination(++this.pageNo, this.pageSize, this.choosenCustomer, this.choosenStatus);

  }

  /* Assign data to the datasource */
  assignData(data) {
    this.dataSource.data = data
    this.WebpagesService.sharedDataSource.data = this.dataSource.data
  }

  /* To filter templates by Customers and status*/
  onFilter() {
    let customer = this.choosenCustomer ? this.choosenCustomer : "", status = this.choosenStatus ? this.choosenStatus : ""

    if (customer === ' ALL') {
      this.WebpagesService.filterTemplates("", status);
    }
    else { 
      this.WebpagesService.filterTemplates(customer, status);
    }
    
    this._router.navigate([], {
      queryParams: { customer: customer, status: status },
      queryParamsHandling: 'merge'
    });
  }

  /* To reset the filters */
  resetFilters() {
    this.filterCustomer.setValue('')
    this.filterStatus.setValue('')
    this.path = this._route.snapshot.url[0].path
    this._router.navigate(['/' + this.path])
    this.WebpagesService.loadTemplates()
  }

  /* To open Webpage Delete and Publish popup */
  openDialog(action, id, version) {
    this.WebpagesService.templateID = id;
    this.WebpagesService.version = version;

    if (action == 'delete') {
      const dialogRef = this.dialog.open(DeletePopupComponent);
    } else if (action == 'publish') {
      const dialogRef = this.dialog.open(WebpagePublishPopupComponent);
    }
  }

  /* To navigate to the edit section of the template - Making the data table row clickable */
  editTemplate(id, version) {
    if (this._router.url.includes('/webpages')) {
      this.router.navigateByUrl('webpages/edit/' + id + '/version/' + version);
    }
  }

  /* To create a copy of a particular template */
  copy(id, version) {
    this.WebpagesService.copyTemplate(id, version).subscribe(response => {
      if (response && this._router.url.includes('/webpages')) {
        this.router.navigateByUrl('webpages/edit/' + response['id'] + '/version/' + response['version']);
        this.WebpagesService.showToastMessage("Webpage Copied Successfully!", "check_circle", 'success');
      }
    })
  }

  /* Pagination */
  onPagination = $event => this.WebpagesService.loadTemplatesOnPagination(++$event.pageIndex, $event.pageSize, this.choosenCustomer, this.choosenStatus);

  setLength(length) {
    this.length = length
    this.WebpagesService.totalElements = this.length;
  }

  setPageNo(pageNumber) {
    this.pageNo = pageNumber
    this.WebpagesService.pageIndex = this.pageNo;
  }

  setPageSize(pageSize) {
    this.pageSize = pageSize
    this.WebpagesService.pageSize = this.pageSize;
  }

}