import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { WebpagesService } from 'src/app/services/webpages.service';
import { FlyerService } from 'src/app/services/flyer.service';
import { MicrositesService } from 'src/app/services/microsites.service';

import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-flyer-edit',
  templateUrl: './flyer-edit.component.html',
  styleUrls: ['./flyer-edit.component.css'],
})
export class FlyerEditComponent implements OnInit, OnDestroy {
  /* To fetch the template Id and version from the route */
  private routeSub: Subscription;
  templateId: string = '';
  templateVersion: string = '';
  micrositesPageId: string = '';
  editWebpage: boolean = false;
  editFlyer: boolean = false;
  editMicrosite: boolean = false;

  constructor(
    public webPageService: WebpagesService,
    public FlyerService: FlyerService,
    public micrositesService: MicrositesService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.templateId = params['id'];
      this.templateVersion = params['version'];
      this.micrositesPageId = params['pageId'];
    });

    if (this.router.url.includes('/webpages/edit')) {
      this.editWebpage = true;
      this.webPageService.getTemplateDetails(this.templateId, this.templateVersion);
    }

    if (this.router.url.includes('/flyers/edit')) {
      this.editFlyer = true;
      this.FlyerService.getFlyerDetails(this.templateId, this.templateVersion);
    }

    if (this.router.url.includes('/microsites/edit')) {
      this.editMicrosite = true;
      this.micrositesService.loadMicrositesPageDetails(this.templateId, this.micrositesPageId);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unload($event: any) {
    if (this.editWebpage) {
      if (this.webPageService.isModified()) {
        $event.returnValue =
          'You have unsaved changes! If you leave the page, your changes will be lost.';
      }
    }
    if (this.editFlyer) {
      if (this.FlyerService.isModified()) {
        $event.returnValue =
          'You have unsaved changes! If you leave the page, your changes will be lost.';
      }
    }
    if (this.editMicrosite) {
      if (this.micrositesService.isModified()) {
        $event.returnValue =
          'You have unsaved changes! If you leave the page, your changes will be lost.';
      }
    }
  }

  canDeactivate() {
    let filesModified = false;
    if (this.editWebpage) {
      filesModified = this.webPageService.isModified();
    }

    if (this.editFlyer) {
      filesModified = this.FlyerService.isModified();
    }

    if (this.editMicrosite) {
      filesModified = this.micrositesService.isModified();
    }

    if (filesModified) {
      if (
        window.confirm(
          'You have unsaved changes. If you leave the page, your changes will be lost.',
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
