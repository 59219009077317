import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { MatSelect } from '@angular/material/select';

import { FlyerService } from '../../../services/flyer.service';


@Component({
  selector: 'app-add-attribute-popup',
  templateUrl: './add-attribute-popup.component.html',
  styleUrls: ['./add-attribute-popup.component.css']
})


export class AddAttributePopupComponent implements OnInit {

  @ViewChild('attributeSelect') attributeSelect: MatSelect;

  attributes: any = []
  attributeForm: FormGroup;

  // disable or enable submit button
  showSubmit: boolean = true;

  /* The details of the current flyer*/
  flyerDetail;

  attributeList: any = [

    { viewValue: 'Choice', type: 'CHOICES' },
    { viewValue: 'Color', type: 'COLOR' },
    { viewValue: 'File', type: 'FILE'},
    { viewValue: 'Image Uploader', type: 'IMAGE_FILE' },
    { viewValue: 'Number', type: 'NUMBER', },
    { viewValue: 'PDF Append', type: 'PDF_APPEND'},
    { viewValue: 'PDF Prepend', type: 'PDF_PREPEND'},
    { viewValue: 'Photo Picker', type: 'PHOTO_PICKER_WITH_UPLOAD' },
    { viewValue: 'Section Heading', type: 'SECTION_HEADING' },
    { viewValue: 'Slider', type: 'SLIDER'},
    { viewValue: 'Text', type: 'TEXT' }
  ];

  selectedAttribute = new FormControl();
  attrName: any = new FormControl('', [Validators.required]);
  attrDescription = new FormControl('', [Validators.required]);
  attrDefault:any = new FormControl('', [Validators.required]);
  attrMin:any = new FormControl();
  attrMax:any = new FormControl();
  attrStep:any = new FormControl();
  attrChoices:any = new FormControl();
  
  attrs: any = [];

  disableDropdown: boolean;
  duplicateAttribute: boolean;
  attrData: any = [];

  showDefaultValue: boolean = false;
  showMinValue: boolean = false;
  showMaxValue: boolean = false;
  showStepValue: boolean = false;
  showChoicesValue: boolean = false;
  showColor: boolean = false;

  showList: boolean = false;
  slider: boolean = false;
  number: boolean = false;
  choices: boolean = false;
  text: boolean = false;
  color: boolean = false;
  sectionHeading: boolean = false;
  image: boolean = false;
  photoPicker: boolean = false;
  file: boolean = false;
  pdfAppend: boolean = false;
  pdfPrepend: boolean = false;


  constructor(private FlyerService: FlyerService,
    private fb: FormBuilder) {
    this.attributeForm = this.fb.group({
      name: '',
      attributes: this.fb.array([]),
      choice: ''
    });
  }

  ngOnInit(): void {
    this.flyerDetail = this.FlyerService.flyerDetail;
  }

  quantities(): FormArray {
    return this.attributeForm.get("attributes") as FormArray
  }

  newQuantity(): FormGroup {
    return this.fb.group({
      name: '',
      attributeName: '',
      choice: ''
    })
  }

  selectAttribute(event) {
    this.disableDropdown = true;

    if (event.value === 'SLIDER' || event.value === 'TEXT' || event.value === 'CHOICES' || 
      event.value === 'SECTION_HEADING' || event.value === 'NUMBER' || event.value === 'COLOR'){

      this.showDefaultValue = true;
      
      switch (event.value) {
        case 'SLIDER':
          this.slider = true;
          this.showMinValue = true;
          this.showMaxValue = true;
          this.showStepValue = true;
          this.showColor = false;
        break;
        case 'CHOICES':
          this.choices = true;
          this.showChoicesValue = true;
        break;  
        case 'TEXT':
          this.text = true;
        break;  
        case 'SECTION_HEADING':
          this.sectionHeading = true;
        break;    
        case 'NUMBER':
          this.number = true;
          this.showMinValue = true;
          this.showMaxValue = true;
        break;
        case 'COLOR':
          this.color = true;
          this.showColor = true;
          this.showChoicesValue = false;
        break;
      }
    }  
    
  if (event.value === 'IMAGE_FILE' || event.value === 'PHOTO_PICKER_WITH_UPLOAD' || event.value === 'FILE' ||
    event.value === 'PDF_APPEND' || event.value === 'PDF_PREPEND') {
      this.showDefaultValue = false;
      switch (event.value) {
        case 'IMAGE_FILE':
          this.image = true;
        break;
        case 'PHOTO_PICKER_WITH_UPLOAD':
          this.photoPicker = true;
        break;  
        case 'FILE':
          this.file = true;
        break;
        case 'PDF_APPEND':
          this.pdfAppend = true;
        break;
        case 'PDF_PREPEND':
          this.pdfPrepend = true;
        break;  
    }
  }

  this.quantities().push(this.newQuantity());

}

  addAttribute(event) {
    this.duplicateAttribute = this.FlyerService.checkDuplicateAttributeName(this.attrName.value, this.flyerDetail.flyerId);

    if (this.duplicateAttribute){
      this.FlyerService.showToastMessage("Duplicate Attribute Name!", "error", 'danger');
      this.attrName.setValue('');
      this.attrDefault.setValue('');
      this.attrMin.setValue('');
      this.attrMax.setValue('');
      this.attrChoices.setValue('');
      this.attrStep.setValue('');
    }
    else {
      if (this.slider){
        this.attrs.push({ 
          flyerId: this.flyerDetail.flyerId,
          name: this.attrName.value,
          type: this.selectedAttribute.value.toUpperCase(),
          description: this.attrDescription.value,
          defaultValue: this.attrDefault.value,
          value: '',
          minimumValue: this.attrMin.value,
          maximumValue: this.attrMax.value,
          interval: this.attrStep.value,
          choices: "",
          ordering: 0,
          deleted: false
        })
      } 
      else if (this.choices){
        this.attrs.push({ 
          flyerId: this.flyerDetail.flyerId,
          name: this.attrName.value,
          type: this.selectedAttribute.value.toUpperCase(),
          description: this.attrDescription.value,
          defaultValue: this.attrDefault.value,
          value: '',
          minimumValue: "",
          maximumValue: "",
          interval: "",
          choices: this.attrChoices.value,
          ordering: 0,
          deleted: false
        })
      }
      else if (this.number){
        this.attrs.push({ 
          flyerId: this.flyerDetail.flyerId,
          name: this.attrName.value,
          type: this.selectedAttribute.value.toUpperCase(),
          description: this.attrDescription.value,
          defaultValue: this.attrDefault.value,
          value: '',
          minimumValue: this.attrMin.value,
          maximumValue: this.attrMax.value,
          interval: "",
          choices: "",
          ordering: 0,
          deleted: false
        })
      }
      else if (this.color){
        this.attrs.push({ 
          flyerId: this.flyerDetail.flyerId,
          name: this.attrName.value,
          type: this.selectedAttribute.value.toUpperCase(),
          description: this.attrDescription.value,
          defaultValue: this.attrDefault.value,
          value: '',
          minimumValue: 0,
          maximumValue: 0,
          interval: "",
          choices: "",
          ordering: 0,
          deleted: false
        })
      }
      else {
        this.attrs.push({ 
          flyerId: this.flyerDetail.flyerId,
          name: this.attrName.value,
          type: this.selectedAttribute.value.toUpperCase(),
          description: this.attrDescription.value,
          defaultValue: this.attrDefault.value,
          value: '',
          minimumValue: 0,
          maximumValue: 0,
          interval: 0,
          choices: "",
          ordering: 0,
          deleted: false
        })
      }
      this.quantities().controls = []

      this.showList = true;
      this.disableDropdown = false;      
      this.showSubmit = false;

      this.attrName.setValue('');
      this.attrDefault.setValue('');
      this.attrMin.setValue('');
      this.attrMax.setValue('');
      this.attrStep.setValue('');
      this.attrChoices.setValue('');
      this.attrDescription.setValue('');

      this.slider = false;
      this.choices = false;
      this.text = false;
      this.sectionHeading = false;
      this.number = false;
      this.color = false;
      this.image = false;
      this.photoPicker = false;
      this.file = false;
      this.pdfAppend = false;
      this.pdfPrepend = false;

      this.showColor = false;
      this.showMinValue = false;
      this.showMaxValue = false;
      this.showStepValue = false;
      this.showChoicesValue = false;
      this.showDefaultValue = false;

      this.attributeSelect.value = [];
    }
  }

  onSubmit() {
    this.FlyerService.updateAttributes(this.flyerDetail.flyerId, this.attrs).subscribe(attributeData => {
      this.FlyerService.getFlyer(this.flyerDetail.id, this.flyerDetail.version).subscribe((data) => {
        this.FlyerService.flyerAttributesChanged.next(data['flyerAttributes']);
      })
      this.FlyerService.showToastMessage("Attribute Added Successfully!", "check_circle", 'success');
      this.attrs = [];
    })
  }

  
}
