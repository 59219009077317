<mat-toolbar>
    <img src="assets/logo/PC-logo.png" alt="logo" class="logo" width="55">

    <button mat-flat-button *ngIf="isUserLoggedIn" [routerLink]="['/flyers']" routerLinkActive="active">
        FLYERS
    </button>
    
    <button mat-flat-button *ngIf="isUserLoggedIn" [routerLink]="['/webpages']" routerLinkActive="active">
        WEBPAGES
    </button>

    <button mat-flat-button *ngIf="isUserLoggedIn" [routerLink]="['/microsites']" routerLinkActive="active">
        MICROSITES
    </button>
    
    <span class="example-spacer"></span>
    
    <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="isUserLoggedIn">
        <mat-icon class="profile">account_circle</mat-icon>
    </button>
    
    <mat-menu #menu="matMenu">
        <button mat-menu-item disabled *ngIf="dataLoaded | async">
            <mat-icon>person</mat-icon>
            <span>{{loggedInUser.fullName}}</span>
        </button>
    
        <button mat-menu-item disabled *ngIf="dataLoaded | async">
            <mat-icon>email</mat-icon>
            <span>{{loggedInUser.emailAddress}}</span>
        </button>
    
        <button mat-menu-item (click)="onLogOut()" *ngIf="dataLoaded | async">
            <mat-icon>exit_to_app</mat-icon>
            <span>Log out</span>
        </button>
    </mat-menu>
    
</mat-toolbar>