import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MicrositesService } from '../../../services/microsites.service';
import { micrositePageData } from 'src/app/models/micrositePageData';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-microsite-pages-add-popup',
  templateUrl: './microsite-pages-add-popup.component.html',
  styleUrls: ['./microsite-pages-add-popup.component.css'],
})
export class MicrositePagesAddPopupComponent implements OnInit {
  /* Ng Form declaration */
  @ViewChild('addMicrositePageForm') form: NgForm;
  buildingHostname: string = '';

  formData: micrositePageData = {
    name: '',
    page: '',
    description: '',
    micrositeId: null,
    buildingId: null,
    buildingName: '',
    buildingHostname: '',
  };

  showDuplicateSlugErrorMessage: boolean = false;
  duplicateSlugErrorMessage: string = '';

  constructor(private _router: Router, public micrositesService: MicrositesService) {}

  ngOnInit(): void {
    this.buildingHostname = this.getBuildingHostname(this.micrositesService.currentMicrositeData);
  }

  getBuildingHostname(templateDetail) {
    return templateDetail && templateDetail.hostname
      ? templateDetail.hostname + '/'
      : 'Microsite.com/';
  }

  onSubmit(form) {
    this.formData.name = form.value.pageName;
    this.formData.page = form.value.pageSlug.toLowerCase();
    this.formData.description = form.value.pageDescription;
    this.formData.micrositeId = this.micrositesService.currentMicrositeData.id;
    this.formData.buildingId = this.micrositesService.currentMicrositeData.buildingId;
    this.formData.buildingName = this.micrositesService.currentMicrositeData.buildingName;
    this.formData.buildingHostname = this.micrositesService.currentMicrositeData.hostname;
    this.createMicrositePage(this.formData, form);
  }

  createMicrositePage(pageData, form) {
    if (pageData.micrositeId) {
      this.micrositesService.addMicrositePage(pageData.micrositeId, pageData).subscribe(
        (data: any) => {
          if (data) {
            this.showDuplicateSlugErrorMessage = false;
            this.micrositesService.dialog && this.micrositesService.dialog.closeAll();
            this.micrositesService.showToastMessage(
              'Microsite Page Added Successfully!',
              'check_circle',
              'success'
            );
            this._router.navigateByUrl('microsites/edit/' + data.micrositeId + '/pages/' + data.id);
          }
        },
        (error) => {
          this.showDuplicateSlugErrorMessage = true;
          this.duplicateSlugErrorMessage = error;
          if (form && form.controls) {
            form.form.controls['pageSlug'].setErrors({ incorrect: true });
          }
        }
      );
    }
  }

  onPageSlugValueChange(value) {
    this.form.value.pageSlug = value.toLowerCase();
    this.showDuplicateSlugErrorMessage = false;
  }
}
