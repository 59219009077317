/* Angular core imports */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError, forkJoin, Subject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

/* Material imports */
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

/* Other imports - components and services */
import { toFormDataNew } from './convertFormdata';
import { IconSnackBarComponent } from '../components/icons-snack-bar/icon-snack-bar.component';
import { templateDetail } from '../models/templateDetail';
import { Router } from '@angular/router';
import { AppConfigService } from './app-config.service';


@Injectable({
  providedIn: 'root'
})


export class WebpagesService {

  /* Validation for Basic form */
  basicFormValid: boolean = false;
  
  /* The details of the templates */
  templateDetail: any
  templateDetailChanged: any = new Subject<any>();
  basicDetails: any;
  templateFiles: any;
  newAssetFiles: any = [];
  templateNameChanged: any = new Subject<any>();
  templateId: any
  templateVersion: any

  /* File opened in editor */
  fileOpenedInEditor: any;
  
  /* Content of opened file in editor */
  contentOfOpenedFile: any = new Subject<any>();
  
  /* main.ftlh file selected */
  mainFtlhSelected: any = new Subject<any>();
  
  /* Flag for file modification check */
  filesModified: any = false

  /* Webpage Saved?? */
  webpageSaved: any = new Subject<any>();

  /* To assign data to the template */
  data: any;

  /* To replace asset URLs */
  URLsReplaced: boolean = true

  /* Back up data if anything goes wrong while editing */
  backupData: any

  /* These variables maintains a copy of the datasource which can be shared between multiple components */
  dataSource = new MatTableDataSource();
  sharedDataSource = new MatTableDataSource();
  sharedDataSourceChanged$: any = new Subject<any>();
  sharedPageNo$: any = new Subject<any>();
  sharedPageSize$: any = new Subject<any>();
  sharedTotalElements$: any = new Subject<any>();

  /* For pagination */
  pageIndex: number = 1;
  pageSize: number = 30;
  totalElements: number;

  /* This variable maintains a copy of the newly created webpage */
  currentWebpageDetails: any;

  /* These variables maintains a copy of template ID, version and customer ID for operations like - DELETE, PUBLISH, etc. 
  Also used for navigation after creating template. */
  templateID: number;
  version: number;
  customerId: number;

  /* Positions for displaying snackbar */
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  /* To store property related info - used in static preview */
  properties$: any = new Subject<any>();
  propertyDetails: any;
  propertyGlobalID: any;

  /* To send webpage type for static preview */
  webpageType$: any = new Subject<any>();

  /* To send customer for dynamic preview */
  customerName$: any = new Subject<any>();
  templateName$: any = new Subject<any>();

  /* To store and send customer Global Id with template while fetching details of the template. */
  customerGlobalId: any;
  customerGlobalId$: any = new Subject<any>();

  templateSystem: any;
  templateSystem$: any = new Subject<any>();

  /* These variables are used for sending necessary notifications for generating static preview */
  webpageSavedForPDPPreview: any = new Subject<any>();
  webpageSavedForSearchPreview: any = new Subject<any>();


  constructor(private env: AppConfigService, private http: HttpClient, private _snackBar: MatSnackBar, public router: Router) { }


  /* Http Options */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  /* Handle API errors */
  errorHandler(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = error.error.message
      console.error('Client side error occurred:', errorMessage);
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong.
      errorMessage = `Backend returned code: ${error.status}, ` + `Error message: ${error.error}`;
      console.error('Backend error message: ', errorMessage);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
    // return throwError(errorMessage);
  };

  // ******************* POST calls ************************

  /* To create a new webpage */
  createTemplate(template) {
    return this.http.post(
      this.env.config.APP.API_URL + '/template',
      JSON.stringify(template),
      this.httpOptions
    ).pipe(
      retry(2),
      catchError(this.errorHandler)
    )
  }

  /* Copy/Duplicate a single webpage by id and version */
  copyTemplate(id, version) {
    return this.http.post(
      this.env.config.APP.API_URL + '/template/' + id + '/version/' + version + '/copy', this.httpOptions
    ).pipe(
      retry(2),
      catchError(this.errorHandler)
    )
  }

  /* Publish a single webpage by id and version */
  publishTemplate(id, version, template) {
    return this.http.post(this.env.config.APP.API_URL + '/template/' + id + '/version/' + version + '/publish', 
    template
    ).pipe(
      retry(2),
      catchError(this.errorHandler)
    )
  }

  /* To create a dynamic preview link */
  createDynamicPreviewLink(customerGlobalId, searchTemplateId, searchTemplateVersionId, pdpTemplateId, pdpTemplateVersionId) {
    return this.http.post(
      this.env.config.APP.API_URL + '/template/preview?customerGlobalId=' + customerGlobalId +
      '&searchTemplateId=' + searchTemplateId +
      '&searchTemplateVersionId=' + searchTemplateVersionId +
      '&pdpTemplateId=' + pdpTemplateId +
      '&pdpTemplateVersionId=' + pdpTemplateVersionId, 
      {}
    )
  }

  // ******************* GET calls ************************

  /* To retrieve a list of existing customers */
  getCustomers() {
    return this.http.get(
      this.env.config.APP.API_URL + '/customer'
    ).pipe(
      retry(2),
      catchError(this.errorHandler)
    )
  }

  /* To retrieve a list of webpages */
  getTemplates() {
    return this.http.get(
      this.env.config.APP.API_URL + '/template'
    ).pipe(
      retry(2),
      catchError(this.errorHandler)
    )
  }

  /* To retrive webpages with pagination parameters */
  getTemplatesWithPagination(pageNo, pgSize, templateStatus, customerName) {
    let pageIndex = pageNo.toString();
    let pageSize = pgSize.toString();
    return this.http.get(
      this.env.config.APP.API_URL + '/template', {
        params: new HttpParams()
        .set('pageNo', pageIndex)
        .set('pageSize', pageSize)
        .set('templateStatus', templateStatus)
        .set('customerName', customerName)
      }
    ).pipe(
      retry(2),
      catchError(this.errorHandler)
    )
  }

  /* To filter the list of webpages by params */
  getTemplatesByParams(customerName, templateStatus) {
    return this.http.get(
      this.env.config.APP.API_URL + '/template', {
        params: new HttpParams()
        .set('templateStatus', templateStatus)
        .set('customerName', customerName)
      }
    )
  }

  /* Retrieve templates based on their type, customer and page size - for dynamic preview */
  getTemplatesByType(templateType, customerName, pageSize) {
    return this.http.get(
      this.env.config.APP.API_URL + '/template', {
        params: new HttpParams()
        .set('templateType', templateType)
        .set('customerName', customerName)
        .set('pageSize', pageSize)
      }
    )
  }

  /* Retrieve a single webpage data by ID */
  getTemplate(id, version): Observable<templateDetail> {
    return this.http.get<templateDetail>(
      this.env.config.APP.API_URL + '/template/' + id + '/version/' + version
    )
  }

   /* To retrieve a list of properties of a customer */
  getProperties(customerId, templateId) {
    return this.http.get(
      this.env.config.APP.API_URL + '/customer/' + customerId + '/properties', {
        params: {
          templateId
        }
      }
    ).pipe(
      retry(2),
      catchError(this.errorHandler)
    )
  }

  /* To get static preview response for PDP pages */
  getStaticPreviewForPDP(propertyGlobalId, templateId, version, templateSystem, customerGlobalId) {
    return this.http.get(
      this.env.config.APP.WEB_SERVICE_WEB_URL + 'web/property/' + propertyGlobalId, {
        params: new HttpParams()
        .set('pdpTemplateId', templateId)
        .set('customerGlobalId', customerGlobalId)
        .set('pdpVersionId', version),
      responseType: 'text'
     }
    )
  }

  /* T get static preview response for Search pages */
  getStaticPreviewForSearch(templateId, version, customerGlobalId) {
    return this.http.get(
      this.env.config.APP.WEB_SERVICE_WEB_URL + 'web/property/search', {
        params: new HttpParams()
        .set('searchTemplateId', templateId)
        .set('searchVersionId', version)
        .set('customerGlobalId', customerGlobalId),
        responseType: 'text'
      }
    )
  }

  // ******************* PUT calls ************************

  /* Update a single webpage data by ID */
  updateTemplate(id, version, item) {
    return this.http.put(
      this.env.config.APP.API_URL + '/template/' + id + '/version/' + version, 
      item
    ).pipe(
      retry(2),
      catchError(this.errorHandler)
    )
  }

  // ******************* DELETE calls ************************

  /* Delete a single webpage by id and version */
  deleteTemplate(id, version) {
    return this.http.delete(this.env.config.APP.API_URL + '/template/' + id + '/version/' + version, 
      { responseType: 'text' }
    ).subscribe(() => {
      this.loadTemplates();
    })
  }

  // ******************* OTHER FUNCTIONS ************************

  /* To store the data in central location */
  loadTemplates() {
    this.getTemplates().subscribe((response: any) => {
      this.dataSource.data = response.contents;
      this.pageIndex = response.pageNo;
      this.pageSize = response.pageSize;
      this.totalElements = response.totalElements;

      this.sharedDataSourceChanged$.next(this.dataSource.data);
      this.sharedPageNo$.next(this.pageIndex);
      this.sharedPageSize$.next(this.pageSize);
      this.sharedTotalElements$.next(this.totalElements);
    })
  }

  /* To filter the data by customer name and template status in services */
  filterTemplates(customer, status) {
    this.getTemplatesByParams(customer, status).subscribe((response: any) => {
      this.dataSource.data = response.contents;
      this.pageIndex = response.pageNo;
      this.pageSize = response.pageSize;
      this.totalElements = response.totalElements;

      this.sharedDataSourceChanged$.next(this.dataSource.data);
      this.sharedPageNo$.next(this.pageIndex);
      this.sharedPageSize$.next(this.pageSize);
      this.sharedTotalElements$.next(this.totalElements);
    })
  }

  /* To fetch templates with pagination requirements */
  loadTemplatesOnPagination(pageNo, pageSize, templateStatus, customerName) {
    this.getTemplatesWithPagination(pageNo, pageSize, customerName, templateStatus).subscribe((response: any) => {
      this.dataSource.data = response.contents;
      this.pageIndex = response.pageNo;
      this.pageSize = response.pageSize;
      this.totalElements = response.totalElements;

      this.sharedDataSourceChanged$.next(this.dataSource.data);
      this.sharedPageNo$.next(this.pageIndex);
      this.sharedPageSize$.next(this.pageSize);
      this.sharedTotalElements$.next(this.totalElements);
    })
  }

  /* To fetch template details on the edit page */
  assignData(data) {
    // console.log('fetching Template details: ', data)
    this.templateId = data.id
    this.templateVersion = data.version
    
    this.templateDetail = data
    var { files, ...basicDetails } = this.templateDetail
    this.basicDetails = basicDetails
    this.templateFiles = files
    
    this.templateDetailChanged.next(this.templateDetail);
    this.templateNameChanged.next(this.basicDetails);

    this.customerId = data.customerId;
    this.webpageType$.next(data.type);
    this.customerName$.next(data.customerName);
    this.templateName$.next(data.name);
    this.customerGlobalId$.next(data.customerGlobalId);
    this.templateSystem = data.system;
    this.templateSystem$.next(data.system);

    if (data.type === 'PDP') {
      this.getProperties(data.customerId, data.id).subscribe((res) => {
        this.properties$.next(res);
      })
    }

    this.backupData = data;
  }

  /* To fetch template details and store it in services for easy retrieval */
  getTemplateDetails(templateId, templateVersion) {
    if (templateId && templateVersion) {
      this.getTemplate(templateId, templateVersion).subscribe(data => {
        this.assignData(data);
      }, (error: HttpErrorResponse) => {
        console.log(error);
        // if (error.status === 404) {
          this.showToastMessage('Oops! Template not found. Redirecting in 3s', 'error', 'danger');
        
          setTimeout(() => {
            this.router.navigate(['webpages']);
          }, 3000);
        // }
        
      })
    }
  }

  /* To get the MIME type for sorting files */
  getMIMEType(extension) {
    const extToMimes = {
      'html': 'text/html',
      'ftlh': 'text/plain',
      'ftl': 'text/plain',
      'css': 'text/css',
      'js': 'text/javascript',
      'gif': 'image/gif',
      'jpg': 'image/jpeg',
      'png': 'image/png',
      'svg': 'image/svg+xml',
      'bmp': 'image/bmp',
      'ico': 'image/x-icon',
      'tif': 'image/tiff',
      'ttf': 'font/ttf',
      'woff': 'font/woff',
      'otf': 'font/otf',
      'json': 'application/json',
      'eot': 'application/vnd.ms-fontobject',
      'pdf': 'application/pdf',
    }
    if (extToMimes.hasOwnProperty(extension)) {
      return extToMimes[extension];
    }
    return false;
  }

  /* To check if the file type is valid or invalid */
  isFileTypeValid(filename) {
    const fileName = filename.split('.')
    const extension = fileName[fileName.length - 1]
    const validFiles = ['pdf','json', 'eot', 'woff2', 'gif', 'jpg', 'jpeg', 'png', 'svg', 'bmp', 'ico', 'html', 'ftlh', 'ftl', 'css', 'js', 'tif', 'tiff', 'ttf', 'woff', 'otf', 'webp']
    if (validFiles.includes(extension)) return true
    else return false
  }

  /* To check if file type is valid or create */
  isFileTypeValidToCreate(filename) {
    const fileName = filename.split('.')
    const extension = fileName[fileName.length - 1]
    const validFiles = ['html', 'ftlh', 'ftl', 'css', 'js']
    if (validFiles.includes(extension)) return true
    else return false
  }

  /* To retrieve the media type */
  getMediaType(extension) {
    const extToMediaType = {
      'html': 'HTML',
      'ftlh': 'TEXT_PLAIN',
      'ftl': 'TEXT_PLAIN',
      'css': 'CSS',
      'js': 'JS',
    }
    if (extToMediaType.hasOwnProperty(extension)) {
      return extToMediaType[extension];
    }
    return false;
  }

  /* To create a file */
  createFile(filename) {
    this.filesModified = true
    const fileName = filename.split('.')
    const mediaType = this.getMediaType(fileName[fileName.length - 1])
    this.templateDetail.files.push({ "id": 0, "name": filename, "mediaType": mediaType, "content": "", "modified": true })
    this.templateDetailChanged.next(this.templateDetail);
  }

  /* To open any file in editor */
  openFileInEditor(filename) {
    this.fileOpenedInEditor = filename;
    const file = this.templateDetail.files.filter(file => file.name == this.fileOpenedInEditor)
    this.contentOfOpenedFile.next(file[0]);
  }

  /* To get S3 URL */
  getDataS3URL(s3Url, mediaType) {
    if (mediaType == "CSS" || mediaType == "JS" || mediaType == "HTML" || mediaType == "TEXT_PLAIN" || mediaType == "JSON") {
      return this.http.get(s3Url, { responseType: 'text' })
    }
    else {
      const headers = new HttpHeaders();
      headers.set('Accept', this.getMIMEType(mediaType.toLowerCase()));
      return this.http.get(s3Url, { headers, responseType: 'blob' as 'json' });
    }
  }

  /* To save previous file */
  savePreviousFile(prevFile) {
    this.filesModified = true;
    const fileIndex = this.templateDetail.files.findIndex(file => file.name == prevFile.name);
    this.templateDetail.files[fileIndex].content = prevFile.content;
    this.templateDetail.files[fileIndex].modified = true;
  }

  /* To rename a file on the edit page */
  renameFile(newFileName, oldFilename) {
    this.filesModified = true;
    const fileIndex = this.templateDetail.files.findIndex(file => file.name == oldFilename)
    if (!this.templateDetail.files[fileIndex].modified) {
      this.getDataS3URL(this.templateDetail.files[fileIndex].downloadUrl, this.templateDetail.files[fileIndex].mediaType).subscribe(content => {
        this.addContent(this.templateDetail.files[fileIndex], content)
        this.templateDetail.files[fileIndex].name = newFileName
        this.templateDetail.files[fileIndex].modified = true
      })
    }
    else {
      this.addContent(this.templateDetail.files[fileIndex], this.templateDetail.files[fileIndex].content)
      this.templateDetail.files[fileIndex].name = newFileName
      this.templateDetail.files[fileIndex].modified = true
    }
  }

  /* To add content to the file */
  addContent(file, content) {
    file.content = content
  }

  /* To delete a file */
  deleteFile(fileId) {
    return this.http.delete(this.env.config.APP.API_URL + '/template/' + this.templateId + '/version/' + this.templateVersion + '/file/' + fileId, 
      { responseType: 'text' }
    ).subscribe(() => {
      const fileDeleted = this.templateDetail.files.filter(file => file.id == fileId)
      const newFiles = this.templateDetail.files.filter(file => file.id !== fileId)
      this.templateDetail.files = newFiles
      this.assignData(this.templateDetail)
      if (fileDeleted[0].name == this.fileOpenedInEditor) {
        this.mainFtlhSelected.next(true)
        this.openFileInEditor('main.ftlh')
      }
      this.showToastMessage("File Deleted SuccessFully", "delete", 'danger')
    })
  }

  /* To restore a file */
  restoreFile(fileName) {
    const fileIndex = this.templateDetail.files.findIndex(file => file.name == fileName)
    this.templateDetail.files[fileIndex].deleted = false
  }

  /* To Add new asset file to the existing flyer */
  addNewAssetFile(file) {
    this.filesModified = true
    this.newAssetFiles.push({ 'id': 0, 'multipartFile': file })
    this.templateDetailChanged.next(this.templateDetail);
  }

  /* To replace asset URLs */
  replaceAssetUrls() {
    this.contentOfOpenedFile.next(null)
    var { files, ...basicDetails } = this.templateDetail
    const missingFiles = []
    let observablesArray = []
    let assetFiles = []
    this.URLsReplaced = false
    this.templateDetail.files.forEach((file, index) => {
      if (file.mediaType == "TEXT_PLAIN" || file.mediaType == "JS" || file.mediaType == "CSS" || file.mediaType == "HTML") {
        if (file.s3Url && !file.modified) {
          observablesArray.push(this.getDataS3URL(file.downloadUrl, file.mediaType))
          assetFiles.push(file)
        }
      }
    })
    forkJoin(...observablesArray).subscribe(
      data => {
        for (let i = 0; i < data.length; i++) {
          let assetFileData = data[i];
          var href = /href="(.*?)"/g;
          var src = /src="(.*?)"/g;
          var url = /url\((?!['"]?(?:data:|https?:|\/\/))(['"]?)([^'")]*)\1\)/g
          var match
          while (match = href.exec(assetFileData.toString()) || src.exec(assetFileData.toString()) || url.exec(assetFileData.toString())) {
            const assetName = match[2] ? match[2].split('/').pop() : match[1].split('/').pop()
            const toReplace = match[2] ? match[2] : match[1]
            if (!assetName.includes('{')) {
              const fileToReplace = this.templateDetail.files.filter(file => {
                if (assetName.includes(file.name)) return file
              })
              if (fileToReplace.length == 0) {
                continue
              }
              var outputString
              if (assetFiles[i].mediaType == "JS" || assetFiles[i].mediaType == "CSS" || assetFiles[i].mediaType == "HTML") {
                outputString = "${getFileUrl('" + fileToReplace[0].name + "')}";
              } else if (assetFiles[i].mediaType == "TEXT_PLAIN") {
                outputString = "${getFileUrl('" + fileToReplace[0].name + "')}";
              }
              assetFileData = assetFileData.toString().replace(toReplace, outputString)
              assetFiles[i].modified = true
              this.filesModified = true
              this.addContent(assetFiles[i], assetFileData)

            }

          }
        }
        this.URLsReplaced = true
        if (this.fileOpenedInEditor) this.openFileInEditor(this.fileOpenedInEditor)
        this.showToastMessage("URLs Replaced Successfully!", "check_circle", 'success');
      },
      () => {
        this.URLsReplaced = true
        this.showToastMessage("Some Error Occured", "close", 'danger');
      }
    )
  }

  /* While saving a webpage */
  saveWebpage(publish) {
    this.filesModified = false
    this.contentOfOpenedFile.next(null)
    this.backupData = JSON.parse(JSON.stringify(this.templateDetail))
    var { files, ...basicDetails } = this.templateDetail
    const templateDetail = { ...basicDetails }
    const fileWithoutContent = []
    this.templateDetail.files.forEach(file => {
      if (file.modified && file.content == "") {
        fileWithoutContent.push(file.name)
      }
    });
    if (fileWithoutContent.length > 0) {
      alert("Files cannot be empty : \n " + fileWithoutContent.map((el, i) => ++i + '. ' + el + '\n'))
      return false
    }
    const updatedFiles = this.templateDetail.files.filter(file => file.modified == true)
    updatedFiles.forEach(newFile => {
      const fileName = newFile.name.split('.')
      var extension
      // Get MIME type
      if (newFile.name.split('.').pop() == "ftlh") {
        if (fileName[fileName.length - 2] == "css") {
          extension = "css"
        }
        else {
          extension = "ftlh"
        }
      }
      else {
        extension = newFile.name.split('.').pop()
      }
      const MIMEType = this.getMIMEType(extension)
      var file = new File([newFile.content], newFile.name, { type: MIMEType });
      delete newFile.content
      delete newFile.mediaType
      delete newFile.modified
      delete newFile.name
      delete newFile.s3Url
      delete newFile.fileSize
      delete newFile.textFile
      delete newFile.downloadUrl
      newFile.multipartFile = file
    })
    templateDetail.files = []
    if (updatedFiles.length > 0) updatedFiles.map(file => templateDetail.files.push(file))
    if (this.newAssetFiles.length > 0) this.newAssetFiles.map(file => {
      if (!file.s3Url) {
        templateDetail.files.push(file)
      }
    })
    delete templateDetail.lastModifiedDate

    this.updateTemplate(this.templateId, this.templateVersion, toFormDataNew(templateDetail)).subscribe((data) => {
      this.assignData(data);
      if (publish !== null) {
        if (typeof publish === "object") {
          publish;
        }
        if (typeof publish === "string" && publish === "get-preview-for-pdp-page") {
          this.webpageSavedForPDPPreview.next(true);
        }
        if (typeof publish === "string" && publish === "get-preview-for-search-page") {
          this.webpageSavedForSearchPreview.next(true);
        }
      } else {
        this.showToastMessage("Webpage Updated Successfully!", "check_circle", 'success');
      }

      this.webpageSaved.next(true)
      this.newAssetFiles = []
      this.filesModified = false
      if (this.fileOpenedInEditor) this.openFileInEditor(this.fileOpenedInEditor)
    },
      (err) => {
        if (!publish) this.showToastMessage("Some error occured", "delete", 'danger')
        this.assignData(this.backupData)
        this.filesModified = true
      }
    )
  }

  /* To check if template details are modified or not */
  isModified() {
    if (!this.templateDetail) return false
    if (this.filesModified) {
      return true;
    }
    else {
      var { files, ...basicDetails } = this.templateDetail
      if (JSON.stringify(basicDetails) === JSON.stringify(this.basicDetails)) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  /* Flag to check if files are modified or not */
  fileModified() {
    this.filesModified = true;
  }

  /* To check for duplicate file name */
  checkDuplicateFilename(fileName) {
    if (this.templateDetail.files.some(file => file.name === fileName)) {
      return true
    }
    else {
      return false
    }
  }

  hasWhiteSpace(fileName) {
    if (fileName.indexOf(' ') >= 0){
      return true
    } else {
      return false
    }
  }

  /* All Toast messages */
  showToastMessage(message, icon, classname) {
    this._snackBar.openFromComponent(IconSnackBarComponent, {
      data: {
        message: message,
        icon: icon
      },
      duration: 2000,
      panelClass: classname,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  }

  
}
