import { AbstractControl, ValidationErrors, FormGroup, FormArray, FormControl } from '@angular/forms';
import { isString, isNumber } from 'lodash-es';

// export function toFormData<T extends AbstractControl>(control: T) {
//     const formData = new FormData();
//     const formValue = getFormValue(control);
//     Object.keys(formValue).forEach((key, index) => {
//         formData.append(key, formValue[key]);
//     });
//     return formData;
// }
export class FormDataPresenter {
    name: string;
    value: string;
    file: Blob | File;
    fileName: string;
}
/**
 * @param data {} => any data: belonging object
 * @param excludeKeys keys that need to be excluded
 * @param renameKeys params to rename the keys
 */
export function toFormDataNew(data: any, excludeKeys: string[] = [], renameKeys?: { [key: string]: string }): FormData {
    const formData = new FormData();
    if (!data) {
        return;
    }
    // need for collecting all data as array and then push all of them in FormData
    const formValue = getValueFromSub((data instanceof AbstractControl) ? data.value : data, [], excludeKeys);
    // todo 29/12/19 push all of the data FormData
    const formDataArrList: FormDataPresenter[] = removeDotsFromPresenter(formValue);
    formDataArrList.forEach(x => {
        let key = x.name;
        if (renameKeys) {
            key = getRenamedKeys(renameKeys, x.name);
        }
        if (x.file) {
            formData.append(key, x.file, x.fileName);
        } else {
            formData.append(key, x.value);
        }
    });
    new Response(formData).text().then(console.log);
    return formData;
}
function removeDotsFromPresenter(formValue: FormDataPresenter[]) {
    formValue.map(x => {
        x.name = x.name.replace(/\.\[/g, '[');
        return x;
    });
    return formValue;
}
/**
 * will rename the keys
 * @param renameKeys rename keys
 * @param name for key
 */
function getRenamedKeys(renameKeys: { [p: string]: string }, name: string) {
    let renamedKey;
    if (!renameKeys) {
        return name;
    }
    for (const key of Object.keys(renameKeys)) {
        renamedKey = name.replace(new RegExp(key, 'g'), renameKeys[key]);
    }
    return renamedKey;
}
function hasData(data: File | string | number | any[] | any, keys: string[]) {
    const selfExcludedKeys = ['status', 'file'];
    const emptyStatus = data === undefined || data === null;
    let dataStatus = true;
    if (keys.length && selfExcludedKeys.includes(keys[keys.length - 1])) {
        dataStatus = !!data;
    }
    return !emptyStatus && dataStatus;
}
function getValueFromSub(data: File | string | number | any[] | any,
    keys: string[] = [],
    excludeKeys: string[] = []): FormDataPresenter[] {
    const formDataP: Array<FormDataPresenter> = new Array<FormDataPresenter>();
    if (!hasData(data, keys)) {
        return [];
    }
    if (keys.length && excludeKeys.length && excludeKeys.indexOf(keys[keys.length - 1]) !== -1) {
        return [];
    }
    // @formatter:off
    const isVal = typeof (data) === 'number'
        || typeof (data) === 'string'
        || typeof (data) === 'boolean'
        || data instanceof File;
    // @formatter:on
    if (isVal) {
        const formDataPresenter = new FormDataPresenter();
        formDataPresenter.name = getKeyFromKeys(keys);
        if (data instanceof File) {
            const data1 = data as File;
            formDataPresenter.file = data1;
            formDataPresenter.fileName = data1.name;
        } else {
            formDataPresenter.value = '' + data + '';
        }
        formDataP.push(formDataPresenter);
    } else if (data instanceof Array) {
        data.forEach((obj, index) => {
            let newKey;
            // checking for obj => Array[string] | Array[object]
            // generate new key based on array destructure format
            if (obj instanceof Object) {
                // check of empty key is not added, dev can be abel to change at their side
                newKey = '[' + index + ']';
            } else if (isNumber(obj) || isString(obj)) {
                // check of empty key is not added, dev can be abel to change at their side
                newKey = '[' + index + ']';
            }
            // get values for object, object can anything therefor recursive call
            keys.push(newKey);
            getValueFromSub(obj, [...keys], excludeKeys).forEach(x => {
                formDataP.push(x);
            });
            keys.pop();
        });
    } else if (data instanceof Object) {
        Object.keys(data).forEach((okey) => {
            // generate new key based on object destructure format
            let newKey;
            if (isString(data[okey]) || isNumber(data[okey])) {
                newKey = okey;
            } else {
                newKey = okey;
            }
            // get values for object, object can anything therefor recursive call
            keys.push(newKey);
            getValueFromSub(data[okey], [...keys], excludeKeys).forEach(x => {
                formDataP.push(x);
            });
            keys.pop();
        });
    }
    return formDataP;
}
function getKeyFromKeys(keys: string[]) {
    return keys.join('.');
}
function hasFileType(file: File, type: MimeTypes) {
    if (file) {
        const extension = file.name.split('.')[1].toLowerCase();
        const hasExtension = !!type.split(', ').filter(x => x === extension).length;
        if (!hasExtension) {
            return {
                requiredFileType: true
            };
        }
    }
    return null;
}
export function requiredMimeType(type: MimeTypes) {
    return (formControl: AbstractControl) => {
        const file = formControl.value;
        if (file) {
            if (!file.length) {
                return hasFileType(file, type);
            } else {
                for (let i = 0; i < file.length; i++) {
                    const hasFileType1 = hasFileType(file[i], type);
                    if (hasFileType1) {
                        return hasFileType1;
                    }
                }
            }
            return null;
        }
        return null;
    };
}
export enum MimeTypes {
    IMAGES = 'png, jpeg',
    DOC = 'doc, docx, rtf'
}
/**
 * Not accurate as recursion is not used
 * @param form pass for which wants to get error codes
 */
export function getFormValidationErrors(form) {
    Object.keys(form.controls).forEach(key => {
        const controlErrors: ValidationErrors = form.get(key).errors || 0;
        if (controlErrors) {
            Object.keys(controlErrors).forEach(keyError => {
                console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
        }
    });
}
/**
 * Merge controls into target
 * Not Tested - StackOverflowError is present
 * @param target a
 * @param source b
 */
export function mergeFormControls(target: FormGroup | FormArray, source: AbstractControl): AbstractControl {
    const object = target;
    if (source instanceof FormControl) {
        return source;
    } else if (target instanceof FormGroup) {
        if (source instanceof FormGroup) {
            Object.keys(source.controls).forEach(x => {
                target.addControl(x, mergeFormControls(target, source.controls[x]));
            });
        }
    } else if (target instanceof FormArray) {
        if (source instanceof FormArray) {
            source.controls.forEach(x => {
                target.push(mergeFormControls(target, x));
            });
            return object;
        } else if (source instanceof FormGroup) {
            target.push(source);
        }
    }
    return object;
}
