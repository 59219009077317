<div class="container-fluid custom-container">

    <div class="icon-box">
        <i class="material-icons custom-icon">priority_high</i>
    </div>
    
    <h1 mat-title><strong>Are you Sure?</strong></h1>

    <div mat-dialog-content class="center">
        <div class="row justify-content-center" >
            <p>Flyer PDF(s) will be deleted from all the properties that are associated with this flyer.</p>
        </div>
    </div>

    <div class="row justify-content-center">
        <div mat-dialog-actions class="custom-actions">
            <button mat-raised-button class="custom-cancel" [mat-dialog-close]="true">Cancel</button>
            <button mat-raised-button class="custom-yes btn-danger" (click)="unPublishFlyers()" [mat-dialog-close]="true" >Yes</button>
        </div>
    </div>

</div>
