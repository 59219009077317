<div class="preview-dialog">

    <div class="row custom-close-row">
        <button class="custom-close-btn" mat-icon-button [mat-dialog-close]="true">
            <span class="material-icons">close</span>
        </button>
    </div>
    
    
    <div class="container-fluid">
        
        <h1 mat-dialog-title><b>Preview Website</b></h1>
    
        <div class="center">
    
            <!-- customer type choice -->
            <ng-select appearance="outline" 
                [items]="customers" bindLabel="name" placeholder="Select Customer"
                [(ngModel)]="selectedCustomer" [clearable]=true (change)="selectPages()">
            </ng-select>
    
            <!-- PDP choice -->
            <ng-select appearance="outline"
                [items]="propertyDetailPages" bindLabel="name" placeholder="Select Property Detail Page"
                [(ngModel)]="selectedPDP" [clearable]=true>
            </ng-select>

            <!-- Search page choice -->            
            <ng-select appearance="outline"
                [items]="searchPages" bindLabel="name" placeholder="Select Search Page"
                [(ngModel)]="selectedSearchPage" [clearable]=true>
            </ng-select>

        </div>
    </div>

    <!-- Generate preview buttons -->
    <div class="row justify-content-center">
        <div mat-dialog-actions class="custom-dialog-actions">
            <button mat-toggle-button class="custom-preview-btn" (click)="generateLink()" 
            [disabled]="!selectedCustomer || !selectedPDP || !selectedSearchPage" mat-button>
                Generate Preview
            </button>
        </div>
    </div>

    <!-- To show link -->
    <div class="row custom-row" *ngIf="showLink">
        <div class="col-1 custom-icon">
            <span class="material-icons">insert_link</span>    
        </div>
        <div class="col-9 custom-link">
            <input matInput disabled [(ngModel)]="link">
        </div>
        <div class="col-2">
            <button class="copy-btn" mat-button [cdkCopyToClipboard]="link">Copy</button>
        </div>
    </div>
    
</div>