export class FlyerPropertyStatus {
  id: number;
  propertyGlobalId: string;
  flyerId: string;
  propertyName: string;
  status: FlyerStatus | string | null;
  errorMessage: string;
}


export enum FlyerStatus {
  PENDING, PROCESSING, SUCCESSFUL, ERROR, ABORTED
}
