import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { WebpagesService } from '../../../services/webpages.service';
import { webData } from 'src/app/models/webData';


@Component({
  selector: 'app-webpage-add-popup',
  templateUrl: './webpage-add-popup.component.html',
  styleUrls: ['./webpage-add-popup.component.css']
})


export class WebpageAddPopupComponent implements OnInit {

  /* Form field validations */
  name = new FormControl('', [Validators.required]);
  description = new FormControl('', [Validators.required]);
  selectType = new FormControl('', [Validators.required]);
  selectCustomer = new FormControl('', [Validators.required]);
  selectSystem = new FormControl('PC', [Validators.required]);

  /* Add template form */
  @ViewChild('addTemplateForm', { static: false })
  addTemplateForm = NgForm;
  public selectedCustomer;
  public selectedWebpageType;
  public selectedSystem = "PC";
  paginator: any;
  choosenCustomer: any = "";
  choosenStatus: any = "";

  /* Routing */
  router: Router;

  /* Datasource for table data */
  dataSource = new MatTableDataSource();
  data: Subscription;

  templateData: webData = {
    name: "",
    customerId: 0,
    customerGlobalId:0,
    type: "",
    description: "",
    system: "PC"
  }

  /* To Fetch customers */
  public customers: any;

  /* Types of webpages */ 
  public webPageTypes = [ 
    {name:'Search', id:"SEARCH"}, 
    {name: 'Property Detail Page', id:'PDP'}
  ];

  constructor(
    public dialog: MatDialog,
    private _router: Router, private _route: ActivatedRoute,
    public WebpagesService: WebpagesService,
  ) {
    this.router = _router;
  }

  ngOnInit(): void {

    /* For fetching customers */
    this.WebpagesService.getCustomers().subscribe(res => { 
      this.customers = res 
      this.customers = this.customers.sort((a, b) => a.name.localeCompare(b.name))
    });

    /* For filteration */
    this._route.queryParams.subscribe(params => {
      if (params['customer']) this.choosenCustomer = params['customer'];
      if (params['status']) this.choosenStatus = params['status'];
    });

  }

  onSubmit() {
    this.templateData.customerId = this.selectedCustomer.id;
    this.templateData.customerGlobalId = this.selectedCustomer.customerGlobalId;
    this.templateData.type = this.selectedWebpageType;
    this.templateData.system = this.selectedSystem;
    this.addTemplate(this.templateData);
  }

  /* To create a new webpage */
  addTemplate(templateData) {
    this.WebpagesService.createTemplate(templateData).subscribe(response => {
      if (this._router.url.includes('/webpages')) {
        this.router.navigate(['/webpages/edit/' + response['id'] + '/version/' + response['version']]);
        this.WebpagesService.showToastMessage("Webpage Added Successfully!", "check_circle", 'success');
      }
    })
  }

  
}
