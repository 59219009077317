<div id="container">
  <ace-editor
    [ngClass]="!isEditable ? 'disabled' : ''"
    [(text)]="text"
    theme="monokai"
    [mode]="mode"
    #editor
    [options]="options"
    (textChanged)="onChange()"
    [readOnly]="(flyerTemplate || micrositeTemplate ? false : isPublished) || !isEditable"
    style="width: 100%; overflow: auto"
    [style.min-height.px]="height"
  >
  </ace-editor>
  <div id="smyles_dragbar"></div>
</div>
