<app-flyer-info></app-flyer-info>
<div class="fluid-container">
    <div class="row">
        <div class="col-3" *ngIf="editWebpage">
            <app-web-left-section></app-web-left-section>
        </div>
        <div class="col-3" *ngIf="editFlyer">
            <app-flyer-attributes></app-flyer-attributes>
        </div>
        <div class="col-3" *ngIf="editMicrosite">
            <app-microsite-page-edit-left-section></app-microsite-page-edit-left-section>
        </div>
        <div class="col-9 editor">
            <app-template-editor></app-template-editor>
            <app-pdf-viewer></app-pdf-viewer>
        </div>
    </div>
</div>