<div class="container-fluid custom-container">

    <div class="row custom-row">
        <h2>
            <b>Previewing</b>
        </h2>
    </div>

    <div class="row custom-row">
        <p>When you preview, the current version of your code will be Saved. </p>
    </div>
    
    <div class="row custom-row">
        <div mat-dialog-actions class="custom-row">
            <button mat-button [mat-dialog-close]="true" class="custom-cancel">Cancel</button>
            <button mat-button [mat-dialog-close]="true" class="btn custom-save"
                (click)="showPreview()">
                Save
            </button>
        </div>
    </div>
</div>