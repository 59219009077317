import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})


export class ListComponent implements OnInit {

  router: Router;

  constructor(
    private _router: Router
  ) {
    this.router = _router;
  }

  ngOnInit(): void { }

  
}
