import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})


export class HeaderComponent implements OnInit {

  isUserLoggedIn: boolean = false;
  signinCompleteSubscription: any;
  signinErrorSubscription: any;
  signupCompleteSubscription: any;
  loggedInUser: any;
  dataLoaded: Promise<boolean>;
  loginStatusChangeSubscription: any;

  constructor(private router: Router, public userService: UserService) { }

  ngOnInit(): void {
    this.loginStatusChangeSubscription = this.userService.loginStatusChanged.subscribe(status => {
      this.isUserLoggedIn = status
    })

    this.userService.getLoggedInUser().subscribe((userData) => {
      if (userData) {
        this.loggedInUser = userData
        this.dataLoaded = Promise.resolve(true);
        w.Events.publish('detectChanges');
      }
    })

    let w = (<any>window);
    this.signupCompleteSubscription = w.Events.subscribe('SigninComplete', (response) => {
      this.userService.getLoggedInUser().subscribe((userData) => {
        this.loggedInUser = userData
        this.dataLoaded = Promise.resolve(true);
        w.Events.publish('detectChanges');
      });
    });
  }

  onLogOut() {
    this.isUserLoggedIn = false;
    this.userService.logOut().subscribe();
  }


}