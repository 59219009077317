import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MicrositesService } from '../../../services/microsites.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-microsite-edit',
  templateUrl: './microsite-edit.component.html',
  styleUrls: ['./microsite-edit.component.css'],
})
export class MicrositeEditComponent implements OnInit, OnDestroy {
  micrositeId: String;
  micrositeDataSource: Subscription;

  private routeSub: Subscription;

  constructor(
    public micrositesService: MicrositesService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe((params) => {
      this.micrositeId = params['id'];
    });

    this.micrositesService.loadSingleMicrosite(this.micrositeId);

    /* Retrieve the Microsite pages data list */
    this.micrositeDataSource =
      this.micrositesService.micrositeDataSourceChanged.subscribe(
        (micrositeData) => {
          this.assignData(micrositeData);
        }
      );
  }

  assignData(micrositeData) {
    if (micrositeData.templates && micrositeData.templates.length) {
      this.micrositesService.showAddMicrositePageButton = true;
    }
  }

  ngOnDestroy() {
    this.routeSub && this.routeSub.unsubscribe();
    this.micrositeDataSource && this.micrositeDataSource.unsubscribe();
  }
}
